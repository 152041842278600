import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const LoginRegistration = () => {



    // const fetchData = async () => {
    //   // Replace 'your-auth-token' with the actual token
    //     try {
    //       const response = await axios.get('https://www.backend.ebeachbd.com/api/userInfo', {

    //         headers: {
    //           'Authorization': `Bearer ${localStorage.getItem('token')}`
    //         }
    //       });
    //       console.log(response.data);
    //     } catch (error) {
    //       console.error('Error fetching data:', error);
    //     }
    //   };

    //   useEffect(() => {
    //     fetchData()
    //   },[])

    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/'

    // const router = useRouter();
    const handleSubmit = async (event) => {
        event?.preventDefault();
        const form = event?.target;
        const email = form?.email?.value;
        const password = form?.password?.value;

        const loginDb = {
            email, password
        }


        fetch(`https://backend.ebeachbd.com/api/login`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',

            },
            body: JSON.stringify(loginDb),
        })
            .then((Response) => Response.json())
            .then((data) => {
                if (data.success === true) {

                    localStorage.setItem('token', data.data.token)
                    localStorage.setItem('email', data.data.email)
                    localStorage.setItem('user_id', data.data.user_id)
                    localStorage.setItem('name', data.data.name)
                }
                if (data.success === false) {
                    toast.error('Wrong Email Or Password')
                }
                console.log(data)
                // console.log()
                //(data.data);
                //(data.data.user_id);

                if (data.data.token && data.data.user_id && data.data.email) {
                    navigate(from, { replace: true })
                    toast.success('login')
                }
            })
            .catch((error) => {
                console.error(error)
                toast.error('Wrong Email Or Password')
            });
    }



    const [passwordError, setPasswordError] = useState('');

    const handleRegister = (event) => {
        event.preventDefault();
        const form = event.target;
        const name = form.name.value;
        const email = form.email.value;
        const password = form.password.value;
        const confirm_password = form.confirm_password.value;


        // const otp = form.otp.value;

        // Check if passwords match
        if (password !== confirm_password) {
            setPasswordError('Password and Confirm Password do not match');
            return; // Don't proceed with the submission
        }

        // If passwords match, clear any previous error
        setPasswordError('');

        const users = {
            name, email, password, confirm_password

        };
        //(users)
        // ${process.env.NEXT_PUBLIC_API_URL}/create-users
        fetch(`https://backend.ebeachbd.com/api/register`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',

            },
            body: JSON.stringify(users),
        })
            .then((Response) => Response.json())
            .then((data) => {
                //(data);
                console.log(data)
                if (data.success === true) {

                    toast.success('SuccessFuly Users Create')
                }
                if (data.success === false) {

                    toast.error('Something Went Wrong')
                }
            })
            .catch((error) => console.error(error));
    };

    const [activeButton, setActiveButton] = useState('registration');
    const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    return (
        <div className="">
            <div className=' container mx-auto  mt-10 gap-10'>

                <div class="flex items-center justify-center mt-6 ">
                    <a className={`w-1/3 pb-4 font-medium text-center text-gray-800 capitalize  dark:text-black ${activeButton === 'login' ? 'border-b-2 border-blue-500 dark:border-blue-400' : ''}`} onClick={() => setActiveButton('login')}>
                        Login
                    </a>

                    <a className={`w-1/3 pb-4 font-medium text-center text-gray-800 capitalize  dark:text-black ${activeButton === 'registration' ? 'border-b-2 border-blue-500 dark:border-blue-400' : ''}`} onClick={() => setActiveButton('registration')}>
                        Registration
                    </a>
                </div>






                <div>
                    {activeButton === 'login' && (

                        <form action="" onSubmit={handleSubmit}>

                            <div className='w-full mt-8 py-6'>
                                <div class="flex  rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl  bg-white inset-0 z-0">
                                    <div class="hidden lg:block lg:w-1/2 bg-cover"
                                        style={{ backgroundImage: "url('https://images.unsplash.com/photo-1577495508048-b635879837f1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=675&q=80')" }}
                                    ></div>
                                    <div class="w-full p-8 lg:w-1/2">
                                        <h2 class="text-2xl font-semibold text-gray-700 text-center">E-Beach BD</h2>
                                        <p class="text-xl text-gray-600 text-center">Welcome back!</p>
                                        <a href="#" class="flex items-center justify-center mt-4 text-white rounded-lg shadow-md hover:bg-gray-100">
                                            <div class="px-4 py-3">
                                                <svg class="h-6 w-6" viewBox="0 0 40 40">
                                                    <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#FFC107" />
                                                    <path d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z" fill="#FF3D00" />
                                                    <path d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z" fill="#4CAF50" />
                                                    <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#1976D2" />
                                                </svg>
                                            </div>
                                            <h1 class="px-4 py-3 w-5/6 text-center text-gray-600 font-bold">Sign in with Google</h1>
                                        </a>
                                        <div class="mt-4 flex items-center justify-between">
                                            <span class="border-b w-1/5 lg:w-1/4"></span>
                                            <a href="#" class="text-xs text-center text-gray-500 uppercase">or login with email</a>
                                            <span class="border-b w-1/5 lg:w-1/4"></span>
                                        </div>

                                        <div class="mt-4">
                                            <div class="flex justify-between">
                                                <label class="block text-gray-700 text-sm font-bold mb-2">Email</label>

                                            </div>
                                            <input class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="email" name='email' />
                                        </div>
                                        {/* <div class="mt-4">
                                            <div class="flex justify-between">
                                                <label class="block text-gray-700 text-sm font-bold mb-2"> Password</label>

                                            </div>
                                            <input class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="password" name='password' />

                                        </div> */}
                                        <div className="mt-4 relative">
                                            <div class="flex justify-between">

                                                <label class="block text-gray-700 text-sm font-bold mb-2">Password</label>
                                            </div>
                                            <input class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" name='password' type={passwordVisible ? 'text' : 'password'} placeholder="Password" />
                                            <button type="button" className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                                                onClick={() => setPasswordVisible(!passwordVisible)}>
                                                {passwordVisible ? (
                                                    <svg className="h-6 text-gray-700" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                        <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.477 0 8.268 2.943 9.542 7-.274.893-.649 1.748-1.122 2.558M15.536 15.536a9.956 9.956 0 01-3.536.464 9.956 9.956 0 01-3.536-.464m5.072 0a9.954 9.954 0 01-2.536 2.536m0-5.072a9.954 9.954 0 01-2.536-2.536" />
                                                    </svg>
                                                ) : (
                                                    <svg className="h-6 text-gray-700" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path d="M3.98 8.478a10.94 10.94 0 00-.97 3.522m18.038 0c-.088.963-.307 1.897-.657 2.78m-2.007-5.073a9.966 9.966 0 00-3.552-3.522M15 12a3 3 0 01-6 0 3 3 0 016 0zM4.062 19.938a9.953 9.953 0 005.657 2.016c1.732 0 3.373-.42 4.838-1.174M2.458 12c.652-1.587 1.634-3.053 2.87-4.342M21.542 12a10.91 10.91 0 00-2.868-4.342M3.98 8.478L15.536 20.034M3.98 8.478l11.556 11.556" />
                                                    </svg>
                                                )}
                                            </button>
                                        </div>
                                        <Link to={`/user/forget_password`} class="text-xs text-gray-500">Forget Password?</Link>
                                        <div class="mt-8">
                                            <button class="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600">Login</button>
                                        </div>
                                        <div class="mt-4 flex items-center justify-between">
                                            <span class="border-b w-1/5 md:w-1/4"></span>
                                            <a href="#" class="text-xs text-gray-500 uppercase">or sign up</a>
                                            <span class="border-b w-1/5 md:w-1/4"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    )}
                    {activeButton === 'registration' && (

                        <div class="w-full mt-8 py-6">
                            <div class="flex flex-col items-center">


                            </div>


                            <form action="" onSubmit={handleRegister}>


                                <div>
                                    <div class="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
                                        <div class="hidden lg:block lg:w-1/2 bg-cover"
                                            style={{ backgroundImage: "url('https://images.unsplash.com/photo-1546514714-df0ccc50d7bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=667&q=80')" }}
                                        ></div>
                                        <div class="w-full p-8 lg:w-1/2">
                                            <h2 class="text-2xl font-semibold text-gray-700 text-center">E-Beach BD</h2>
                                            <p class="text-xl text-gray-600 text-center">Welcome back!</p>
                                            <a href="#" class="flex items-center justify-center mt-4 text-white rounded-lg shadow-md hover:bg-gray-100">
                                                <div class="px-4 py-3">
                                                    <svg class="h-6 w-6" viewBox="0 0 40 40">
                                                        <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#FFC107" />
                                                        <path d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z" fill="#FF3D00" />
                                                        <path d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z" fill="#4CAF50" />
                                                        <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#1976D2" />
                                                    </svg>
                                                </div>
                                                <h1 class="px-4 py-3 w-5/6 text-center text-gray-600 font-bold">Sign in with Google</h1>
                                            </a>
                                            <div class="mt-4 flex items-center justify-between">
                                                <span class="border-b w-1/5 lg:w-1/4"></span>
                                                <a href="#" class="text-xs text-center text-gray-500 uppercase">or login with email</a>
                                                <span class="border-b w-1/5 lg:w-1/4"></span>
                                            </div>
                                            <div class="mt-4">
                                                <div class="flex justify-between">
                                                    <label class="block text-gray-700 text-sm font-bold mb-2">Name</label>

                                                </div>
                                                <input class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="name" name='name' />
                                            </div>
                                            <div class="mt-4">
                                                <div class="flex justify-between">
                                                    <label class="block text-gray-700 text-sm font-bold mb-2">Email</label>

                                                </div>
                                                <input class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="email" name='email' />
                                            </div>
                                            {/* <div class="mt-4">
                                                <div class="flex justify-between">
                                                    <label class="block text-gray-700 text-sm font-bold mb-2"> Password</label>

                                                </div>
                                                <input class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="password" name='password' />
                                            </div> */}
                                            <div className="mt-4 relative">
                                                <div class="flex justify-between">

                                                    <label class="block text-gray-700 text-sm font-bold mb-2">Password</label>
                                                </div>
                                                <input class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" name='password' type={passwordVisible ? 'text' : 'password'} placeholder="Password" />
                                                <button type="button" className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5" onClick={() => setPasswordVisible(!passwordVisible)}>
                                                    {passwordVisible ? (
                                                        <svg className="h-6 text-gray-700" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.477 0 8.268 2.943 9.542 7-.274.893-.649 1.748-1.122 2.558M15.536 15.536a9.956 9.956 0 01-3.536.464 9.956 9.956 0 01-3.536-.464m5.072 0a9.954 9.954 0 01-2.536 2.536m0-5.072a9.954 9.954 0 01-2.536-2.536" />
                                                        </svg>
                                                    ) : (
                                                        <svg className="h-6 text-gray-700" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path d="M3.98 8.478a10.94 10.94 0 00-.97 3.522m18.038 0c-.088.963-.307 1.897-.657 2.78m-2.007-5.073a9.966 9.966 0 00-3.552-3.522M15 12a3 3 0 01-6 0 3 3 0 016 0zM4.062 19.938a9.953 9.953 0 005.657 2.016c1.732 0 3.373-.42 4.838-1.174M2.458 12c.652-1.587 1.634-3.053 2.87-4.342M21.542 12a10.91 10.91 0 00-2.868-4.342M3.98 8.478L15.536 20.034M3.98 8.478l11.556 11.556" />
                                                        </svg>
                                                    )}
                                                </button>
                                            </div>
                                            <div class="mt-4">
                                                <div class="flex justify-between">
                                                    <label class="block text-gray-700 text-sm font-bold mb-2">Confirm Password</label>

                                                </div>
                                                <input class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="password" name='confirm_password' />
                                            </div>
                                            <div class="mt-8">
                                                <button class="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600">Register</button>
                                            </div>
                                            <div class="mt-4 flex items-center justify-between">
                                                <span class="border-b w-1/5 md:w-1/4"></span>
                                                <a href="#" class="text-xs text-gray-500 uppercase">or sign up</a>
                                                <span class="border-b w-1/5 md:w-1/4"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {passwordError && <div className='text-danger'>{passwordError}</div>}
                            </form>
                        </div>

                    )}
                </div>
            </div>
            {/* style={{ backgroundImage: "url('https://img.freepik.com/free-vector/technology-background_23-2148119855.jpg?w=2000&t=st=1689179372~exp=1689179972~hmac=8a67af351443807b8728d4c2e1b067ba4a54be7b07c038cac30bdb71291062c1')", }} */}
            <div className='lg:flex justify-evenly container mx-auto'  >












            </div>

        </div>
    );
};

export default LoginRegistration;