import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';


const PopularProducts = () => {

    const [home, setHome] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(`https://backend.ebeachbd.com/api/product-list`)
            .then(response => response.json())
            .then(data => {
                setHome(data);
                setLoading(false);
            });
    }, []);
    //(home);


    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true, // Set autoplay to true
        autoplaySpeed: 3000,

        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    //(home?.data?.length)

    const popularProducts = home?.data?.filter(item => item.condition === 'popular');
    //(popularProducts)
    // const prev = JSON.parse(localStorage.getItem('cart')) ;
    // const [cart, setCart] = useState([prev]);

    // useEffect(() => {
    //     const prev = JSON.parse(localStorage.getItem('cart'));
    //     if (prev) {
    //         setCart(prev);
    //     }
    // }, []);

    // const handleAddToCart = data => {
    //     const newCart = [...cart, data];
    //     setCart(newCart);
    //     localStorage.setItem('cart', JSON.stringify(newCart));
    // }

    const [cart, setCart] = useState([])
    // const handleAddToCart = (itemId) => {
    //     // Retrieve existing cart items from localStorage
    //     const existingCartItems = JSON.parse(localStorage.getItem('cart')) || [];

    //     // Add the new item ID to the cart
    //     const updatedCartItems = [...existingCartItems, itemId];

    //     // Update the cart state and localStorage
    //     setCart(updatedCartItems);
    //     localStorage.setItem('cart', JSON.stringify(updatedCartItems));

    //     // Display a success toast
    //     toast.success('Added to cart successfully!');
    // };
    // const handleAddToCart = (itemId, stock) => {
    //     // Retrieve existing cart items from localStorage
    //     const existingCartItems = JSON.parse(localStorage.getItem('cart')) || [];

    //     // Count the occurrences of the item in the cart
    //     const itemCountInCart = existingCartItems.filter(item => item === itemId).length;

    //     // Check if adding the item would exceed the available stock
    //     if (itemCountInCart >= stock) {
    //         // Display an alert if the cart exceeds the stock
    //         alert("You cannot add more of this product. Stock limit reached.");
    //         return; // Stop further execution
    //     }

    //     // Add the new item ID to the cart
    //     const updatedCartItems = [...existingCartItems, itemId];

    //     // Update the cart state and localStorage
    //     setCart(updatedCartItems);
    //     localStorage.setItem('cart', JSON.stringify(updatedCartItems));

    //     // Display a success toast
    //     toast.success('Added to cart successfully!');
    // };
    const handleAddToCart = (itemId, stock) => {
        // Retrieve existing cart items from localStorage
        const existingCartItems = JSON.parse(localStorage.getItem('DetailsCart')) || [];

        // Find the index of the item in the existing cart items array
        const itemIndex = existingCartItems.findIndex(item => item.id === itemId);

        // If the item is already in the cart, update its quantity
        if (itemIndex !== -1) {
            // Increment quantity if it doesn't exceed stock
            if (existingCartItems[itemIndex].count < stock) {
                existingCartItems[itemIndex].count += 1;
            } else {
                // Display an alert if the cart exceeds the stock
                alert("You cannot add more of this product. Stock limit reached.");
                return; // Stop further execution
            }
        } else {
            // If the item is not in the cart, add it with quantity 1
            if (1 <= stock) {
                existingCartItems.push({ id: itemId, count: 1, selectedColor: null, selectedSize: null });
            } else {
                // Display an alert if the stock is insufficient
                alert("Sorry, this product is out of stock.");
                return; // Stop further execution
            }
        }

        // Update the cart state and localStorage
        localStorage.setItem('DetailsCart', JSON.stringify(existingCartItems));

        // Display a success toast
        toast.success('Added to cart successfully!');
    };
    const handleAddToFavouriteList = (itemId) => {
        // Retrieve existing cart items from localStorage
        const existingCartItems = JSON.parse(localStorage.getItem('favouriteList')) || [];

        // Add the new item ID to the cart
        const updatedCartItems = [...existingCartItems, itemId];

        // Update the cart state and localStorage
        setCart(updatedCartItems);
        localStorage.setItem('favouriteList', JSON.stringify(updatedCartItems));

        // Display a success toast
        toast.success('Added to favourite List successfully!');
    };
    return (
        <div className='px-7 mt-10 '>
            {loading ? (
                <button className="btn loading m-10 ">loading</button>
            ) : (
                <div className='container mx-auto '>
                    <div className=" grid  grid-cols-1  ">
                        <div className='flex justify-start mb-3'>
                            <h1 to={'/all-services'} className=' bg-[#be1e2d] font-semibold text-white text-xl px-2 rounded-md py-1 mb-5'>Popular Products</h1>
                        </div>
                        <Slider className=''  {...settings}>
                            {popularProducts?.map(item => (
                                <div className="card rounded-none shadow-xl gap-5">
                                    <Link to={`/product/product_details/${item.slug}`}>
                                        <figure className="">
                                            <img src={item.photo} alt="Shoes" className="w-[200px] h-[200px] rounded-lg" />
                                        </figure>
                                        <div className="card-body items-center text-center">
                                            <p className='text-black mt-5'>{item.title.slice(0, 15) + '...'}</p>
                                            <div className='flex gap-2'>
                                                <div>{item.offer_price}</div>
                                                <div> <del className='text-red-500'>{item.price}</del></div>
                                                <p>BDT</p>
                                            </div>

                                            <p className='text-black'>Stock: {item.stock}</p>
                                            <div className='flex text-[#FACA15]'>
                                                <FaStar></FaStar>
                                                <FaStar></FaStar>
                                                <FaStar></FaStar>
                                                <FaStar></FaStar>
                                                <FaStar></FaStar>
                                            </div>

                                        </div>
                                    </Link>
                                    <p>{
                                        item.stock === '0' ?
                                            <>
                                                <button className='btn bg-orange-600 text-white border-none btn-sm px-10 rounded-none py-2' >Out Of Stock</button>
                                            </>
                                            :
                                            <>
                                                <div className='flex justify-evenly'>
                                                    <button className="bg-[#be1e2d] text-white px-2 py-2 rounded hover:bg-[#be1e2d]" onClick={() => handleAddToFavouriteList(item.id)} >
                                                        <svg stroke="currentColor"
                                                            className='h-6 w-6'
                                                            fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="2em" xmlns="http://www.w3.org/2000/svg"><path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>
                                                    </button>
                                                    <button className="bg-[#be1e2d] text-white px-2 py-2 rounded hover:bg-[#be1e2d]" onClick={() => handleAddToCart(item.id, item.stock)} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" class=" h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </>
                                    }</p>
                                </div>

                            ))}

                        </Slider>
                    </div>

                </div>
            )}
        </div>
    );
};

export default PopularProducts;