import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaLinkedin, FaWhatsapp, FaInstagram, FaArrowAltCircleRight, FaYoutube, } from 'react-icons/fa';


const Footer = () => {
   return (
      <div className=' text-white'>
         <footer
            className="footer p-10 bg-neutral text-neutral-content">
            <div className='container mx-auto'>
               <span className="footer-title">Contact Us</span>
               <Link to='' className="link link-hover text-start">Address:Tetuljhora, Hemayetpur, <br /> Savar, Dhaka</Link>
               <Link to='' className="link link-hover">Phone: 01810111389</Link>
               <Link to='' className="link link-hover">Email: info@ebeachbd.com</Link>
               <span className="footer-title">Follow Us On</span>
               <div className='flex gap-3'>
                  <Link to={`https://www.facebook.com/ebeachbd`}>

                     <FaFacebook></FaFacebook>
                  </Link>
                  <Link to={`https://www.instagram.com/ebeachbd`}>
                     <FaInstagram></FaInstagram>

                  </Link>
                  <Link to={`https://www.linkedin.com/company/e-beach-bd`}>

                     <FaLinkedin></FaLinkedin>
                  </Link>
                  <Link to={`https://www.youtube.com/@ebeachbd`}>
                  <FaYoutube></FaYoutube>
                  
                  </Link>
               

               </div>
               <div>
                  <span className='text-2xl font-semibold text-start'>Payment Methods</span>
                  <div className='flex gap-4 mt-3'>

                     <img class="text-white" src="https://www.backend.ebeachbd.com/frontend/img/payment-method/bkash.png" alt="bkash" style={{ height: '50px', width: '50px' }} />
                     <img class="text-white" src="https://www.backend.ebeachbd.com/frontend/img/payment-method/nagad.png" alt="bkash" style={{ height: '50px', width: '50px' }} />
                     <img class="text-white" src="https://seeklogo.com/images/D/dutch-bangla-rocket-logo-B4D1CC458D-seeklogo.com.png" alt="bkash" style={{ height: '50px', width: '50px' }} />
                     <img class="text-white" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeJNAxJ4xpo7hA42ig53oW976yrWxRYKqrDirhpMisi_BMWg1I5zO5kyA5k14jD2hGYjY&usqp=CAU" alt="bkash" style={{ height: '50px', width: '50px' }} />
                  </div>

               </div>
            </div>
            <div>
               <span className="footer-title">About</span>
               <Link to='/about_us' className="link link-hover flex">
                  <FaArrowAltCircleRight></FaArrowAltCircleRight>
                  <p className='mt-[-3px] ml-2'> About US</p>
               </Link>
               <Link to='/privacy_policy' className="link link-hover flex">
                  <FaArrowAltCircleRight></FaArrowAltCircleRight>
                  <p className='mt-[-3px] ml-2'>Privacy Policy</p>
               </Link>
               <Link to='' className="link link-hover flex">
                  <FaArrowAltCircleRight></FaArrowAltCircleRight>
                  <p className='mt-[-3px] ml-2'> Tearms And Condition</p>
               </Link>
               <Link to='' className="link link-hover flex">
                  <FaArrowAltCircleRight></FaArrowAltCircleRight>
                  <p className='mt-[-3px] ml-2'>  Delivery Policy</p>
               </Link>
               <Link to='/return_policy' className="link link-hover flex">
                  <FaArrowAltCircleRight></FaArrowAltCircleRight>
                  <p className='mt-[-3px] ml-2'>   Return Policy </p>
               </Link>
               <Link to='/meet_team' className="link link-hover flex">
                  <FaArrowAltCircleRight></FaArrowAltCircleRight>
                  <p className='mt-[-3px] ml-2'> Meet Our Team </p>
               </Link>

            </div>
            <div>
               <span className="footer-title">Join Our Mailing List</span>

               <label className="input input-bordered flex items-center gap-2">
                  <input type="text" className="grow" placeholder="Your E-mail Address" />
                  <button><FaArrowAltCircleRight></FaArrowAltCircleRight></button>
               </label>
               <span className="footer-title">Download Our Mobile Apps</span>
               <a href=" https://play.google.com/store/apps/details?id=com.fahad.ebeach"><img src="https://www.backend.ebeachbd.com/frontend/img/core-img/play-store.png" className='h-[40px] w-[150px]' alt="Play Store" /></a>
               <a href=" https://play.google.com/store/apps/details?id=com.fahad.ebeach"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZctC5fESTv7R5MN5DINu4njVZokSlsBUO4s1Gj61FhJBlI2HQcfuiC_oTVyVJZUE8zik&usqp=CAU" className='h-[40px] w-[150px]' alt="Play Store" /></a>

            </div>
            <div>
               <span className="footer-title">Scan HERE</span>


               <a href="https://play.google.com/store/apps/details?id=com.fahad.ebeach">

                  <img src="https://i.pinimg.com/originals/a8/69/40/a86940a4ed8a69539b341f3c414c47b3.png" className='h-[150px] w-[150px]' alt="Play Store" />
               </a>

            </div>

         </footer>
         <hr />
         <div className='text-center flex justify-evenly bg-[#2A323C] p-5'>
            <p>2023. <strong>EBEACH BD</strong> - All rights reserved.</p>
            <p>Developed By. <strong className='text-blue-800'>Saklain Mostak</strong></p>
         </div>
      </div>
   );
};

export default Footer;