import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './home.css'; // Import CSS file for custom styles
import { MdOutlineCloseFullscreen } from "react-icons/md";

const HomeProduct = () => {
    const [home, setHome] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    useEffect(() => {
        setLoading(true);
        fetch(`https://backend.ebeachbd.com/api/days`)
            .then(response => response.json())
            .then(data => {
                setHome(data);
                setLoading(false);
            });
    }, []);

    const openFullScreen = (image, index) => {
        setSelectedImage(image);
        setSelectedIndex(index);
    };

    const closeFullScreen = () => {
        setSelectedImage(null);
        setSelectedIndex(null);
    };

    const goToNext = () => {
        const nextIndex = selectedIndex < home.data.length - 1 ? selectedIndex + 1 : 0;
        setSelectedIndex(nextIndex);
        setSelectedImage(home.data[nextIndex].filename);
    };

    const goToPrev = () => {
        const prevIndex = selectedIndex > 0 ? selectedIndex - 1 : home.data.length - 1;
        setSelectedIndex(prevIndex);
        setSelectedImage(home.data[prevIndex].filename);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='px-7 mt-10'>
            {loading ? (
                <button className="btn loading m-10 ">loading</button>
            ) : (
                <div className='container mx-auto '>
                    <div className=" grid  grid-cols-1  ">
                        <div className='flex justify-start mb-3'>
                            <h1 to={'/all-services'} className=' bg-[#be1e2d] font-semibold text-white text-xl px-2 rounded-md py-1 mb-5'>HELLO ! GOOD DAYS</h1>
                        </div>
                        <Slider className=''  {...settings}>
                            {home?.data?.map((item, index) => (
                                <div className="card rounded-none" key={item.id}>
                                    <figure className="">
                                        <img 
                                            src={item.filename} 
                                            alt="Shoes" 
                                            className="w-[200px] h-[200px] rounded-lg cursor-pointer" 
                                            onClick={() => openFullScreen(item.filename, index)} 
                                        />
                                    </figure>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            )}
            {selectedImage && (
                <div className="fullscreen-modal">
                    <button className="close-btn " onClick={closeFullScreen}><MdOutlineCloseFullscreen />
</button>
                    <button className="prev-btn" onClick={goToPrev}>&#10094;</button>
                    <img src={selectedImage} alt="Full Screen" className="fullscreen-image" />
                    <button className="next-btn" onClick={goToNext}>&#10095;</button>
                </div>
            )}
        </div>
    );
};

export default HomeProduct;











// import React, { useState, useEffect } from 'react';
// import './home.css';



// function App() {
//     const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
//     const photos = [
//       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBKggxuNw0jnir49VtKatjorQ583IWAnpZCTy9pSBATT5J2hT6BZRTyjV9THFXPCmx5SI&usqp=CAU',
//       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRObh5vxnABwnsogFf_upvz9tO709vAVr0XlpskbARWRRo1cNLPfO0sIcgeU-B6qSDjWZQ&usqp=CAU',
//       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbJtKjEgIvJavIjw8hzmBLeLO5-8UXvSfz0UN3grfXuqtlMoInBzc5nvVknRJE4voqb_8&usqp=CAU'
//     ];

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentPhotoIndex((prevIndex) =>
//         prevIndex === photos.length - 1 ? 0 : prevIndex + 1
//       );
//     }, 3000); // Change photo every 3 seconds
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="App">
//       <PhotoGallery
//         currentPhotoIndex={currentPhotoIndex}
//         photos={photos}
//         onPhotoClick={() => {
//           setCurrentPhotoIndex((prevIndex) =>
//             prevIndex === photos.length - 1 ? 0 : prevIndex + 1
//           );
//         }}
//       />
//     </div>
//   );
// }

// const PhotoGallery = ({ currentPhotoIndex, photos, onPhotoClick }) => {
//   const handlePhotoClick = (index) => {
//     onPhotoClick(index);
//   };

//   return (
//     <div className="photo-gallery">
//       {photos.map((photo, index) => (
//         <Photo
//           key={index}
//           url={photo}
//           onClick={() => handlePhotoClick(index)}
//           zoomed={index === currentPhotoIndex}
//         />
//       ))}
//     </div>
//   );
// };

// const Photo = ({ url, onClick, zoomed }) => {
//   const [isZoomed, setIsZoomed] = useState(false);

//   useEffect(() => {
//     setIsZoomed(zoomed);
//   }, [zoomed]);

//   const toggleZoom = () => {
//     setIsZoomed(!isZoomed);
//   };

//   return (
//     <div className={`photo ${isZoomed ? 'zoomed' : ''}`} onClick={onClick}>
//       <img src={url} alt="Gallery" onClick={toggleZoom} />
//     </div>
//   );
// };

// export default App;
