// import React, { useEffect, useState } from 'react';

// const UserProfile = () => {

//         const [users, setUsers] = useState([])

//     useEffect(() => {
//         fetch(`https://www.backend.ebeachbd.com/api/userInfo`, {
//             headers: {
//                 authorization: `Bearer ${localStorage.getItem('token')}`
//             }
//         })

//             .then(res => res.json())
//             .then(data => setUsers(data))
//     }, [])

//     console.log(users)


//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         address: '',
//         city: '',
//         country: '',
//         phone: '',
//         photo: null // Assuming you're uploading a photo
//     });

//     const handleChange = (e) => {
//         const { name, value, files } = e.target;
//         setFormData(prevState => ({
//             ...prevState,
//             [name]: files ? files[0] : value // If it's a file input, use files, otherwise use value
//         }));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await fetch('https://backend.ebeachbd.com/api/profie_change', {
//                 method: 'POST',
//                 headers: {
//                                     authorization: `Bearer ${localStorage.getItem('token')}`
//                                 },
//                 body: formData
//             });
//             if (response.ok) {
//                 // Handle success
//                 console.log('Data submitted successfully');
//             } else {
//                 // Handle error
//                 console.error('Error submitting data');
//             }
//         } catch (error) {
//             console.error('Error submitting data:', error);
//         }
//     };

//     return (
//         <div className='container mx-auto mt-5'>
//             <form onSubmit={handleSubmit}>
//                 <div className="h-full">
//                     <div className="border-b-2 block md:flex">
//                         <div className="w-full md:w-3/5 p-8 bg-white lg:ml-4 shadow-md text-start">
//                             <div className="rounded shadow-lg p-6">
//                                 <div className="pb-6">
//                                     <label htmlFor="name" className="font-semibold text-gray-700 block pb-1">Name</label>
//                                     <input
//                                         name="name"
//                                         value={formData.name}
//                                         onChange={handleChange}
//                                         className="border-1 bg-white border rounded-r px-4 py-2 w-full"
//                                         type="text"
//                                     />
//                                 </div>
//                                 <div className="pb-6">
//                                     <label htmlFor="email" className="font-semibold text-gray-700 block pb-1">Email</label>
//                                     <input
//                                         name="email"
//                                         value={formData.email}
//                                         onChange={handleChange}
//                                         className="border-1 bg-white border rounded-r px-4 py-2 w-full"
//                                         type="email"
//                                         disabled // Assuming email is not editable
//                                     />
//                                 </div>
//                                 <div className="pb-6">
//                                     <label htmlFor="address" className="font-semibold text-gray-700 block pb-1">Address</label>
//                                     <input
//                                         name="address"
//                                         value={formData.address}
//                                         onChange={handleChange}
//                                         className="border-1 bg-white border rounded-r px-4 py-2 w-full"
//                                         type="text"
//                                     />
//                                 </div>
//                                 <div className="pb-6">
//                                     <label htmlFor="city" className="font-semibold text-gray-700 block pb-1">City</label>
//                                     <input
//                                         name="city"
//                                         value={formData.city}
//                                         onChange={handleChange}
//                                         className="border-1 bg-white border rounded-r px-4 py-2 w-full"
//                                         type="text"
//                                     />
//                                 </div>
//                                 <div className="pb-6">
//                                     <label htmlFor="country" className="font-semibold text-gray-700 block pb-1">Country</label>
//                                     <input
//                                         name="country"
//                                         value={formData.country}
//                                         onChange={handleChange}
//                                         className="border-1 bg-white border rounded-r px-4 py-2 w-full"
//                                         type="text"
//                                     />
//                                 </div>
//                                 <div className="pb-6">
//                                     <label htmlFor="phone" className="font-semibold text-gray-700 block pb-1">Phone</label>
//                                     <input
//                                         name="phone"
//                                         value={formData.phone}
//                                         onChange={handleChange}
//                                         className="border-1 bg-white border rounded-r px-4 py-2 w-full"
//                                         type="text"
//                                     />
//                                 </div>
//                                 <div className="pb-6">
//                                     <label htmlFor="photo" className="font-semibold text-gray-700 block pb-1">Photo</label>
//                                     <input
//                                         name="photo"
//                                         onChange={handleChange}
//                                         className="border-1 bg-white border rounded-r px-4 py-2 w-full"
//                                         type="file"
//                                     />
//                                 </div>
//                                 <div className="offset-md-3 col-sm-6">
//                                     <button
//                                         type="submit"
//                                         className="bg-[#ff8084] text-white px-6 py-2 lg:font-medium text-small lg:text-sm rounded-md my-7 cursor-pointer">
//                                         Save & Publish
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </form>
//         </div>
//     );
// };

// export default UserProfile;




// import React, { useEffect, useState } from 'react';

// const UserProfile = () => {

//     const [file, setFile] = useState(null); // State to store selected file
//     const [fileURL, setFileURL] = useState(null); // State to store URL of uploaded file

//     const handleFileChange = (event) => {
//         const selectedFile = event.target.files[0];
//         setFile(selectedFile); // Set the selected file

//         // Read the file as a data URL
//         const reader = new FileReader();
//         reader.onload = () => {
//             setFileURL(reader.result); // Update fileURL state
//             setBrandData(prevState => ({
//                 ...prevState,
//                 photo: reader.result // Update brandData.photo with the URL of the uploaded file
//             }));
//         };
//         reader.readAsDataURL(selectedFile);
//     };

//     const [users, setUsers] = useState([])

//     useEffect(() => {
//         fetch(`https://www.backend.ebeachbd.com/api/userInfo`, {
//             headers: {
//                 authorization: `Bearer ${localStorage.getItem('token')}`
//             }
//         })

//             .then(res => res.json())
//             .then(data => setUsers(data))
//     }, [])

//     console.log(users)


//     const [brandData, setBrandData] = useState({
//         full_name: '',
//         email: '',
//         address: '',
//         city: '',
//         country: '',
//         phone: '',
//         photo: '',

//     });

//     const modified = localStorage.getItem('user_id')


//     useEffect(() => {

//         setBrandData({
//             full_name: users?.data?.full_name || '',
//             email: users?.data?.email || '',
//             address: users?.data?.address || '',
//             city: users?.data?.city || '',
//             country: users?.data?.country || '',
//             phone: users?.data?.phone || '',
//             photo: users?.data?.photo || '',
//             status: users?.data?.status || '',

//         });
//     }, [users]);



//     const handleInputChange = (event) => {
//         const name = event.target.name
//         const value = event.target.value
//         const attribute = { ...brandData }
//         attribute[name] = value
//         setBrandData(attribute)

//     };

//     const handleUpdateBrand = (e) => {
//         e.preventDefault();

//         const formData = new FormData();
//         formData.append('full_name', brandData.full_name);
//         formData.append('email', brandData.email);
//         formData.append('address', brandData.address);
//         formData.append('city', brandData.city);
//         formData.append('country', brandData.country);
//         formData.append('phone', brandData.phone);

//         // Check if a new file is selected
//         if (fileURL) {
//             formData.append('photo', fileURL); // Append the file to FormData
//         }

//         fetch(`https://backend.ebeachbd.com/api/profie_change`, {
//             method: 'POST',
//             headers: {
//                 authorization: `Bearer ${localStorage.getItem('token')}`,
//             },
//             body: formData // Send FormData object in the body
//         })
//             .then(response => response.json())
//             .then(data => {
//                 console.log(data);
//                 // Handle success or show a success message to the user
//             })
//             .catch(error => {
//                 console.error('Error updating brand:', error);
//                 // Handle error or show an error message to the user
//             });
//     };

//     console.log(brandData.photo)
//     console.log("File URL:", fileURL);

//     return (
//         <div className='container mx-auto mt-5'>
//             <div class="h-full">
//                 <div class="border-b-2 block md:flex">

//                     <div class="w-full md:w-2/5 p-4 sm:p-6 lg:p-8 bg-white shadow-md">
//                         <div class=" ">

//                             <figure class="px-10 pt-10">
//                                 <div class="avatar">
//                                     <div class="w-52 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
//                                         {fileURL ? (
//                                             <img src={fileURL} alt="" />
//                                         ) : (
//                                             <img src={brandData.photo} alt="" />
//                                         )}
//                                     </div>
//                                 </div>
//                             </figure>
//                             <div class="card-body items-center text-center">
//                                 <h2 class="card-title">Name : {brandData?.full_name}</h2>
//                                 <p class="mt-5 text-xl">Email : {brandData?.email}</p>
//                                 <p class="mt-5 text-xl">Status : {brandData?.status}</p>
//                                 <p class="mt-5 text-xl">Address : {brandData?.address}</p>
//                                 <p class="mt-5 text-xl">City : {brandData?.city}</p>
//                                 <p class="mt-5 text-xl">Country : {brandData?.country}</p>
//                                 <p class="mt-5 text-xl">Phone : {brandData?.phone}</p>
//                             </div>


//                         </div>
//                     </div>

//                     <div class="w-full md:w-3/5 p-8 bg-white lg:ml-4 shadow-md text-start">
//                         <form action="" onSubmit={handleUpdateBrand}>



//                             <div class="rounded  shadow-lg p-6">
//                                 <div class="pb-6">
//                                     <label for="name" class=" font-semibold text-gray-700 block pb-1">Name</label>
//                                     <div class="flex">
//                                         <input
//                                             onChange={handleInputChange}
//                                             name='full_name'
//                                             defaultValue={brandData?.full_name} id="username" class="border-1 bg-white border  rounded-r px-4 py-2 w-full" type="text" />
//                                     </div>
//                                 </div>

//                                 <div class="pb-4">
//                                     <label for="about" class="font-semibold text-gray-700 block pb-1">Email</label>
//                                     <input id="email"
//                                         onChange={handleInputChange}
//                                         name='email'
//                                         disabled class="border-1 bg-white border  rounded-r px-4 py-2 w-full" type="email" value={brandData?.email} />

//                                 </div>
//                                 <div class="pb-6">
//                                     <label for="name" class=" font-semibold text-gray-700 block pb-1">Address</label>
//                                     <div class="flex">
//                                         <input
//                                             onChange={handleInputChange}
//                                             name='address'
//                                             defaultValue={brandData?.address} id="username" class="border-1 bg-white border rounded-r px-4 py-2 w-full" type="text" />
//                                     </div>
//                                 </div>
//                                 <div class="pb-6">
//                                     <label for="name" class=" font-semibold text-gray-700 block pb-1">City</label>
//                                     <div class="flex">
//                                         <input
//                                             onChange={handleInputChange}
//                                             name='city'
//                                             defaultValue={brandData?.city} id="username" class="border-1 bg-white border  rounded-r px-4 py-2 w-full" type="text" />
//                                     </div>
//                                 </div>
//                                 <div class="pb-6">
//                                     <label for="name" class=" font-semibold text-gray-700 block pb-1">Country</label>
//                                     <div class="flex">
//                                         <input
//                                             onChange={handleInputChange}
//                                             name='country'
//                                             defaultValue={brandData?.country} id="username" class="border-1 bg-white border  rounded-r px-4 py-2 w-full" type="text" />
//                                     </div>
//                                 </div>
//                                 <div class="pb-6">
//                                     <label for="name" class=" font-semibold text-gray-700 block pb-1">Phone</label>
//                                     <div class="flex">
//                                         <input
//                                             onChange={handleInputChange}
//                                             name='phone'
//                                             defaultValue={brandData?.phone} id="username" class="border-1 bg-white border  rounded-r px-4 py-2 w-full" type="text" />
//                                     </div>
//                                 </div>
//                                 <div class="pb-6">
//                                     <label for="name" class=" font-semibold text-gray-700 block pb-1">Photo</label>
//                                     <div class="flex">
//                                         <input
//                                             onChange={handleFileChange}
//                                             name='photo'
//                                             defaultValue={brandData?.photo} id="username" class="border-1 bg-white border  rounded-r px-4 py-2 w-full" type="file" />
//                                     </div>
//                                 </div>

//                             </div>
//                             <div className="offset-md-3 col-sm-6">

//                                 <input type="submit" name="create" className="btn mt-5 btn-success text-white" value="Update" />
//                             </div>
//                         </form>
//                     </div>

//                 </div>

//             </div>
//         </div>
//     );
// };

// export default UserProfile;

import React, { useEffect, useState } from 'react';

const UserProfile = () => {


    const [users, setUsers] = useState([])

    useEffect(() => {
        fetch(`https://www.backend.ebeachbd.com/api/userInfo`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })

            .then(res => res.json())
            .then(data => setUsers(data))
    }, [])

    console.log(users)

    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        address: '',
        city: '',
        country: '',
        phone: '',
        photo: ''
    });

    useEffect(() => {

        setFormData({
            full_name: users?.data?.full_name || '',
            email: users?.data?.email || '',
            address: users?.data?.address || '',
            city: users?.data?.city || '',
            country: users?.data?.country || '',
            phone: users?.data?.phone || '',
            photo: users?.data?.photo || '',
            status: users?.data?.status || '',

        });
    }, [users]);


    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'photo') {
            // If the input is for photo, update formData with the selected file
            setFormData(prevState => ({
                ...prevState,
                [name]: files[0] // Assuming single file selection
            }));
        } else {
            // For other input fields, update formData with the value
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const [file, setFile] = useState(null); // State to store selected file
    const [fileURL, setFileURL] = useState(null); // State to store URL of uploaded file

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile); // Set the selected file

        // Read the file as a data URL
        const reader = new FileReader();
        reader.onload = () => {
            setFileURL(reader.result); // Update fileURL state
            setFormData(prevState => ({
                ...prevState,
                photo: reader.result // Update brandData.photo with the URL of the uploaded file
            }));
        };
        reader.readAsDataURL(selectedFile);
    };


    console.log(formData?.photo?.name)
    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (!token) {
            // Handle error when token is not available
            return;
        }

        const formDataToSend = new FormData();

        // Append other fields to formDataToSend
        formDataToSend.append('full_name', formData.full_name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('address', formData.address);
        formDataToSend.append('city', formData.city);
        formDataToSend.append('country', formData.country);
        formDataToSend.append('phone', formData.phone);

        // Check if a new photo is selected
        if (formData.photo instanceof File) {
            formDataToSend.append('photo', formData?.photo); // Append the file to formDataToSend
        }

        const response = await fetch('https://backend.ebeachbd.com/api/profie_change', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formDataToSend
        });

        if (response.ok) {
            // Handle success
            console.log('Profile updated successfully');
        } else {
            // Handle error
            console.error('Failed to update profile');
        }
    };

    console.log(formData?.photo?.name)


    return (
        <div className='container mx-auto mt-5'>
            <div class="h-full">
                <div class="border-b-2 block md:flex">

                    <div class="w-full md:w-2/5 p-4 sm:p-6 lg:p-8 bg-white shadow-md">
                        <div class=" ">

                            <figure class="px-10 pt-10">
                                <div class="avatar">
                                    <div class="w-52 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">

                                        <img src={`https://backend.ebeachbd.com/uploads/images/user_image/${formData.photo}`} alt="" />

                                    </div>
                                </div>
                            </figure>
                            <div class="card-body items-center text-center">
                                <h2 class="card-title">Name : {formData?.full_name}</h2>
                                <p class="mt-5 text-xl">Email : {formData?.email}</p>
                                <p class="mt-5 text-xl">Status : {formData?.status}</p>
                                <p class="mt-5 text-xl">Address : {formData?.address}</p>
                                <p class="mt-5 text-xl">City : {formData?.city}</p>
                                <p class="mt-5 text-xl">Country : {formData?.country}</p>
                                <p class="mt-5 text-xl">Phone : {formData?.phone}</p>
                            </div>


                        </div>
                    </div>

                    <div class="w-full md:w-3/5 p-8 bg-white lg:ml-4 shadow-md text-start">
                        <form action="" onSubmit={handleSubmit}>



                            <div class="rounded  shadow-lg p-6">
                                <div class="pb-6">
                                    <label for="name" class=" font-semibold text-gray-700 block pb-1">Name</label>
                                    <div class="flex">
                                        <input
                                            onChange={handleChange}
                                            name='full_name'
                                            defaultValue={formData?.full_name} id="username" class="border-1 bg-white border  rounded-r px-4 py-2 w-full" type="text" />
                                    </div>
                                </div>

                                <div class="pb-4">
                                    <label for="about" class="font-semibold text-gray-700 block pb-1">Email</label>
                                    <input id="email"
                                        onChange={handleChange}
                                        name='email'
                                        disabled class="border-1 bg-white border  rounded-r px-4 py-2 w-full" type="email" value={formData?.email} />

                                </div>
                                <div class="pb-6">
                                    <label for="name" class=" font-semibold text-gray-700 block pb-1">Address</label>
                                    <div class="flex">
                                        <input
                                            onChange={handleChange}
                                            name='address'
                                            defaultValue={formData?.address} id="username" class="border-1 bg-white border rounded-r px-4 py-2 w-full" type="text" />
                                    </div>
                                </div>
                                <div class="pb-6">
                                    <label for="name" class=" font-semibold text-gray-700 block pb-1">City</label>
                                    <div class="flex">
                                        <input
                                            onChange={handleChange}
                                            name='city'
                                            defaultValue={formData?.city} id="username" class="border-1 bg-white border  rounded-r px-4 py-2 w-full" type="text" />
                                    </div>
                                </div>
                                <div class="pb-6">
                                    <label for="name" class=" font-semibold text-gray-700 block pb-1">Country</label>
                                    <div class="flex">
                                        <input
                                            onChange={handleChange}
                                            name='country'
                                            defaultValue={formData?.country} id="username" class="border-1 bg-white border  rounded-r px-4 py-2 w-full" type="text" />
                                    </div>
                                </div>
                                <div class="pb-6">
                                    <label for="name" class=" font-semibold text-gray-700 block pb-1">Phone</label>
                                    <div class="flex">
                                        <input
                                            onChange={handleChange}
                                            name='phone'
                                            defaultValue={formData?.phone} id="username" class="border-1 bg-white border  rounded-r px-4 py-2 w-full" type="text" />
                                    </div>
                                </div>
                                <div class="pb-6">
                                    <label for="name" class=" font-semibold text-gray-700 block pb-1">Photo</label>
                                    <div class="flex">
                                        <input
                                            onChange={handleChange}
                                            name='photo'
                                            defaultValue={formData?.photo} id="username" class="border-1 bg-white border  rounded-r px-4 py-2 w-full" type="file" />
                                    </div>
                                </div>

                            </div>
                            <div className="offset-md-3 col-sm-6">

                                <input type="submit" name="create" className="btn mt-5 btn-success text-white" value="Update" />
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default UserProfile;
