
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = () => {

  const [loading, setLoading] = useState(false);


  const email = localStorage.getItem('email')
  const user_id = localStorage.getItem('user_id')
  const total = localStorage.getItem('total')
  const After_coupon_total = localStorage.getItem('After_coupon_total')
  const subtotal = localStorage.getItem('subtotal')
  const shipping_charge = localStorage.getItem('shipping_charge')
  const couponValue = localStorage.getItem('couponValue')

  const [formData, setFormData] = useState({
    user_id: user_id,
    total_amount: After_coupon_total,
    sub_total: subtotal,
    name: '',
    email: email,
    phone: '',
    address: '',
    item: [],
    delivery_charge: shipping_charge,
    coupon: couponValue
  });
  const allIdString = localStorage.getItem('DetailsCart');

  const data = allIdString ? JSON.parse(allIdString) : [];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const items = data.map(item => ({ product_id: item.id, qty: item.count, size: item.selectedSize, color: item.selectedColor }));
      const formDataWithItems = { ...formData, item: items };

      const response = await axios.post('https://backend.ebeachbd.com/api/checkout-confirm', formDataWithItems, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      console.log(response.data.success);
      if (response.data.success === true) {
        localStorage.removeItem('couponValue')
        localStorage.removeItem('total')
        localStorage.removeItem('subtotal')
        localStorage.removeItem('shipping_charge')
        localStorage.removeItem('DetailsCart')
        localStorage.removeItem('After_coupon_total')

        navigate('/confirm_message')
      }

      // handle successful response
    } catch (error) {
      console.error('Error:', error); // handle error
    }
    setLoading(false);
  };

  return (

    <div className='text-start mb-10'>
      {/* <div className='mt-[-10px]'>
        <progress className="progress progress-success w-[33.333333%] bg-green-500 rounded-none" value="0" max="100"></progress>
      </div> */}



      <form onSubmit={handleSubmit}>
        <div className='lg:flex container mx-auto gap-10 p-2 mb-20 mt-10'>


          <div className='lg:w-[50%] border   bg-white shadow-2xl rounded-lg'>
            <div className='flex bg-[#15396D] p-5 gap-5 text-white font-bold'>

              <p className=''>  Order Detail</p>
            </div>
            <div className='p-5'>
              <div className=" items-center">
                <div className="w-60">
                  <label htmlFor="">
                    Name
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <input
                  name="name" value={formData.name} onChange={handleChange}

                  placeholder=" Name"
                  type="text"
                  className="input input-bordered mt-3 w-full rounded-sm"
                />
              </div>

              <div className="mt-5  items-center">
                <div className="w-60">
                  <label htmlFor="">
                    Email
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <input

                  placeholder="email"
                  type="text"
                  name="email" value={formData.email} onChange={handleChange}
                  disabled
                  className="input input-bordered mt-3 w-full rounded-sm"
                />
              </div>
              <div className="mt-5  items-center">
                <div className="w-60">
                  <label htmlFor="">
                    Adress
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <input

                  placeholder="Adress"
                  type="text"
                  name='address'
                  value={formData.address} onChange={handleChange}
                  className="input input-bordered mt-3 w-full rounded-sm"
                />
              </div>
              <div className="mt-5  items-center">
                <div className="w-60">
                  <label htmlFor="">
                    Phone Number
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <input

                  placeholder="phone"
                  type="text"
                  name="phone" value={formData.phone} onChange={handleChange}
                  className="input input-bordered mt-3 w-full rounded-sm"
                />
              </div>

            </div>

          </div>


          <div className='lg:w-[50%] border mt-5 lg:mt-0 bg-white shadow-2xl rounded-lg'>
            <div className='flex bg-[#15396D] p-5 gap-5 text-white font-bold'>

              <p className=''>  Order Detail</p>
            </div>
            <div className='p-5  '>
              <div className="  items-center">
                <div className="w-60">
                  <label htmlFor="">
                    State
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <input


                  name='state'
                  placeholder="State"
                  type="text"
                  className="input input-bordered mt-1 w-full rounded-sm"
                />
              </div>

            </div>

            <div className='p-5  '>
              <div className="  items-center">
                <div className="w-60">
                  <label htmlFor="">
                    City
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <input

                  name='city'
                  placeholder="City"
                  type="text"
                  className="input input-bordered mt-1 w-full rounded-sm"

                />
              </div>

            </div>
            <div className='p-5  '>
              <div className="  items-center">
                <div className="w-60">
                  <label htmlFor="">
                    Post Code
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <input
                  name='post_code'

                  placeholder="Post Code"
                  type="text"
                  className="input input-bordered mt-1 w-full rounded-sm"

                />
              </div>

            </div>

            <div className='p-5  '>
              <div className="  items-center">
                <div className="w-60">
                  <label htmlFor="">
                    Order Notes
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <textarea name="" id="" className='w-full textarea textarea-bordered  rounded-sm' rows="5"></textarea>
              </div>

            </div>



            <div className='divider'></div>
            <div className=" px-6 mt-5">

              {/* <div className="flex justify-between">
                                <p>Service Charge</p>
                                <p > Taka</p>
                            </div>
                            <div className='divider'></div>
    
                            <div className="flex justify-between">
                                <p>Sales Tax</p>
                                <p>0.00</p>
                            </div>
                            <div className='divider'></div>
    
                            <div className="flex justify-between">
                                <p>Total Service Charge</p>
                                <p > Taka</p>
                            </div>
                            <div className='divider'></div>
                            <div className="mr-5 mb-4">
                                <input type="checkbox" name="" id="" className="mr-2" />
                                I have read and agree to the <strong>Terms & Conditions </strong>and <strong> Refund Policy.</strong>
    
                            </div> */}
              <div className='text-center mb-5'>
                <button
                  disabled={loading}
                  className='btn w-full bg-[#1697DA] text-white border-none hover:bg-[#57a2ca]'>
                  {loading ? 'Sending...' : 'Proceed To CheckOut'}

                </button>
              </div>
            </div>

          </div>


        </div>
      </form>



    </div>
  );
};

export default CheckoutForm;



// <div className="max-w-md mx-auto mt-10 p-6 bg-gray-100 rounded-lg">
//   <h2 className="text-2xl font-semibold mb-4">Checkout Form</h2>
//   <form onSubmit={handleSubmit}>
//     <div className="mb-4">
//       <label className="block mb-2">User ID</label>
//       <input type="text" name="user_id" value={formData.user_id} onChange={handleChange} className="w-full border p-2 rounded-md" />
//     </div>
//     <div className="mb-4">
//       <label className="block mb-2">Total Amount</label>
//       <input type="text" name="total_amount" value={formData.total_amount} onChange={handleChange} className="w-full border p-2 rounded-md" />
//     </div>
//     <div className="mb-4">
//       <label className="block mb-2">Sub Total</label>
//       <input type="text" name="sub_total" value={formData.sub_total} onChange={handleChange} className="w-full border p-2 rounded-md" />
//     </div>
//     <div className="mb-4">
//       <label className="block mb-2">Name</label>
//       <input type="text" name="name" value={formData.name} onChange={handleChange} className="w-full border p-2 rounded-md" />
//     </div>
//     <div className="mb-4">
//       <label className="block mb-2">Email</label>
//       <input type="email" name="email" value={formData.email} onChange={handleChange} className="w-full border p-2 rounded-md" />
//     </div>
//     <div className="mb-4">
//       <label className="block mb-2">Phone</label>
//       <input type="tel" name="phone" value={formData.phone} onChange={handleChange} className="w-full border p-2 rounded-md" />
//     </div>
//     <div className="mb-4">
//       <label className="block mb-2">Address</label>
//       <textarea name="address" value={formData.address} onChange={handleChange} className="w-full border p-2 rounded-md"></textarea>
//     </div>
//     <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600">Submit</button>
//   </form>
// </div>

