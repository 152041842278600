
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

// const CheckoutFormBuyNow = () => {

//   const [loading, setLoading] = useState(false);

//   const [shipping, setShipping] = useState([])
//   useEffect(() => {
//     fetch(`https://backend.ebeachbd.com/api/shipping-list`)
//       .then(res => res.json())
//       .then(data => setShipping(data))
//   }, [])

//   console.log(shipping)
//   const [selectedShipping, setSelectedShipping] = useState(null); // State to store the ID of the selected shipping
//   const [deliveryCharge, setDeliveryCharge] = useState([])
//   const handleCheckboxChange = (shippingId, price) => {
//     setDeliveryCharge(price)
//     localStorage.setItem('shipping_charge', price);
//     if (selectedShipping === shippingId) {
//       // Unselect the checkbox if it's already selected
//       setSelectedShipping(null);
//     } else {
//       // Select the clicked checkbox
//       setSelectedShipping(shippingId);
//     }
//   };
//   const deliveryChargeNumber = parseFloat(deliveryCharge);
//   console.log(parseInt(deliveryCharge));


//   const email = localStorage.getItem('email')
//   const user_id = localStorage.getItem('user_id')



//     const shipping_charge = localStorage.getItem('shipping_charge')

//   const allIdString = localStorage.getItem('DetailsCartBuyNow');
//   const data = allIdString ? JSON.parse(allIdString) : [];
//   console.log(data[0].price * data[0].count)
//   const total = data[0].price * data[0].count
//   const subTotal = total + parseFloat(shipping_charge)
//   console.log(subTotal)

//   console.log(data[0]?.price)
//   const [formData, setFormData] = useState({
//     user_id: user_id,
//     total_amount: subTotal,
//     sub_total:  total,
//     name: '',
//     email: email,
//     phone: '',
//     address: '',
//     item: [],
//     delivery_charge: shipping_charge,
//     coupon: 0
//   });

//   console.log(shipping_charge)
//   console.log(formData)

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };
//   const navigate = useNavigate()
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     try {
//       const items = data.map(item => ({ product_id: item.id, qty: item.count, size: item.selectedSize, color: item.selectedColor }));
//       const formDataWithItems = { ...formData, item: items };

//       const response = await axios.post('https://backend.ebeachbd.com/api/checkout-confirm', formDataWithItems, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('token')}`,
//           'Content-Type': 'application/json'
//         }
//       });
//       console.log(response.data.success);
//       if (response.data.success === true) {
//         localStorage.removeItem('couponValue')
//         localStorage.removeItem('total')
//         localStorage.removeItem('subtotal')
//         localStorage.removeItem('shipping_charge')
//         localStorage.removeItem('DetailsCart')
//         localStorage.removeItem('After_coupon_total')

//         navigate('/confirm_message')
//       }

//       // handle successful response
//     } catch (error) {
//       console.error('Error:', error); // handle error
//     }
//     setLoading(false);
//   };

//   return (

//     <div className='text-start mb-10'>
//       {/* <div className='mt-[-10px]'>
//         <progress className="progress progress-success w-[33.333333%] bg-green-500 rounded-none" value="0" max="100"></progress>
//       </div> */}



//       <form onSubmit={handleSubmit}>
//         <div className='lg:flex container mx-auto gap-10 p-2 mb-20 mt-10'>


//           <div className='lg:w-[50%] border   bg-white shadow-2xl rounded-lg'>
//             <div className='flex bg-[#15396D] p-5 gap-5 text-white font-bold'>

//               <p className=''>  Order Detail</p>
//             </div>
//             <div className='p-5'>
//               <div className=" items-center">
//                 <div className="w-60">
//                   <label htmlFor="">
//                     Name
//                     <sup>
//                       <span className="text-red-500 mr-1">*</span>
//                     </sup>
//                   </label>
//                 </div>
//                 <input
//                   name="name" value={formData.name} onChange={handleChange}

//                   placeholder=" Name"
//                   type="text"
//                   className="input input-bordered mt-3 w-full rounded-sm"
//                 />
//               </div>

//               <div className="mt-5  items-center">
//                 <div className="w-60">
//                   <label htmlFor="">
//                     Email
//                     <sup>
//                       <span className="text-red-500 mr-1">*</span>
//                     </sup>
//                   </label>
//                 </div>
//                 <input

//                   placeholder="email"
//                   type="text"
//                   name="email" value={formData.email} onChange={handleChange}
//                   disabled
//                   className="input input-bordered mt-3 w-full rounded-sm"
//                 />
//               </div>
//               <div className="mt-5  items-center">
//                 <div className="w-60">
//                   <label htmlFor="">
//                     Adress
//                     <sup>
//                       <span className="text-red-500 mr-1">*</span>
//                     </sup>
//                   </label>
//                 </div>
//                 <input

//                   placeholder="Adress"
//                   type="text"
//                   name='address'
//                   value={formData.address} onChange={handleChange}
//                   className="input input-bordered mt-3 w-full rounded-sm"
//                 />
//               </div>
//               <div className="mt-5  items-center">
//                 <div className="w-60">
//                   <label htmlFor="">
//                     Phone Number
//                     <sup>
//                       <span className="text-red-500 mr-1">*</span>
//                     </sup>
//                   </label>
//                 </div>
//                 <input

//                   placeholder="phone"
//                   type="text"
//                   name="phone" value={formData.phone} onChange={handleChange}
//                   className="input input-bordered mt-3 w-full rounded-sm"
//                 />
//               </div>

//             </div>

//           </div>


//           <div className='lg:w-[50%] border mt-5 lg:mt-0 bg-white shadow-2xl rounded-lg'>
//             <div className='flex bg-[#15396D] p-5 gap-5 text-white font-bold'>

//               <p className=''>  Order Detail</p>
//             </div>
//             <div className='p-5  '>
//               <div className="  items-center">
//                 <div className="w-60">
//                   <label htmlFor="">
//                     State
//                     <sup>
//                       <span className="text-red-500 mr-1">*</span>
//                     </sup>
//                   </label>
//                 </div>
//                 <input


//                   name='state'
//                   placeholder="State"
//                   type="text"
//                   className="input input-bordered mt-1 w-full rounded-sm"
//                 />
//               </div>

//             </div>

//             <div className='p-5  '>
//               <div className="  items-center">
//                 <div className="w-60">
//                   <label htmlFor="">
//                     City
//                     <sup>
//                       <span className="text-red-500 mr-1">*</span>
//                     </sup>
//                   </label>
//                 </div>
//                 <input

//                   name='city'
//                   placeholder="City"
//                   type="text"
//                   className="input input-bordered mt-1 w-full rounded-sm"

//                 />
//               </div>

//             </div>
//             <div className='p-5  '>
//               <div className="  items-center">
//                 <div className="w-60">
//                   <label htmlFor="">
//                     Post Code
//                     <sup>
//                       <span className="text-red-500 mr-1">*</span>
//                     </sup>
//                   </label>
//                 </div>
//                 <input
//                   name='post_code'

//                   placeholder="Post Code"
//                   type="text"
//                   className="input input-bordered mt-1 w-full rounded-sm"

//                 />
//               </div>

//             </div>

//             <div className='p-5  '>
//               <div className="  items-center">
//                 <div className="w-60">
//                   <label htmlFor="">
//                     Order Notes
//                     <sup>
//                       <span className="text-red-500 mr-1">*</span>
//                     </sup>
//                   </label>
//                 </div>
//                 <textarea name="" id="" className='w-full textarea textarea-bordered  rounded-sm' rows="5"></textarea>
//               </div>

//             </div>



//             <div className='divider'></div>
//             <p className='ml-6'>Select One </p>
//             <div className=" px-6 mt-5">
//               {shipping?.data?.map((ship) => (
//                 <div key={ship.id} className="flex justify-between mt-2">
//                   <div className='flex'>
//                     {/* Use checked attribute to check/uncheck the checkbox */}
//                     <input
//                       type="checkbox"
//                       checked={selectedShipping === ship.id} // Check if this checkbox should be checked
//                       onChange={() => handleCheckboxChange(ship.id, ship.delivery_charge)} // Call the function to handle checkbox change
//                     />
//                     <p className="text-gray-700 ml-3">{ship.shipping_address}</p>
//                   </div>
//                   <p className="text-gray-700">{ship.delivery_charge} TAKA</p>
//                 </div>
//               ))}
//               {/* <div className="flex justify-between">
//                 <p>Service Charge</p>
//                 <p > Taka</p>
//               </div>
//               <div className='divider'></div>

//               <div className="flex justify-between">
//                 <p>Sales Tax</p>
//                 <p>0.00</p>
//               </div>
//               <div className='divider'></div>

//               <div className="flex justify-between">
//                 <p>Total Service Charge</p>
//                 <p > Taka</p>
//               </div>
//               <div className='divider'></div>
//               <div className="mr-5 mb-4">
//                 <input type="checkbox" name="" id="" className="mr-2" />
//                 I have read and agree to the <strong>Terms & Conditions </strong>and <strong> Refund Policy.</strong>

//               </div> */}
//               <div className='text-center mb-5 mt-5'>

//                 <button
//                   disabled={loading || selectedShipping === null}
//                   className='btn w-full bg-[#1697DA] text-white border-none hover:bg-[#57a2ca]'>
//                   {loading ? 'Sending...' : 'Proceed To CheckOut'}
//                 </button>

//               </div>
//             </div>

//           </div>


//         </div>
//       </form>



//     </div>
//   );
// };

// export default CheckoutFormBuyNow;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CheckoutFormBuyNow = () => {
  const [loading, setLoading] = useState(false);
  const [shipping, setShipping] = useState([]);
  const [selectedShipping, setSelectedShipping] = useState(null);
  const [deliveryCharge, setDeliveryCharge] = useState(0);

  useEffect(() => {
    fetch('https://backend.ebeachbd.com/api/shipping-list')
      .then(res => res.json())
      .then(data => setShipping(data))
      .catch(err => console.error('Error fetching shipping list:', err));
  }, []);

  useEffect(() => {
    const storedShippingCharge = localStorage.getItem('shipping_charge');
    if (storedShippingCharge) {
      setDeliveryCharge(parseFloat(storedShippingCharge));
    }
  }, []);

  const handleCheckboxChange = (shippingId, price) => {
    setDeliveryCharge(parseFloat(price));
    localStorage.setItem('shipping_charge', price);
    if (selectedShipping === shippingId) {
      setSelectedShipping(null);
    } else {
      setSelectedShipping(shippingId);
    }
  };

  const email = localStorage.getItem('email');
  const user_id = localStorage.getItem('user_id');
  const allIdString = localStorage.getItem('DetailsCartBuyNow');
  const data = allIdString ? JSON.parse(allIdString) : [];

  const total = data.length > 0 ? data[0].price * data[0].count : 0;
  const subTotal = total + deliveryCharge;

  const [formData, setFormData] = useState({
    user_id: user_id || '',
    total_amount: subTotal,
    sub_total: total,
    name: '',
    email: email || '',
    phone: '',
    address: '',
    item: [],
    delivery_charge: deliveryCharge,
    coupon: 0,
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      total_amount: subTotal,
      sub_total: total,
      delivery_charge: deliveryCharge,
    }));
  }, [subTotal, total, deliveryCharge]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const items = data.map(item => ({ product_id: item.id, qty: item.count, size: item.selectedSize, color: item.selectedColor }));
      const formDataWithItems = { ...formData, item: items };

      const response = await axios.post('https://backend.ebeachbd.com/api/checkout-confirm', formDataWithItems, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data.success) {
        localStorage.removeItem('couponValue');
        localStorage.removeItem('total');
        localStorage.removeItem('subtotal');
        localStorage.removeItem('shipping_charge');
        localStorage.removeItem('DetailsCart');
        localStorage.removeItem('After_coupon_total');

        navigate('/confirm_message');
      }
    } catch (error) {
      console.error('Error:', error);
    }
    setLoading(false);
  };

  return (
    <div className='text-start mb-10'>
      <form onSubmit={handleSubmit}>
        <div className='lg:flex container mx-auto gap-10 p-2 mb-20 mt-10'>
          <div className='lg:w-[50%] border bg-white shadow-2xl rounded-lg'>
            <div className='flex bg-[#15396D] p-5 gap-5 text-white font-bold'>
              <p>Order Detail</p>
            </div>
            <div className='p-5'>
              <div className="items-center">
                <div className="w-60">
                  <label>
                    Name
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <input
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  type="text"
                  className="input input-bordered mt-3 w-full rounded-sm"
                />
              </div>

              <div className="mt-5 items-center">
                <div className="w-60">
                  <label>
                    Email
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <input
                  placeholder="email"
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled
                  className="input input-bordered mt-3 w-full rounded-sm"
                />
              </div>
              <div className="mt-5 items-center">
                <div className="w-60">
                  <label>
                    Address
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <input
                  placeholder="Address"
                  type="text"
                  name='address'
                  value={formData.address}
                  onChange={handleChange}
                  className="input input-bordered mt-3 w-full rounded-sm"
                />
              </div>
              <div className="mt-5 items-center">
                <div className="w-60">
                  <label>
                    Phone Number
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <input
                  placeholder="phone"
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="input input-bordered mt-3 w-full rounded-sm"
                />
              </div>
            </div>
          </div>

          <div className='lg:w-[50%] border mt-5 lg:mt-0 bg-white shadow-2xl rounded-lg'>
            <div className='flex bg-[#15396D] p-5 gap-5 text-white font-bold'>
              <p>Order Detail</p>
            </div>
            <div className='p-5'>
              <div className="items-center">
                <div className="w-60">
                  <label>
                    State
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <input
                  name='state'
                  placeholder="State"
                  type="text"
                  className="input input-bordered mt-1 w-full rounded-sm"
                />
              </div>
            </div>

            <div className='p-5'>
              <div className="items-center">
                <div className="w-60">
                  <label>
                    City
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <input
                  name='city'
                  placeholder="City"
                  type="text"
                  className="input input-bordered mt-1 w-full rounded-sm"
                />
              </div>
            </div>

            <div className='p-5'>
              <div className="items-center">
                <div className="w-60">
                  <label>
                    Post Code
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <input
                  name='post_code'
                  placeholder="Post Code"
                  type="text"
                  className="input input-bordered mt-1 w-full rounded-sm"
                />
              </div>
            </div>

            <div className='p-5'>
              <div className="items-center">
                <div className="w-60">
                  <label>
                    Order Notes
                    <sup>
                      <span className="text-red-500 mr-1">*</span>
                    </sup>
                  </label>
                </div>
                <textarea className='w-full textarea textarea-bordered rounded-sm' rows="5"></textarea>
              </div>
            </div>

            <div className='divider'></div>
            <p className='ml-6'>Select One </p>
            <div className="px-6 mt-5">
              {shipping?.data?.map((ship) => (
                <div key={ship.id} className="flex justify-between mt-2">
                  <div className='flex'>
                    <input
                      type="checkbox"
                      checked={selectedShipping === ship.id}
                  
                        onChange={() => handleCheckboxChange(ship.id, ship.delivery_charge)}
                        />
                        <p className="text-gray-700 ml-3">{ship.shipping_address}</p>
                      </div>
                      <p className="text-gray-700">{ship.delivery_charge} TAKA</p>
                    </div>
                  ))}
    
                  <div className='text-center mb-5 mt-5'>
                    <button
                      disabled={loading || selectedShipping === null}
                      className='btn w-full bg-[#1697DA] text-white border-none hover:bg-[#57a2ca]'>
                      {loading ? 'Sending...' : 'Proceed To CheckOut'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    };
    
    export default CheckoutFormBuyNow;
    

