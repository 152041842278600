import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const HomeSlider = () => {


    const [categories, setCategories] = useState([])

    useEffect(() => {
        fetch(`https://backend.ebeachbd.com/api/category-list`)
            .then(res => res.json())
            .then(data => setCategories(data))
    }, [])

    ////(categories.data)

    const [currentIndex, setCurrentIndex] = useState(0);
    const [carouselItems, setCarouselItems] = useState([])

    useEffect(() => {
        fetch(`https://backend.ebeachbd.com/api/banner-list`)
            .then(res => res.json())
            .then(data => setCarouselItems(data))
    }, [])
    const totalItems = carouselItems?.data?.length;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((currentIndex + 1) % totalItems);
        }, 3000); // Change slide every 3 seconds (3000 milliseconds)

        return () => clearInterval(interval);
    }, [currentIndex, totalItems]);


    const [subCat, setSubCat] = useState([])

    useEffect(() => {
        fetch(`https://backend.ebeachbd.com/api/subcategory-list`)
            .then(res => res.json())
            .then(data => setSubCat(data))
    }, [])

    // const id = 7

    // const subCategory = subCat.data
    // console.log(subCat.data
    //     ?.filter(subCategory => subCategory.category_id === '7'))

    const submit = (data) => {
        // console.log(data)
    }
    const [hoveredCategory, setHoveredCategory] = useState(null);
    const [openSubmenuId, setOpenSubmenuId] = useState(null);

    const toggleSubmenu = (categoryId) => {
        if (openSubmenuId === categoryId) {
            setOpenSubmenuId(null); // If submenu is already open, close it
        } else {
            setOpenSubmenuId(categoryId); // Otherwise, open the clicked submenu
        }
    };

    const isSubmenuOpen = (categoryId) => {
        return openSubmenuId === categoryId;
    };
    return (
        <div>
            {/* lg:hidden */}
            <div className='bg-slate-300 lg:hidden'>

                <div class=" container mx-auto  text-green-700  grid  grid-cols-2 md:grid-cols-3 lg:grid-cols-10">
                    {
                        categories?.data?.map(page =>
                            <>

                                <div class="group relative cursor-pointer py-2 ">

                                    <div class="flex items-center justify-between  ">
                                        <a class="menu-hover  text-base font-medium flex ">
                                            <p  > {page.title}</p>
                                            <span className='mt-1'>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>
                                            </span>
                                        </a>
                                    </div>

                                    <div
                                        class="invisible absolute z-50 flex w-full flex-col bg-gray-100 py-1 px-4 text-gray-800 shadow-xl group-hover:visible">
                                        {subCat?.data
                                            ?.filter(subCategory => subCategory.category_id === page.id.toString())
                                            .map(filteredSubCategory => (
                                                <Link to={`/product/category/subCategory/${filteredSubCategory.id}`}
                                                    onClick={() => submit(filteredSubCategory.title)}
                                                    key={filteredSubCategory.id}
                                                    className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'>
                                                    {filteredSubCategory.title}
                                                </Link>
                                            ))}


                                    </div>
                                </div>
                            </>


                        )}
                </div>
            </div>
            {/*  */}

            <div className='lg:flex mt-2 container mx-auto'>
                <div className="dropdown rounded-xl dropdown-hover dropdown-right lg:w-[400px] md:w-full hidden lg:block w-full flex-col bg-gray-100 py-1 px-4 text-gray-800 shadow-xl group-hover:visible">
                    <div tabIndex={0} role="button" className="block rounded-lg mb-2 lg:mb-0 md:mb-0">
                        {categories?.data?.map(category => (
                            <ul
                                key={category.id}
                                className="hover:bg-gray-200 dark:hover:bg-gray-700 p-1 px-5 rounded-md text-start"
                                onMouseEnter={() => setHoveredCategory(category.id)}
                                onMouseLeave={() => setHoveredCategory(null)}
                            >
                                <li onClick={() => toggleSubmenu(category.id)}>{category.title}</li>
                                <ul tabIndex={0} className={`dropdown-content z-[1] w-full flex-col bg-gray-100 py-1 px-4 text-gray-800 ml-2 p-1 shadow-sm h-full ${isSubmenuOpen(category.id) ? 'visible' : 'hidden'}`}>
                                    {subCat?.data
                                        ?.filter(subCategory => subCategory.category_id === (hoveredCategory !== null ? hoveredCategory.toString() : ''))
                                        .map(filteredSubCategory => (
                                            <Link to={`/product/category/subCategory/${filteredSubCategory.id}`}>
                                                <li onClick={() => submit(category.id)} key={filteredSubCategory.id} className='dark:hover:bg-gray-700 rounded px-2 py-1'>


                                                    {filteredSubCategory.title}


                                                </li>
                                            </Link>
                                        ))}
                                </ul>
                            </ul>
                        ))}
                    </div>
                </div>

                <div className='lg:ml-5 md:w-[100%]'>
                    <div className="carousel w-full rounded-3xl">
                        {carouselItems?.data?.map((item, index) => (
                            <div key={index} className={`carousel-item w-full ${index === currentIndex ? '' : 'hidden'}`}>
                                <img src={item.photo} className="lg:h-[430px] lg:w-[1400px] md:h-[450px] h-[250px] rounded-3xl" alt={`carousel-item-${index}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeSlider;
// <div className='lg:flex mt-2 container mx-auto '>
//     <div className=" dropdown rounded-xl dropdown-hover dropdown-right lg:w-[400px] md:w-full hidden lg:block   w-full flex-col bg-gray-100 py-1 px-4 text-gray-800 shadow-xl group-hover:visible">
//         <div tabIndex={0} role="button" className=" block  rounded-lg  mb-2 lg:mb-0 md:mb-0">
//             {categories?.data?.map(category => (
//                 <ul key={category.id} className="hover:bg-gray-200 dark:hover:bg-gray-700 p-1 px-5 rounded-md text-start  ">
//                     {category.title}
//                     <ul tabIndex={0} className="dropdown-content z-[1] w-full flex-col bg-gray-100 py-1 px-4 text-gray-800 ml-2 p-1 shadow-sm ">
//                         {subCat?.data
//                             ?.filter(subCategory => subCategory.category_id === category.id.toString())
//                             .map(filteredSubCategory => (
//                                 <>

//                                     <a
//                                         onClick={() => submit(category.id)}
//                                         key={filteredSubCategory.id}
//                                        >
//                                         {filteredSubCategory.title}
//                                     </a>
//                                     {/* <li onClick={() => submit(category.id)} className='dark:hover:bg-gray-700 rounded px-2 py-1'><a>Item 1</a></li>
//                                     <li className='dark:hover:bg-gray-700 rounded px-2 py-1'><a>Item 1</a></li>
//                                     <li className='dark:hover:bg-gray-700 rounded px-2 py-1'><a>Item 1</a></li>
//                                     <li className='dark:hover:bg-gray-700 rounded px-2 py-1'><a>Item 1</a></li>
//                                     <li className='dark:hover:bg-gray-700 rounded px-2 py-1'><a>Item 1</a></li>
//                                     <li className='dark:hover:bg-gray-700 rounded px-2 py-1'><a>Item 1</a></li>
//                                     <li className='dark:hover:bg-gray-700 rounded px-2 py-1'><a>Item 1</a></li>
//                                     <li className='dark:hover:bg-gray-700 rounded px-2 py-1'><a>Item 1</a></li> */}
//                                 </>

//                             ))}

//                     </ul>
//                 </ul>
//             ))}
//         </div>
//     </div>

//     <div className=' lg:ml-5 md:w-[100%]'>

//         <div className="carousel w-full rounded-3xl">
//             {carouselItems?.data?.map((item, index) => (
//                 <div key={index} className={`carousel-item w-full  ${index === currentIndex ? '' : 'hidden'}`}>
//                     <img src={item.photo} className="h-full" alt={`carousel-item-${index}`} />

//                 </div>
//             ))}
//         </div>

//     </div>






// </div>