
import React, { useState } from 'react';
import axios from 'axios';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');

  const handleResetPassword = async () => {
    try {
      const response = await axios.post('https://www.backend.ebeachbd.com/api/user_reset_password', {
        new_password: newPassword,
        code: code,
        email: email
      });
      if (response.data.success) {
        setMessage('Password reset successful!');
        // Handle redirection or other actions after successful password reset
      } else {
        setMessage('Password reset failed. Please try again.');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage('An error occurred while resetting password.');
    }
  };

  return (
    <div className="max-w-md mx-auto mb-10">
      <h1 className="text-2xl font-semibold mb-4">Reset Password</h1>
      <input 
        type="email" 
        placeholder="Email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full"
      />
      <input 
        type="password" 
        placeholder="New Password" 
        value={newPassword} 
        onChange={(e) => setNewPassword(e.target.value)} 
        className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full"
      />
      <input 
        type="text" 
        placeholder="Verification Code" 
        value={code} 
        onChange={(e) => setCode(e.target.value)} 
        className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full"
      />
      <button 
        onClick={handleResetPassword} 
        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md"
      >
        Reset Password
      </button>
      {message && <p className="text-red-500 mt-2">{message}</p>}
    </div>
  );
};

export default ResetPassword;
