import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const MatchVerifyCode = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [message, setMessage] = useState('');

    const handleVerification = async () => {
        try {
            const response = await axios.post('https://www.backend.ebeachbd.com/api/forget_password_code_match', {
                email: email,
                code: code
            });
            if (response.data.success) {

                setMessage('Verification successful!');
                navigate('/user/reset_password')
                // Proceed with password reset logic here
            } else {
                setMessage('Verification failed. Please check your email and code.');
            }
        } catch (error) {
            console.error('Error verifying code:', error);
            setMessage('An error occurred while verifying code.');
        }
    };

    return (
        <div className="max-w-md mx-auto mb-10">
            <h1 className="text-2xl font-semibold mb-4">Verify Your Email</h1>
            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full"
            />
            <input
                type="text"
                placeholder="Verification Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full"
            />
            <button
                onClick={handleVerification}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md"
            >
                Verify Code
            </button>
            {message && <p className="text-red-500 mt-2">{message}</p>}
        </div>
    );
};

export default MatchVerifyCode;
