import React from 'react';

const AboutUs = () => {
    return (
        <div className='text-left container mx-auto mt-10 mb-20'>
            <p>
            Welcome to our online store”E beach BD” – your one-stop destination for all your shopping needs! Explore our wide range of products and discover the latest trends in fashion, electronics, home decor, and more. Our user-friendly website is designed to provide you with a seamless shopping experience from the comfort of your own home.

            </p> <br />
            <p>Details Description <br />
                Welcome to our online store – your one-stop destination for all your shopping needs! Explore our wide range of products and discover the latest trends in fashion, electronics, home decor, and more. Our user-friendly website is designed to provide you with a seamless shopping experience from the comfort of your own home.</p>
<br />
            <p>Browse through our carefully curated categories to find exactly what you're looking for. Whether you're in search of stylish apparel, cutting-edge gadgets, or unique gifts, we've got you covered. Our intuitive search and filter options make it easy to narrow down your choices and find the perfect item.</p>
<br />
            <p>Why shop with us?</p> <br />

            <p>Quality Assurance: We take pride in offering only the finest products from reputable brands, ensuring that you receive top-notch quality with every purchase.
            </p>
<br />
            <p>Secure Transactions: Shop with confidence knowing that your personal and payment information is protected by the latest security protocols.
            </p>
<br />
            <p>Fast and Reliable Shipping: Our efficient shipping process ensures that your orders are delivered promptly to your doorstep. </p> <br />
            <p>
                Hassle-Free Returns: Customer satisfaction is our priority. If you're not completely satisfied with your purchase, our hassle-free return policy makes it simple to initiate a return or exchange.</p> <br />

            <p>24/7 Customer Support: Have a question or need assistance? Our dedicated customer support team is available around the clock to provide you with the help you need.</p>
<br />
            <p>Deals and Discounts: Enjoy exclusive deals, seasonal sales, and special discounts that help you save while you shop.</p> <br />
            <p>
                Join our growing community of satisfied customers who have made us their preferred online shopping destination.</p>
<br />
            <p>Start browsing now and experience the joy of convenient, stress-free online shopping. Thank you for choosing us – Happy shopping!
            </p>

<br />
            <p>
                <strong>Email</strong> : ebeachbd@gmail.com <br />
                <strong>Phone</strong> : 01810111389</p>
        </div>
    );
};

export default AboutUs;