import React, {  useState } from 'react';

const ChangePassword = () => {

  
   
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const user_id = localStorage.getItem('user_id')

    // Function to handle password reset
    const users_reset_password = async () => {
        // Check if the new password and confirm password match
        if (newPassword !== confirmPassword) {
            console.error('New password and confirm password do not match');
            return;
        }

        try {
            // Send a POST request to the server endpoint
            const response = await fetch(`https://backend.ebeachbd.com/api/password-change/${user_id}`, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password: newPassword }),
            });

            // Check if the request was successful
            if (response.ok) {
                console.log(response);
            } else {
                console.error('Failed to reset password');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };



    return (

        <div
            class="relative mx-auto w-full max-w-md bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:rounded-xl sm:px-10 mt-10 mb-10">
            <div class="w-full">
                <div class="text-center">
                    <h6 class="text-2xl font-semibold text-gray-900 mb-10">Change Your Password</h6>

                </div>
                <div class="mt-5">
                    <form action="" onSubmit={users_reset_password}>

                        
                        <div class="relative mt-6">
                            <input type="password"
                                value={newPassword} onChange={(e) => setNewPassword(e.target.value)}
                                name="password" id="password" placeholder="Password" class="peer bg-white peer mt-1 w-full border-b-2 border-gray-300 px-0 py-1 placeholder:text-transparent focus:border-gray-500 focus:outline-none" />
                            <label for="password" class="pointer-events-none absolute top-0 left-0 origin-left -translate-y-1/2 transform text-sm text-gray-800 opacity-75 transition-all duration-100 ease-in-out peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:top-0 peer-focus:pl-0 peer-focus:text-sm peer-focus:text-gray-800">New Password</label>
                        </div>
                        <div class="relative mt-6">
                            <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} name="password" id="password" placeholder="Password" class="peer bg-white peer mt-1 w-full border-b-2 border-gray-300 px-0 py-1 placeholder:text-transparent focus:border-gray-500 focus:outline-none" />
                            <label for="password" class="pointer-events-none absolute top-0 left-0 origin-left -translate-y-1/2 transform text-sm text-gray-800 opacity-75 transition-all duration-100 ease-in-out peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:top-0 peer-focus:pl-0 peer-focus:text-sm peer-focus:text-gray-800">Confrim Password</label>
                        </div>
                        <div class="form-group row my-6">
                            <div class="offset-md-3 col-sm-6">
                                <input type="button" onClick={users_reset_password} class="w-full btn rounded-md bg-black px-3 py-4 text-white focus:bg-gray-600 focus:outline-none" value="Submit" />
                            </div>
                        </div>
                        {/* <div class="my-6">
                            <button type="submit" class="w-full rounded-md bg-black px-3 py-4 text-white focus:bg-gray-600 focus:outline-none">Change Password</button>
                        </div> */}

                    </form>
                </div>
            </div>
        </div>

    );
};

export default ChangePassword;