import React from 'react';

const ReturnPolicy = () => {
    return (
        <div className='container mx-auto text-left mt-10 mb-20'>
            <p>we're dedicated to providing you with the best shopping experience for all your needs. We understand that sometimes returns are necessary, and we want to make this process as smooth as possible for you. That's why we've developed this clear and customer-friendly return policy to ensure.</p> <br />
            <p>14-Day Satisfaction Guarantee</p> <br />
            <p>We offer a 14-day satisfaction guarantee on all our products. If you're not completely satisfied with your purchase for any reason, you can return the item(s) within 14 days of the delivery date for a refund or exchange.</p> <br />
            <p>Eligibility for Returns <br />
                To be eligible for a return, the item(s) must meet the following criteria:
            </p> <br />
            <p>
                The item must be in its original condition, unused, and with all tags and labels attached. 
                <p>

                It must be in the original packaging.
                </p>
                <p>

                The item must not be damaged or altered in any way. <br />
                </p>
<br />

                <p>
                Return Options 
                </p>
           
                <p>You have two options for returns</p> <br />

              <p>  Refund: If you choose a refund, once we receive your returned item and it meets the eligibility criteria, we will process your refund promptly. You will receive the refund to the original payment method used for the purchase. Please allow 14 business days for the refund to appear in your account, depending on your payment provider.</p>
                <br />
                <p>Exchange: If you prefer an exchange, we will ship the replacement item to you as soon as we receive your return and verify its eligibility. Please note that we do not offer exchanges for different items. If you need a different size or color, please return the original item and place a new order for the desired item on our website.</p>
                <br />

               <p> Return Shipping <br />
               Return shipping costs are the responsibility of the customer unless the return is due to an error on our part or a defective item. In such cases, we will provide a prepaid return label.</p>
                <br />
              <p>  Damaged or Defective Items</p>
                <br />
               <p> If you receive a damaged or defective item, please contact our customer support team immediately. We will arrange for a replacement or a refund, as applicable</p>
            </p>
        </div>
    );
};

export default ReturnPolicy;