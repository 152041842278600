import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Message = () => {
    const email = localStorage.getItem('email')
    const [data, setData] = useState([]);
    const user_id = localStorage.getItem('user_id')
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://backend.ebeachbd.com/api/user_order', {
            params: {
              user_id: user_id
            },
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
          setData(response.data);
          console.log(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [user_id]);
    console.log(data)
    return (
        <div>
            <div className='container mx-auto p-5'>

                <div className='mt-10'>
                    <div className='text-center text-[#4CACDE] font-semibold'>
                        <p>Thank You!</p>
                    </div>
                    <div className='text-center text-4xl font-bold text-[#15396D] mt-5'>
                        <h1 >Your order was completed successfully.</h1>
                    </div>
                  
                    <div className='text-center mt-5 mb-8 max-w-[600px] mx-auto'>
                        <p>We sent an email to <strong>{email}</strong> with your order confirmation and receipt. if the email hasn’t arrived, please check your spam folder to see if the email was routed there.</p>
                    </div>
                    <div className='text-center mb-10 max-w-[400px] mx-auto '>
                        <Link to='/my_order_list'>
                            <button className=' btn w-full text-white bg-[#1697DA] border-none hover:bg-[#55a377]'>See Your Order List</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Message;