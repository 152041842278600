import React from 'react';

const PrivacyAndPolicy = () => {
    return (
        <div className='text-left container mx-auto mt-10 mb-20'>
            <p>At E Beach BD, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our website or services.</p> <br />

            <p>Information We Collect</p> <br />

            <p>Personal Information: We may collect personal information such as your name, email address, phone number, shipping address, and billing information when you make a purchase or create an account on our website.</p> <br />

            <p>Usage Information: We collect information about your interactions with our website, including your IP address, browser type, pages viewed, and the time and date of your visit.</p> <br />

            <p>Cookies: We use cookies to enhance your browsing experience and collect data about your preferences and activities on our website. You can manage your cookie preferences through your browser settings.</p> <br />
            <p>
                How We Use Your Information <br />
                We use the information we collect for the following purposes:</p> <br />

            <p>Order Processing: To process and fulfill your orders, including shipping and payment processing.</p> <br />
            <p>Communication: To send order confirmations, updates, and marketing communications (if you opt-in) related to our products and services.</p> <br />
            <p>
                Improvement: To improve our website, products, and services based on user feedback and behavior.</p> <br />
            <p>
                Security <br />
                We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</p> <br />
            <p>
                Your Choices
                You have the following choices regarding your personal information:</p> <br />

            <p>Access and Update: You can access and update your personal information by logging into your account.</p> <br />

            <p>Contact Us <br />
                If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information, please contact us.</p>
        </div>
    );
};

export default PrivacyAndPolicy;