import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';


const PrivateRoutes = ({ children }) => {
    const userId = localStorage.getItem('userId')
    const { data: users = [], isLoading, refetch
    } = useQuery({
        queryKey: ['users'],
        queryFn: async () => {
            const res = await fetch(`https://backend.ebeachbd.com/api/days`)

            const data = await res.json()
            return data
        }
    })

    const email = localStorage.getItem('email')
    const location = useLocation()
    // if (isLoading) {
    //     return <div className="flex justify-center items-center h-screen bg-green-300">
    //         <div className="grid gap-2">
    //             <div className="flex items-center justify-center ">
    //                 <div className="w-40 h-40 border-t-4 border-b-4 border-red-900 rounded-full animate-spin"></div>
    //             </div>
    //         </div>

    //     </div>
    // }

    if (email) {
        return children
    }
    return <Navigate to='/login' state={{ from: location }} replace></Navigate>
};

export default PrivateRoutes;