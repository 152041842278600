

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import DetailsProductCart from './DetailsProductCart';

const Cart = ({ closeModal }) => {
    const allIdString = localStorage.getItem('cart');
    const allId = allIdString ? JSON.parse(allIdString) : [];

    const [idCounts, setIdCounts] = useState({});
    // State to store the ID of the selected shipping
    const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(true);
    useEffect(() => {
        // Count occurrences of each ID in allId
        const counts = allId.reduce((acc, id) => {
            acc[id] = (acc[id] || 0) + 1;
            return acc;
        }, {});

        // Modify the counts based on the condition
        Object.keys(counts).forEach(id => {
            if (counts[id] > 1) {
                counts[id] = allId.filter(val => val === parseInt(id)).length;
            }
        });

        setIdCounts(counts);
    }, []);


    const [getProduct, setGetProduct] = useState([]);

    useEffect(() => {
        fetch(`https://backend.ebeachbd.com/api/product-list`)
            .then(res => res.json())
            .then(data => setGetProduct(data));
    }, []);

    const handleQuantityChange = (productId, change) => {
        const updatedCounts = { ...idCounts };
        updatedCounts[productId] += change;
        if (updatedCounts[productId] < 1) {
            updatedCounts[productId] = 1; // Ensure quantity doesn't go below 1
        }
        setIdCounts(updatedCounts);

        // Update localStorage with the new quantity
        const allIdString = localStorage.getItem('cart');
        const allId = allIdString ? JSON.parse(allIdString) : [];
        const updatedIdArray = [];

        // Remove all occurrences of productId from the array
        allId.forEach(id => {
            if (id !== productId) {
                updatedIdArray.push(id);
            }
        });

        // Add productId to the array based on its new quantity
        for (let i = 0; i < updatedCounts[productId]; i++) {
            updatedIdArray.push(productId);
        }

        // Save the updated array to localStorage
        localStorage.setItem('cart', JSON.stringify(updatedIdArray));
    };

    const handleDelete = (productId) => {
        // Remove all occurrences of productId from localStorage
        const updatedIdArray = allId.filter(id => id !== productId);
        localStorage.setItem('cart', JSON.stringify(updatedIdArray));

        // Update state to reflect the change
        const updatedCounts = { ...idCounts };
        delete updatedCounts[productId];
        setIdCounts(updatedCounts);
    };

    const calculateTotalPrice = () => {
        let totalPrice = 0;
        filteredProducts?.forEach(cartProduct => {
            totalPrice += cartProduct.price * cartProduct.quantity;
        });
        return totalPrice;
    };

    const filteredProducts = getProduct?.data?.filter(product => {
        if (allId.includes(product.id)) {
            // Add quantity property to the product object
            product.quantity = idCounts[product.id] || 1;
            return true;
        }
        return false;
    });

    const [price, setPrice] = useState([])
    console.log(price)

    const [shipping, setShipping] = useState([])
    useEffect(() => {
        fetch(`https://backend.ebeachbd.com/api/shipping-list`)
            .then(res => res.json())
            .then(data => setShipping(data))
    }, [])

    console.log(shipping)

    const [selectedShipping, setSelectedShipping] = useState(null); // State to store the ID of the selected shipping
    const [deliveryCharge, setDeliveryCharge] = useState([])
    const handleCheckboxChange = (shippingId, price) => {
        setDeliveryCharge(price)
        localStorage.setItem('shipping_charge', price);
        if (selectedShipping === shippingId) {
            // Unselect the checkbox if it's already selected
            setSelectedShipping(null);
        } else {
            // Select the clicked checkbox
            setSelectedShipping(shippingId);
        }
    };

    const deliveryChargeNumber = parseFloat(deliveryCharge);
    console.log(deliveryChargeNumber);
    const history = useNavigate();
    const handleCheckout = () => {
        // Save total and subtotal to localStorage
        const total = calculateTotalPrice() + price + (deliveryChargeNumber ? deliveryChargeNumber : 0);
        const subtotal = calculateTotalPrice() + price;
        localStorage.setItem('total', total);
        localStorage.setItem('subtotal', subtotal);

        // Navigate to checkout page
        history('/confirm_order_page');
    };
    useEffect(() => {
        setIsCheckoutDisabled(selectedShipping === null); // Disable checkout if no shipping is selected
    }, [selectedShipping]);
    return (

        <div className="text-start mb-10">


            <>

                <h1 className="mb-10 text-center text-2xl font-bold">Cart Items</h1>
                <div className="mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
                    <div className="rounded-lg ">
                        <DetailsProductCart setPrice={setPrice}></DetailsProductCart>

                        {filteredProducts?.map(cartProduct => (

                            <div className="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start" key={cartProduct.id}>
                                <Link to={`/product/product_details/${cartProduct.slug}`}>

                                    <img src={cartProduct.photo} alt="product-image" className="w-full rounded-lg sm:w-40 lg:h-[100px] md:h-[100px]" />
                                </Link>
                                <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                                    <div className="mt-5 sm:mt-0">
                                        <Link to={`/product/product_details/${cartProduct.slug}`}>
                                            <h2 className="text-lg font-bold text-gray-900 hover:text-blue-700">{cartProduct.title}</h2>
                                        </Link>
                                        <p className="mt-1 text-xs text-gray-700">36EU - 4US</p>
                                        <p className="mt-5">{cartProduct.offer_price * cartProduct.quantity} BDT

                                        </p>
                                    </div>
                                    <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                                        <div className="flex items-center border-gray-100">
                                            <span onClick={() => handleQuantityChange(cartProduct.id, -1)} className="cursor-pointer rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-blue-500 hover:text-blue-50"> - </span>
                                            <input className="h-8 w-8 border bg-white text-center text-xs outline-none" type="number" value={cartProduct.quantity} min="1" readOnly />
                                            <span onClick={() => handleQuantityChange(cartProduct.id, 1)} className="cursor-pointer rounded-r bg-gray-100 py-1 px-3 duration-100 hover:bg-blue-500 hover:text-blue-50"> + </span>
                                        </div>
                                        <div className="flex items-center space-x-4" onClick={() => handleDelete(cartProduct.id)}>

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 cursor-pointer duration-150 hover:text-red-500">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))}

                    </div>

                    <div className="mt-6 h-full rounded-lg border bg-white p-6 shadow-md md:mt-0 md:w-1/3">
                        <div className="mb-2 flex justify-between">
                            <p className="text-gray-700">Subtotal</p>
                            <p className="text-gray-700">{calculateTotalPrice() + price} TAKA</p>
                        </div>
                        {shipping?.data?.map((ship) => (
                            <div key={ship.id} className="flex justify-between mt-2">
                                <div className='flex'>
                                    {/* Use checked attribute to check/uncheck the checkbox */}
                                    <input
                                        type="checkbox"
                                        checked={selectedShipping === ship.id} // Check if this checkbox should be checked
                                        onChange={() => handleCheckboxChange(ship.id, ship.delivery_charge)} // Call the function to handle checkbox change
                                    />
                                    <p className="text-gray-700 ml-3">{ship.shipping_address}</p>
                                </div>
                                <p className="text-gray-700">{ship.delivery_charge} TAKA</p>
                            </div>
                        ))}
                        <hr className="my-4" />
                        <div className="flex justify-between">
                            <p className="text-lg font-bold">Total:
                                {
                                    deliveryChargeNumber ?
                                        calculateTotalPrice() + price + deliveryChargeNumber

                                        :
                                        calculateTotalPrice() + price

                                } TAKA</p>
                            <div>

                                <p className="text-sm text-gray-700">including VAT</p>
                            </div>
                        </div>
                        <button disabled={isCheckoutDisabled} className='w-full' onClick={handleCheckout}>
                            <button onClick={closeModal} className="mt-6 w-full rounded-md bg-blue-500 py-1.5 font-medium text-blue-50 hover:bg-blue-600">
                               Confirm Cart
                            </button>
                        </button>
                    </div>
                </div>
            </>


        </div>
    );
};

export default Cart;


// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';

// const Cart = () => {
//     const allIdString = localStorage.getItem('cart');
//     const allId = allIdString ? JSON.parse(allIdString) : [];

//     const [idCounts, setIdCounts] = useState({});

//     useEffect(() => {
//         // Count occurrences of each ID in allId
//         const counts = allId.reduce((acc, id) => {
//             acc[id] = (acc[id] || 0) + 1;
//             return acc;
//         }, {});

//         // Modify the counts based on the condition
//         Object.keys(counts).forEach(id => {
//             if (counts[id] > 1) {
//                 counts[id] = allId.filter(val => val === parseInt(id)).length;
//             }
//         });

//         setIdCounts(counts);
//     }, []);


//     const [getProduct, setGetProduct] = useState([]);

//     useEffect(() => {
//         fetch(`https://backend.ebeachbd.com/api/product-list`)
//             .then(res => res.json())
//             .then(data => setGetProduct(data));
//     }, []);

//     const handleQuantityChange = (productId, change) => {
//         const updatedCounts = { ...idCounts };
//         updatedCounts[productId] += change;
//         if (updatedCounts[productId] < 1) {
//             updatedCounts[productId] = 1; // Ensure quantity doesn't go below 1
//         }
//         setIdCounts(updatedCounts);

//         // Update localStorage with the new quantity
//         const allIdString = localStorage.getItem('cart');
//         const allId = allIdString ? JSON.parse(allIdString) : [];
//         const updatedIdArray = [];

//         // Remove all occurrences of productId from the array
//         allId.forEach(id => {
//             if (id !== productId) {
//                 updatedIdArray.push(id);
//             }
//         });

//         // Add productId to the array based on its new quantity
//         for (let i = 0; i < updatedCounts[productId]; i++) {
//             updatedIdArray.push(productId);
//         }

//         // Save the updated array to localStorage
//         localStorage.setItem('cart', JSON.stringify(updatedIdArray));
//     };

//     const handleDelete = (productId) => {
//         // Remove all occurrences of productId from localStorage
//         const updatedIdArray = allId.filter(id => id !== productId);
//         localStorage.setItem('cart', JSON.stringify(updatedIdArray));

//         // Update state to reflect the change
//         const updatedCounts = { ...idCounts };
//         delete updatedCounts[productId];
//         setIdCounts(updatedCounts);
//     };

//     const filteredProducts = getProduct?.data?.filter(product => {
//         if (allId.includes(product.id)) {
//             // Add quantity property to the product object
//             product.quantity = idCounts[product.id] || 1;
//             return true;
//         }
//         return false;
//     });

//     return (

//         <div class="text-start">
//             <h1 class="mb-10 text-center text-2xl font-bold">Cart Items</h1>
//             <div class="mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
//                 <div class="rounded-lg ">

//                     {filteredProducts?.map(cartProduct => (

//                         <div class="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start">
//                             <Link to={`/product/product_details/${cartProduct.slug}`}>

//                                 <img src={cartProduct.photo} alt="product-image" className="w-full rounded-lg sm:w-40 lg:h-[100px] md:h-[100px]" />
//                             </Link>
//                             <div class="sm:ml-4 sm:flex sm:w-full sm:justify-between">
//                                 <div class="mt-5 sm:mt-0">
//                                     <h2 class="text-lg font-bold text-gray-900">{cartProduct.title}</h2>
//                                     <p class="mt-1 text-xs text-gray-700">36EU - 4US</p>
//                                     <p class="mt-5">{cartProduct.price * cartProduct.quantity} TAKA</p>
//                                 </div>
//                                 <div class="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
//                                     <div class="flex items-center border-gray-100">
//                                         <span onClick={() => handleQuantityChange(cartProduct.id, -1)} class="cursor-pointer rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-blue-500 hover:text-blue-50"> - </span>
//                                         <input class="h-8 w-8 border bg-white text-center text-xs outline-none" type="number" value={cartProduct.quantity} min="1" />
//                                         <span onClick={() => handleQuantityChange(cartProduct.id, 1)} class="cursor-pointer rounded-r bg-gray-100 py-1 px-3 duration-100 hover:bg-blue-500 hover:text-blue-50"> + </span>
//                                     </div>
//                                     <div class="flex items-center space-x-4" onClick={() => handleDelete(cartProduct.id)}>

//                                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 cursor-pointer duration-150 hover:text-red-500">
//                                             <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
//                                         </svg>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                     ))}

//                 </div>

//                 <div class="mt-6 h-full rounded-lg border bg-white p-6 shadow-md md:mt-0 md:w-1/3">
//                     <div class="mb-2 flex justify-between">
//                         <p class="text-gray-700">Subtotal</p>
//                         <p class="text-gray-700">$129.99</p>
//                     </div>
//                     <div class="flex justify-between">
//                         <p class="text-gray-700">Shipping</p>
//                         <p class="text-gray-700">$4.99</p>
//                     </div>
//                     <hr class="my-4" />
//                     <div class="flex justify-between">
//                         <p class="text-lg font-bold">Total</p>
//                         <div class="">

//                             <p class="text-sm text-gray-700">including VAT</p>
//                         </div>
//                     </div>
//                     <button class="mt-6 w-full rounded-md bg-blue-500 py-1.5 font-medium text-blue-50 hover:bg-blue-600">Check out</button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Cart;
