import React from 'react';
import Nav1 from './Nav1';
import Nav2 from './Nav2';
import Nav3 from './Nav3';


const Navbars = ({ handleCategoryChange, categories, searchQuery, setSearchQuery, handleSearch, filteredData }) => {

    return (
        <div>
            <Nav1
             handleCategoryChange={handleCategoryChange}
             categories={categories}
             searchQuery={searchQuery}
             setSearchQuery={setSearchQuery}
             handleSearch={handleSearch}
             filteredData={filteredData}
            ></Nav1>
            <Nav2
          
                handleCategoryChange={handleCategoryChange}
                categories={categories}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleSearch={handleSearch}
                filteredData={filteredData}
            ></Nav2>
            <Nav3
                handleCategoryChange={handleCategoryChange}
                categories={categories}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleSearch={handleSearch}
                filteredData={filteredData}
            ></Nav3>
        </div>
    );
};

export default Navbars;

// <Nav2
// handleCategoryChange={handleCategoryChange}
// categories={categories}
// searchQuery={searchQuery}
// setSearchQuery={setSearchQuery}
// handleSearch={handleSearch}
// ></Nav2>