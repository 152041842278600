import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaHeart } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa6';
import { Link, useParams } from 'react-router-dom';

const ShopProduct = () => {
    const { id } = useParams();
    const [shopProduct, setShopProduct] = useState([]);
    const [sortedProduct, setSortedProduct] = useState([]);
    const [sortByPrice, setSortByPrice] = useState("");
    const [visibleItems, setVisibleItems] = useState(30); // Initial number of items to display

    // useEffect(() => {
    //     fetch(`https://backend.ebeachbd.com/api/shop-details/${id}`)
    //         .then(res => res.json())
    //         .then(data => {
    //             setShopProduct(data);
    //             setSortedProduct(data.data); // Set sortedProduct initially
    //         });
    // }, [id]);
    useEffect(() => {
        fetch(`https://backend.ebeachbd.com/api/shop-details/${id}`)
            .then(res => res.json())
            .then(data => {
                setShopProduct(data);
                const sortedData = data.data.sort((a, b) => b.id - a.id); // Sort data array in descending order based on id
                setSortedProduct(sortedData);
            })
            .catch(error => {
                // Handle any errors from the fetch request
                console.error('Error fetching data:', error);
            });
    }, [id]);


    useEffect(() => {
        // Check if shopProduct.data is an array before updating sortedProduct
        if (Array.isArray(shopProduct.data)) {
            setSortedProduct([...shopProduct.data]);
        }
    }, [shopProduct]);

    const handleSortByPrice = (order) => {
        const sorted = [...shopProduct.data]; // Create a copy of the original data
        sorted.sort((a, b) => {
            if (order === "asc") {
                return a.offer_price - b.offer_price; // Sort in ascending order
            } else if (order === "desc") {
                return b.offer_price - a.offer_price; // Sort in descending order
            }
            return 0;
        });
        setSortByPrice(order);
        setSortedProduct(sorted);
    };

    const handleFilterByPriceRange = (minPrice, maxPrice) => {
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach(checkbox => {
            checkbox.checked = false; // Uncheck all checkboxes
        });

        const filteredCheckbox = document.getElementById(`${minPrice}-${maxPrice}`);
        filteredCheckbox.checked = true; // Check the clicked checkbox

        const filteredProducts = shopProduct?.data?.filter(product => product.offer_price >= minPrice && product.offer_price <= maxPrice);
        setSortedProduct(filteredProducts);
    };


    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = index => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };
    const [cart, setCart] = useState([])
    const handleAddToCart = (itemId, stock) => {
        // Retrieve existing cart items from localStorage
        const existingCartItems = JSON.parse(localStorage.getItem('cart')) || [];

        // Count the occurrences of the item in the cart
        const itemCountInCart = existingCartItems.filter(item => item === itemId).length;

        // Check if adding the item would exceed the available stock
        if (itemCountInCart >= stock) {
            // Display an alert if the cart exceeds the stock
            alert("You cannot add more of this product. Stock limit reached.");
            return; // Stop further execution
        }

        // Add the new item ID to the cart
        const updatedCartItems = [...existingCartItems, itemId];

        // Update the cart state and localStorage
        setCart(updatedCartItems);
        localStorage.setItem('cart', JSON.stringify(updatedCartItems));

        // Display a success toast
        toast.success('Added to cart successfully!');
    };

    const handleAddToFavouriteList = (itemId) => {
        // Retrieve existing cart items from localStorage
        const existingCartItems = JSON.parse(localStorage.getItem('favouriteList')) || [];

        // Add the new item ID to the cart
        const updatedCartItems = [...existingCartItems, itemId];

        // Update the cart state and localStorage
        setCart(updatedCartItems);
        localStorage.setItem('favouriteList', JSON.stringify(updatedCartItems));

        // Display a success toast
        toast.success('Added to favourite List successfully!');
    };


    const handleShowMore = () => {
        setVisibleItems(prev => prev + 500); // Increase the visible items count by 10
    };

    const uniqueShops = Array.from(new Set(sortedProduct?.map(item => item.shop.id)))
        .map(shopId => sortedProduct?.find(item => item.shop.id === shopId).shop);


    const [shopDetails, setShopDetails] = useState([])
    useEffect(() => {
        fetch(`https://backend.ebeachbd.com/api/single-shop/${id}`)
            .then(res => res.json())
            .then(data => setShopDetails(data))
    }, [id])

    console.log(sortedProduct)

    return (
        <div className='mb-10 '>
            <div className='flex gap-10 mb-5 bg-white px-10 py-5'>
                <div className="avatar">
                    <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                        <img src={shopDetails?.data?.photo} alt='' />
                    </div>

                </div>
                <div className='mt-4'>

                    <h1 className='text-2xl'><strong>{shopDetails?.data?.name}</strong> </h1>
                    <h1><strong>Total Products:</strong> {shopProduct?.data?.length}</h1>
                </div>
            </div>

            <div className='lg:flex  lg:px-20 container mx-auto'>
                <div class="mt-5 box  rounded-xl border  border-gray-300 bg-white p-6 lg:h-[500px]  lg:w-[30%]">
                    <select className="select mb-5 w-full bg-white select-bordered" value={sortByPrice} onChange={(e) => handleSortByPrice(e.target.value)}>
                        <option value="">Select Any</option>
                        <option value="desc">Higher To Lower</option>
                        <option value="asc">Lower To Higher</option>
                    </select>
                    <div class="box flex flex-col gap-5">
                        <div class="flex items-center">
                            <input id="100-300" name="100-300" onChange={() => handleFilterByPriceRange(100, 300)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                            <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300">100 Tk to 300 Tk</label>
                        </div>
                        <div class="flex items-center">
                            <input id="300-600" name="300-600" onChange={() => handleFilterByPriceRange(300, 600)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                            <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300">300 Tk to 600 Tk</label>
                        </div>
                        <div class="flex items-center">
                            <input id="600-1000" name="600-1000" onChange={() => handleFilterByPriceRange(600, 1000)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                            <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300">600 Tk to 1000 Tk</label>
                        </div>
                        <div class="flex items-center">
                            <input id="1000-1500" name="1000-1500" onChange={() => handleFilterByPriceRange(1000, 1500)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                            <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300">1000 Tk to 1500 Tk</label>
                        </div>
                        <div class="flex items-center">
                            <input id="1500-2000" name="1500-2000" onChange={() => handleFilterByPriceRange(1500, 2000)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                            <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300">1500 Tk to 2000 Tk</label>
                        </div>
                        <div class="flex items-center">
                            <input id="2000-2500" name="2000-2500" onChange={() => handleFilterByPriceRange(2000, 2500)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                            <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300">2000 Tk to 2500 Tk</label>
                        </div>
                        <div class="flex items-center">
                            <input id="2500-125000" name="2500-125000" onChange={() => handleFilterByPriceRange(2500, 125000)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                            <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300"> 2500 Tk ++</label>
                        </div>
                    </div>
                </div>

                <div className='lg:w-[70%]'>
                    <div className='mx-auto max-w-[1440px]  items-center grid grid-cols-1 mt-5  px-10'>

                        {sortedProduct.slice(0, visibleItems).map((product, index) => (
                            <div className="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start" key={product.id}>
                                <a href={`/product/product_details/${product.slug}`}>

                                    <img src={product.photo} alt="product-image" className="w-full rounded-lg sm:w-40 lg:h-[100px] md:h-[100px]" />
                                </a>
                                <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                                    <div className="mt-5 sm:mt-0 ">
                                        <a href={`/product/product_details/${product.slug}`}>
                                            <h2 className="text-lg font-bold text-gray-900 hover:text-blue-700 text-start">{product.title}</h2>
                                        </a>
                                        <p className='text-black text-start'>Stock: {product.stock}</p>

                                        <div className='flex gap-2'>
                                            <div>{product.offer_price}</div>
                                            <div> <del className='text-red-500'>{product.price}</del></div>
                                        </div>
                                        <div className='flex text-[#FACA15]'>
                                            <FaStar></FaStar>
                                            <FaStar></FaStar>
                                            <FaStar></FaStar>
                                            <FaStar></FaStar>
                                            <FaStar></FaStar>
                                        </div>
                                    </div>
                                    <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                                        <div className="flex justify-end border-gray-100">
                                            <button className="bg-[#be1e2d] text-white px-2 py-2 rounded hover:bg-[#be1e2d]" onClick={() => handleAddToFavouriteList(product.id)} >
                                                <svg stroke="currentColor"
                                                    className='h-6 w-6'
                                                    fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="2em" xmlns="http://www.w3.org/2000/svg"><path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>
                                            </button>
                                        </div>
                                        <div className="flex justify-end border-gray-100">
                                            <button className="bg-[#be1e2d] text-white px-2 py-2 rounded hover:bg-[#be1e2d]" onClick={() => handleAddToCart(product.id, product.stock)} >
                                                <svg xmlns="http://www.w3.org/2000/svg" class=" h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                                </svg>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
            </div>




            {sortedProduct.length > visibleItems && (
                <div className="flex justify-center mt-5">
                    <button onClick={handleShowMore} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                        Show More
                    </button>
                </div>
            )}
        </div>
    );
};

export default ShopProduct;



// <div className='container mx-auto items-center grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3  mt-5'>
//     {
//         shopProduct?.data?.map(product =>
//             <>
//     <div class="absolute h-full w-full bg-black/20 flex items-center justify-center -bottom-10 group-hover:bottom-0 opacity-0 group-hover:opacity-100 transition-all duration-300">
//     <button class="bg-black text-white py-2 px-5">Add to cart</button>
// </div>

//                 <div className=" lg:w-56 lg:h-72 md:w-56 md:h-72 w-full glass h-full ">
//                     <figure><img className='lg:h-48 lg:w-56 md:h-48 md:w-56 w-full h-full' src={product.photo} alt="car!" /></figure>
//                     <div className="card-body">
//                         {/* <h2 className="card-title">Life hack</h2>
//             <p>How to park your car at your garage?</p> */}

//                     </div>
//                 </div>
//             </>

//         )
//     }
// </div>
//   <Link to={`/product/product_details/${product.slug}`}>
// <div
//     key={product.id}
//     className="bg-slate-100 glass w-full relative group text-start h-96"
//     onMouseEnter={() => handleMouseEnter(index)}
//     onMouseLeave={handleMouseLeave}
// >
//     <figure>
//         <img src={product.photo} style={{ height: '200px' }} alt="car!" className='w-full' />
//     </figure>

//     {hoveredIndex === index && (
//         <>

//             <div className="absolute top-40 left-0 ">

//                 <button onClick={() => handleAddToCart(product.id, product.stock)} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex">
//                     <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
//                         <path stroke-linecap="round" stroke-linejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
//                     </svg>
//                     Add to Cart</button>
//             </div>
//             <div className="absolute top-0 right-0 ">
//                 <button onClick={() => handleAddToFavouriteList(product.id)} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"><FaHeart /></button>
//             </div>
//             <div className="absolute top-0 left-0 ">
//                 <span class="absolute top-4 left-4 w-20 translate-y-2 -translate-x-6 -rotate-45 bg-black text-center text-sm text-white">{product.condition}</span>

//             </div>
//         </>
//     )}

//     <div className="card-body">

//         <Link to={`/product/product_details/${product.slug}`}>
//             <h2 className="card-title">{product.title.slice(0, 20) + '...'}</h2>
//         </Link>
//         <div className='flex gap-2'>
//             <div>{product.price}</div>
//             <div> <del className='text-red-500'>{product.discount}</del></div>
//         </div>
//         <p>Stock: {product.stock}</p>
//     </div>
// </div>
// </Link>

{/* <div className='mt-5'>
                    <fieldset className="filter-by-price-range">
                        <legend>Filter by Price Range</legend>
                        <div>
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" id="100-300" name="100-300" onChange={() => handleFilterByPriceRange(100, 300)} />
                            <label className="ml-2 text-gray-700" htmlFor="100-300">100 Tk to 300 Tk</label>
                        </div>
                        <div>
                            <input className="form-checkbox h-5 w-5 text-gray-600" type="checkbox" id="300-600" name="300-600" onChange={() => handleFilterByPriceRange(300, 600)} />
                            <label className="ml-2 text-gray-700" htmlFor="300-600">300 Tk To 600 Tk</label>
                        </div>
                        <div>
                            <input className="form-checkbox h-5 w-5 text-gray-600" type="checkbox" id="600-1000" name="600-1000" onChange={() => handleFilterByPriceRange(600, 1000)} />
                            <label className="ml-2 text-gray-700" htmlFor="600-1000">600 Tk To 1000 Tk</label>
                        </div>
                        <div>
                            <input className="form-checkbox h-5 w-5 text-gray-600" type="checkbox" id="1000-1500" name="1000-1500" onChange={() => handleFilterByPriceRange(1000, 1500)} />
                            <label className="ml-2 text-gray-700" htmlFor="1000-1500">1000 Tk To 1500 Tk</label>
                        </div>
                        <div>
                            <input className="form-checkbox h-5 w-5 text-gray-600" type="checkbox" id="1500-2000" name="1500-2000" onChange={() => handleFilterByPriceRange(1500, 2000)} />
                            <label className="ml-2 text-gray-700" htmlFor="1500-2000">1500 Tk To 2000 Tk</label>
                        </div>
                        <div>
                            <input className="form-checkbox h-5 w-5 text-gray-600" type="checkbox" id="2000-2500" name="2000-2500" onChange={() => handleFilterByPriceRange(2000, 2500)} />
                            <label className="ml-2 text-gray-700" htmlFor="2000-2500">2000 Tk To 2500 Tk</label>
                        </div>
                    </fieldset>
                </div> */}

{/* <div className='mt-5'>
                   <p value="100-300">
                   <input type="checkbox" name="" id="" />
                   100 Tk to 300 Tk
                   </p>
                
                    <select className="select bg-white select-bordered" onChange={(e) => {
                        const priceRange = e.target.value.split("-");
                        handleFilterByPriceRange(parseInt(priceRange[0]), parseInt(priceRange[1]));
                    }}>
                        <option value="">Filter by Price Range</option>
                        <option value="100-300">100 Tk to 300 Tk</option>
                        <option value="300-600">300 Tk To 600</option>
                        <option value="600-1000">600 Tk To 1000</option>
                        <option value="1000-1500">1000 Tk To 1500</option>
                        <option value="1500-2000">1500 Tk To 2000</option>
                        <option value="2000-2500">2000 Tk To 2500</option>
                    </select>
                </div> */}