import React from 'react';
import image from '../../../img/become.png'
import image2 from '../../../img/update.jpeg'
import { Link } from 'react-router-dom';

const SellerType = () => {
    const backgroundImageStyle = {
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100vw',  // Set width to 100% of viewport width
        // height: '100vh', //
    };
    return (
        <div className="" style={backgroundImageStyle}>
            <div className='lg:flex  p-10 justify-evenly'>
                <div className=' mb-10'>
                    <h1 className='text-6xl font-extrabold text-[#be1e2d]'>Best Online Shopping</h1>
                </div>
               
                <div className="card shrink-0 lg:mt-32 md:mt-32 mt -10 shadow-2xl bg-white lg:w-[400px] w-full mb-10">
                    <div className="card-body">
                        <h1 className='text-2xl text-[#be1e2d]'>Create An Account</h1>
                        <p><small>Welcome To E Beach BD</small></p>

                        <div className="form-control mt-6">
                            <Link className='btn bg-[#be1e2d] text-white font-semibold text-xl border-none' to={`/become_seller`}>
                                Local Seller

                            </Link>
                        </div>
                        <div className="form-control ">
                            <Link className='btn bg-[#be1e2d] text-white font-semibold text-xl border-none' to={`/become_seller`}>
                                Brand Seller
                            </Link>
                        </div>
                        <div className="form-control ">
                            <Link className='btn bg-[#be1e2d] text-white font-semibold text-xl border-none' to={`/become_seller/digital_service`}>
                                Digital Service
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default SellerType;