import React from 'react';
import HomeSlider from '../HomeSlider/HomeSlider';
import HomeShop from '../HomeShop/HomeShop';
import HomeProduct from '../HomeProduct/HomeProduct';
import HomeNewArrival from '../HomeNewArrival/HomeNewArrival';
import WinterProducts from '../WinterProducts/WinterProducts';
import PopularProducts from '../PopularProducts/PopularProducts';
import SearchData from '../SearchData/SearchData';


const Home = () => {








    return (
        <div className=''>
            {/* <SearchData></SearchData> */}
            <HomeSlider></HomeSlider>
            <HomeProduct></HomeProduct>
            <HomeShop></HomeShop>
            <HomeNewArrival></HomeNewArrival>
            <PopularProducts></PopularProducts>
            <WinterProducts></WinterProducts>


        </div>
    );
};

export default Home;

{/* <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
<li><a>Item 1</a></li>
<li><a>Item 2</a></li>
</ul> */}