import React from 'react';
import { useRouteError } from 'react-router-dom';
import img from '../../../img/error.jpg'
const DisplayError = () => {
    const error = useRouteError()
    return (
        <div>
        <img className=' mx-auto' src={img} alt="" />
        {/* <div className='text-center'>
        <p className="text-red-500">Something Went Wrong</p>
        <p className="text-red-600">{error.statusText || error.message}</p>
        <h3 className="text-3xl">please Back To Home</h3>
        </div> */}
    </div>
    );
};

export default DisplayError;