import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const MyOrderList = () => {
  const [data, setData] = useState([]);
  const user_id = localStorage.getItem('user_id')
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://backend.ebeachbd.com/api/user_order', {
          params: {
            user_id: user_id
          },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        setData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [user_id]);
  console.log(data.data)
  const [orderNumber, setOrderNumber] = useState([])

  const handleClick = (allOrder) => {
    console.log(allOrder)
    setOrderNumber(allOrder)
  }

  const filteredData = data?.data?.filter(item => item.order_number === orderNumber);
  console.log(filteredData)
  const orderData = (filteredData?.map(data => data?.product_details));
  console.log(orderData)
  const [totalSum, setTotalSum] = useState(0);
  useEffect(() => {
      let sum = 0;
      orderData?.forEach(order => {
          order?.forEach(item => {
              sum += item.offer_price * item.qty;
          });
      });
      // Round up the sum to the nearest whole number
      const roundedSum = Math.ceil(sum);
      setTotalSum(roundedSum);
      
  }, [orderData]);
  console.log(totalSum)

  const total = filteredData?.map(data => data?.total_amount)

  const shipptingCost = total - totalSum
  console.log(Math.ceil(shipptingCost))

  const [user, setUser] = useState([])
  useEffect(() => {
      fetch(`https://backend.ebeachbd.com/api/user-name/${user_id}`)
          .then(res => res.json())
          .then(data => setUser(data))
  }, [user_id])

  console.log(user.data)
  const name = localStorage.getItem('name')
  console.log(data.data)
  return (

    <div className='max-w-[1000px]  mx-auto mt-10'>
      <div className='text-start mb-3'>
        <h1 className='font-bold'>Hello, {name} </h1>
        <h3>Recent order</h3>
      </div>
      <div class="flex flex-col px-6 py-3  mb-10 bg-[#F8F8FF] rounded">

        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div class="overflow-hidden">
              <table
                class="min-w-full border border-black text-center text-sm font-light text-surface dark:border-black text-black rounded">
                <thead
                  class="border-b border-black font-medium dark:border-black">
                  <tr>
                    <th
                      scope="col"
                      class="border-e border-black px-6 py-4 dark:border-black">
                      #
                    </th>
                    <th
                      scope="col"
                      class="border-e border-black px-6 py-4 dark:border-black">
                      Order
                    </th>
                    <th
                      scope="col"
                      class="border-e border-black px-6 py-4 dark:border-black">
                      Date
                    </th>
                    <th
                      scope="col"
                      class="border-e border-black px-6 py-4 dark:border-black">
                      Status
                    </th>
                    <th
                      scope="col"
                      class="border-e border-black px-6 py-4 dark:border-black">
                      Total
                    </th>
                    <th
                      scope="col"
                      class="border-e border-black px-6 py-4 dark:border-black">
                      Invoice
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {
                    data?.data?.map((order, index) =>
                      <>

                        <tr class="border-b border-black dark:border-black">
                          <td
                            class="whitespace-nowrap border-e border-black px-6 py-4 font-bold dark:border-black">
                            {index + 1}
                          </td>
                          <td
                            class="font-medium whitespace-nowrap border-e border-black px-6 py-4 dark:border-black">
                            {order.order_number}
                          </td>
                          <td
                            class="whitespace-nowrap font-medium border-e border-black px-6 py-4 dark:border-black">
                            {order.date}
                          </td>
                          <td
                            class="whitespace-nowrap font-medium border-e border-black px-6 py-4 dark:border-black">
                            <button className='btn btn-xs btn-info text-white'>
                              {order.status}
                            </button>
                          </td>
                          <td
                            class="whitespace-nowrap border-e font-medium border-black px-6 py-4 dark:border-black">
                            {order.total_amount}
                          </td>
                          <td
                            class="whitespace-nowrap border-e font-medium border-black px-6 py-4 dark:border-black">
                            <Link to={`/order_list_view/${order.order_number}`}>
                            <button  className='btn btn-primary btn-sm text-white'>View </button>
                            </Link>
                          </td>
                          {/* onClick={() => handleClick(order.order_number)} */}
                        </tr>

                      </>

                    )
                  }



                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrderList;
