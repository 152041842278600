import React, { useState } from 'react';
import { AiOutlineHeart } from 'react-icons/ai';
import { FaBars, FaShoppingCart, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Nav3 = ({filteredData}) => {

  const [navbarOpen, setNavbarOpen] = useState(false);


  return (
    <div>
      {
        filteredData.length > 0 ?
        <div>
        <nav className="relative bg-[#be1e2d] lg:hidden flex flex-wrap items-center shadow-xl justify-between px-2 py-3  lg:mb-3 md:mb-0 mb-0">
  
          <div className="container px-4 mx-auto flex flex-wrap items-center justify-between ">
            <div className="w-full relative flex justify-between lg:w-auto lg:static lg:justify-start ">
              {/* <a
                className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap  text-white"
                href='/'
              >
                <div className=''>
                  <h1 className='text-2xl'><strong>E Beach BD</strong></h1>
                  <p><small>Get Ready To Shop</small></p>
                </div>
              </a> */}
  
  
              <button
                className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <FaBars></FaBars>
              </button>
            </div>
            <div
              className={
                "lg:flex flex-grow items-center" +
                (navbarOpen ? " flex" : " hidden")
              }
              id="example-navbar-danger"
            >
              <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
  
  
  
                <li className="nav-item">
                  <a
                    className=" py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                    href='/product/category'
                  >
                    <span className="md:ml-80 lg:ml-2 text-lg">Categories</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className=" py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                    href='/brand/all_brand'
                  >
                    <span className="md:ml-80 lg:ml-2 text-lg"> Brand</span>
                  </a>
                </li>
  
                <li className="nav-item">
                  <a
                    className=" py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                    href='/shop/all_shop'
                  >
                    <span className="md:ml-80 lg:ml-2 text-lg"> All Shop</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className=" py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                    href='/'
                  >
                    <span className="md:ml-80 lg:ml-2 text-lg"> Voucher Products</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className=" py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                    href='/'
                  >
                    <span className="md:ml-80 lg:ml-2 text-lg"> Offers</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
  
        </nav>
  
  
        <nav className='bg-[#be1e2d]  px-2 py-1 hidden lg:block md:hidden'>
  
          <ul className="flex justify-evenly">
  
  
  
            <li className="nav-item">
              <a
                className="px-3 py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                href='/product/category'
              >
                <span className="md:ml-96 lg:ml-2 text-lg">Categories</span>
              </a>
            </li>
  
  
  
  
            <li className="nav-item">
              <a
                className="px-3 py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                href='/brand/all_brand'
              >
                <span className="md:ml-96 lg:ml-2 text-lg"> Brand</span>
              </a>
            </li>
  
            <li className="nav-item">
              <a
                className="px-3 py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                href='/shop/all_shop'
              >
                <span className="md:ml-96 lg:ml-2 text-lg"> All Shop</span>
              </a>
            </li>
  
  
            <li className="nav-item">
              <a
                className=" py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                href='/'
              >
                <span className="md:ml-80 lg:ml-2 text-lg"> Voucher Products</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className=" py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                href='/'
              >
                <span className="md:ml-80 lg:ml-2 text-lg"> Offers</span>
              </a>
            </li>
  
  
  
  
  
  
  
  
  
  
  
          </ul>
        </nav>
      </div>
      :
      <div>
      <nav className="relative bg-[#be1e2d] lg:hidden flex flex-wrap items-center shadow-xl justify-between px-2 py-3  lg:mb-3 md:mb-0 mb-0">

        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between ">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:justify-start ">
            {/* <a
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap  text-white"
              href='/'
            >
              <div className=''>
                <h1 className='text-2xl'><strong>E Beach BD</strong></h1>
                <p><small>Get Ready To Shop</small></p>
              </div>
            </a> */}


            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FaBars></FaBars>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">



              <li className="nav-item">
                <Link
                  className=" py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                  to='/product/category'
                >
                  <span className="md:ml-80 lg:ml-2 text-lg">Categories</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className=" py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                  to='/brand/all_brand'
                >
                  <span className="md:ml-80 lg:ml-2 text-lg"> Brand</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className=" py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                  to='/shop/all_shop'
                >
                  <span className="md:ml-80 lg:ml-2 text-lg"> All Shop</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className=" py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                  to='/voucher_product'
                >
                  <span className="md:ml-80 lg:ml-2 text-lg"> Voucher Products</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className=" py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                  to='/offer_product'
                >
                  <span className="md:ml-80 lg:ml-2 text-lg"> Offers</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

      </nav>


      <nav className='bg-[#be1e2d]  px-2 py-1 hidden lg:block md:hidden'>

        <ul className="flex justify-evenly">



          <li className="nav-item">
            <Link
              className="px-3 py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
              to='/product/category'
            >
              <span className="md:ml-96 lg:ml-2 text-lg">Categories</span>
            </Link>
          </li>




          <li className="nav-item">
            <Link
              className="px-3 py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
              to='/brand/all_brand'
            >
              <span className="md:ml-96 lg:ml-2 text-lg"> Brand</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className="px-3 py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
              to='/shop/all_shop'
            >
              <span className="md:ml-96 lg:ml-2 text-lg"> All Shop</span>
            </Link>
          </li>


          <li className="nav-item">
            <Link
              className=" py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
              to='/voucher_product'
            >
              <span className="md:ml-80 lg:ml-2 text-lg"> Voucher Products</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className=" py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
              to='/offer_product'
            >
              <span className="md:ml-80 lg:ml-2 text-lg"> Offers</span>
            </Link>
          </li>











        </ul>
      </nav>
    </div>
      }

   
    </div>
  );
};

export default Nav3;