import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const DetailsProductCart = ({ setPrice }) => {
    const [details_cart_data, setDetailsCartData] = useState(JSON.parse(localStorage.getItem('DetailsCart')) || []); // Parse JSON string and initialize as empty array if null
    const [matchedProducts, setMatchedProducts] = useState([]); // State variable to store matched products

    useEffect(() => {
        fetch(`https://backend.ebeachbd.com/api/product-list`)
            .then(res => res.json())
            .then(data => {
                const matchedProductsArray = [];

                // Iterate through each object in details_cart_data
                details_cart_data.forEach(cartItem => {
                    // Find the product that matches the ID from details_cart_data
                    const matchedProduct = data?.data?.find(product => product.id === cartItem.id);

                    // If a match is found, add it to the matchedProductsArray
                    if (matchedProduct) {
                        matchedProductsArray.push({
                            ...matchedProduct,
                            count: cartItem.count,
                            selectedColor: cartItem.selectedColor,
                            selectedSize: cartItem.selectedSize
                        });
                    }
                });

                setMatchedProducts(matchedProductsArray); // Set matched products to state
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [details_cart_data]); // Include details_cart_data in the dependency array to fetch the data whenever it changes
    console.log(matchedProducts)
    const decrementCount = (index) => {
        const updatedDetailsCartData = [...details_cart_data];
        if (updatedDetailsCartData[index].count > 1) {
            updatedDetailsCartData[index].count -= 1;
            setDetailsCartData(updatedDetailsCartData);
            localStorage.setItem('DetailsCart', JSON.stringify(updatedDetailsCartData));
        }
    };


    const incrementCount = (index) => {
        const updatedDetailsCartData = [...details_cart_data];
        updatedDetailsCartData[index].count += 1;
        setDetailsCartData(updatedDetailsCartData);
        localStorage.setItem('DetailsCart', JSON.stringify(updatedDetailsCartData));
    };

    const removeFromCart = (index) => {
        const updatedDetailsCartData = [...details_cart_data];
        updatedDetailsCartData.splice(index, 1);
        setDetailsCartData(updatedDetailsCartData);
        localStorage.setItem('DetailsCart', JSON.stringify(updatedDetailsCartData));
        toast.success('Item removed from cart');
    };
    const calculateTotalPrice = () => {
        let totalPrice = 0;
        matchedProducts.forEach(product => {
            totalPrice += product.offer_price * product.count;
        });
        return totalPrice;
    };


    useEffect(() => {
        setPrice(calculateTotalPrice)
    }, [calculateTotalPrice])

    return (
        // <div className="mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
        <div className="rounded-lg ">

            {
                matchedProducts?.map((matched, index) => (

                    <div key={index} className="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start" >
                        <Link to={`/product/product_details/${matched.slug}`}>

                            <img src={matched.photo} alt="product-image" className="w-full rounded-lg sm:w-40 lg:h-[100px] md:h-[100px]" />
                        </Link>
                        <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                            <div className="mt-5 sm:mt-0">
                                <h2 className="text-lg font-bold text-gray-900">{matched.title}</h2>
                                {/* style={{ backgroundColor: matched.selectedColor }} */}
                                <p className="mt-1 text-xs text-gray-700" >Color : {matched.selectedColor}</p>
                                <p className="mt-1 text-xs text-gray-700">Size : {matched.selectedSize}</p>
                                <p className="mt-5">{matched.offer_price * matched.count} <del className='text-red-400 ml-2'>{matched.price}</del>

                                </p>

                            </div>
                            <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                                <div className="flex items-center border-gray-100">
                                    <span onClick={() => decrementCount(index)} className="cursor-pointer rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-blue-500 hover:text-blue-50"> - </span>
                                    <input className="h-8 w-8 border bg-white text-center text-xs outline-none" type="number" min="1" readOnly value={matched.count} />
                                    <span onClick={() => incrementCount(index)} className="cursor-pointer rounded-r bg-gray-100 py-1 px-3 duration-100 hover:bg-blue-500 hover:text-blue-50"> + </span>
                                </div>
                                <div className="flex items-center space-x-4" >

                                    <svg onClick={() => removeFromCart(index)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 cursor-pointer duration-150 hover:text-red-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        {/* <div className="mt-5">
                            <h3 className="text-lg font-semibold">Total Price: {calculateTotalPrice()} TAKA</h3>
                        </div> */}
                    </div>
                ))

            }
        </div>
        // </div>
    );
};

export default DetailsProductCart;
