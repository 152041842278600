import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const WishList = () => {
    const allIdString = localStorage.getItem('favouriteList');
    const allId = allIdString ? JSON.parse(allIdString) : [];

    const [idCounts, setIdCounts] = useState({});

    useEffect(() => {
        // Count occurrences of each ID in allId
        const counts = allId.reduce((acc, id) => {
            acc[id] = (acc[id] || 0) + 1;
            return acc;
        }, {});

        // Modify the counts based on the condition
        Object.keys(counts).forEach(id => {
            if (counts[id] > 1) {
                counts[id] = allId.filter(val => val === parseInt(id)).length;
            }
        });

        setIdCounts(counts);
    }, []);


    const [getProduct, setGetProduct] = useState([]);

    useEffect(() => {
        fetch(`https://backend.ebeachbd.com/api/product-list`)
            .then(res => res.json())
            .then(data => setGetProduct(data));
    }, []);


    const handleDelete = (productId) => {
        // Remove all occurrences of productId from localStorage
        const updatedIdArray = allId.filter(id => id !== productId);
        localStorage.setItem('favouriteList', JSON.stringify(updatedIdArray));

        // Update state to reflect the change
        const updatedCounts = { ...idCounts };
        delete updatedCounts[productId];
        setIdCounts(updatedCounts);
    };


    const filteredProducts = getProduct?.data?.filter(product => {
        if (allId.includes(product.id)) {
            // Add quantity property to the product object
            product.quantity = idCounts[product.id] || 1;
            return true;
        }
        return false;
    });

    const [cart, setCart] = useState([])
    const handleAddToCart = (itemId, stock) => {
        // Retrieve existing cart items from localStorage
        const existingCartItems = JSON.parse(localStorage.getItem('cart')) || [];

        // Count the occurrences of the item in the cart
        const itemCountInCart = existingCartItems.filter(item => item === itemId).length;

        // Check if adding the item would exceed the available stock
        if (itemCountInCart >= stock) {
            // Display an alert if the cart exceeds the stock
            alert("You cannot add more of this product. Stock limit reached.");
            return; // Stop further execution
        }

        // Add the new item ID to the cart
        const updatedCartItems = [...existingCartItems, itemId];

        // Update the cart state and localStorage
        setCart(updatedCartItems);
        localStorage.setItem('cart', JSON.stringify(updatedCartItems));

        // Display a success toast
        toast.success('Added to cart successfully!');
    };

    return (
        <div className="text-start mt-10">
            {/* <h1 className="mb-10 text-center text-2xl font-bold">Cart Items</h1> */}
            <div className="mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
                <div className="rounded-lg ">


                    {
                        filteredProducts?.length > 0
                            ?
                            filteredProducts?.map(cartProduct => (

                                <div className="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start" key={cartProduct.id}>
                                    <Link to={`/product/product_details/${cartProduct.slug}`}>

                                        <img src={cartProduct.photo} alt="product-image" className="w-full rounded-lg sm:w-40 lg:h-[100px] md:h-[100px]" />
                                    </Link>
                                    <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                                        <div className="mt-5 sm:mt-0">
                                            <Link to={`/product/product_details/${cartProduct.slug}`}>
                                                <h2 className="text-lg font-bold text-gray-900 hover:text-blue-700">{cartProduct.title}</h2>
                                            </Link>
                                            <p className="mt-1 text-xs text-gray-700">36EU - 4US</p>
                                            <p className="mt-5">{cartProduct.offer_price}
                                                <del className='text-red-600 ml-2'>{cartProduct.price}</del>
                                                BDT</p>
                                        </div>
                                        <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                                            <div className="flex items-center border-gray-100">
                                                <button className='btn bg-[#be1e2d] text-white border-none btn-sm px-10 rounded-none py-2' onClick={() => handleAddToCart(cartProduct.id, cartProduct.stock)}>Add To Cart</button>
                                            </div>
                                            <div className="flex items-center space-x-4" onClick={() => handleDelete(cartProduct.id)}>

                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 cursor-pointer duration-150 hover:text-red-500">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))

                            :
                            <div className='text-center mt-5'>
                                Nothing Found

                            </div>
                    }


                </div>


            </div>
        </div>
    );
};

export default WishList;