// import React, { useEffect, useState } from 'react';
// import { IoIosStar, IoIosStarOutline } from 'react-icons/io';
// import { Link } from 'react-router-dom';
// import Slider from 'react-slick';

// const ProductDescription = ({homes}) => {

//     const [activeButton, setActiveButton] = useState('login');
//     const [related, setRelated] = useState([])


//     const [loading, setLoading] = useState(false);


//     useEffect(() => {
//         setLoading(true);
//         fetch(`https://backend.ebeachbd.com/api/product-list`)
//             .then(response => response.json())
//             .then(data => {
//                 setRelated(data);
//                 setLoading(false);
//             });
//     }, []);
//     const [AllReview, setAllReview] = useState([])
//     useEffect(() => {
//         fetch(`https://backend.ebeachbd.com/api/get-review/${homes?.data?.id}`)
//             .then(response => response.json())
//             .then(data => {
//                 setAllReview(data);

//             });
//     }, [homes?.data?.id])


//     const relatedProd = homes?.data?.cat_id
//     const relatedProducts = related?.data?.filter(item => item?.cat_id === relatedProd);


//     const [topSelling, setTopSelling] = useState([])
//     useEffect(() => {
//         setLoading(true);
//         fetch(`https://backend.ebeachbd.com/api/bestSelling-list`)
//             .then(response => response.json())
//             .then(data => {
//                 setTopSelling(data);
//                 setLoading(false);
//             });
//     }, []);

//     const settings = {
//         // dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 5,
//         slidesToScroll: 1,
//         autoplay: true, // Set autoplay to true
//         autoplaySpeed: 3000,

//         initialSlide: 0,
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 3,
//                     infinite: true,
//                     dots: true
//                 }
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 2,
//                     initialSlide: 2
//                 }
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1
//                 }
//             }
//         ]
//     };


//     const [selectedRating, setSelectedRating] = useState(null);

//     const handleRatingChange = (event) => {
//         setSelectedRating(event.target.value);
//     };

//     const user_id = localStorage.getItem('user_id')


//     const expense_create = (event) => {
//         event.preventDefault();

//         const form = event.target





//         const star = selectedRating;
//         const message = form.message.value;





//         const productData = {
//             star, message, user_id, product_id: homes?.data?.id

//         }
//         console.log(productData)


//         fetch(`https://backend.ebeachbd.com/api/review-store`, {
//             method: 'POST',
//             headers: {
//                 authorization: `Bearer ${localStorage.getItem('token')}`,
//                 'content-type': 'application/json',
//             },
//             body: JSON.stringify(productData),
//         })
//             .then((Response) => Response.json())
//             .then((data) => {
//                 // refetch()
//                 form.reset();
//                 console.log(data)




//             })
//             .catch((error) => console.error(error));

//     }



//     return (
//         <div className="lg:flex container mx-auto lg:p-0 md:px-5 px-8">
//         <div className='lg:w-[25%]  mt-16 px-4  '>
//             <p className='mb-4 font-bold'>Top Selling Products</p>
//             <div className="dropdown rounded-xl dropdown-hover dropdown-right   block  flex-col bg-white py-1 text-gray-800 shadow-xl group-hover:visible">
//                 <div className="mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
//                     <div className="rounded-lg ">
//                         {
//                             topSelling?.data?.slice(0, 6).map((product => (
//                                 <Link to={`/product/product_details/${product.slug}`}>
//                                     <div className="justify-between rounded-lg bg-white p-6 sm:flex sm:justify-start" >
//                                         <img src={product.photo} alt="product-image" className="w-full rounded-lg sm:w-40 lg:h-[100px] md:h-[100px]" />


//                                         <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
//                                             <div className="mt-5 sm:mt-0">

//                                                 <p className="mt-1 text-xs text-gray-700 underline">{product.title}</p>
//                                                 <p className="mt-5 text-orange-400 font-semibold">{product.price} TAKA</p>
//                                             </div>
//                                         </div>

//                                     </div>
//                                 </Link>
//                             )))
//                         }

//                     </div>


//                 </div>
//             </div>
//         </div>


//         <div className=' container mx-auto lg:w-[75%]   mt-10 gap-10'>

//             <div class="flex   mt-6 ">
//                 <a className={`w-1/3 pb-4 font-medium text-center text-gray-800 capitalize border-b-2  dark:text-black ${activeButton === 'login' ? 'bg-gray-700' : ''}`} onClick={() => setActiveButton('login')}>
//                     Description
//                 </a>

//                 <a className={`w-1/3 pb-4 font-medium text-center text-gray-800 capitalize border-b-2 dark:text-black ${activeButton === 'registration' ? 'bg-gray-700' : ''}`} onClick={() => setActiveButton('registration')}>
//                     Review
//                 </a>
//             </div>






//             <div>
//                 {activeButton === 'login' && (


//                     <div class="w-full mt-5 py-6">



//                         <form action="" >


//                             <p>{homes?.data?.description}</p>


//                         </form>
//                     </div>

//                 )}
//                 {activeButton === 'registration' && (

//                     <div class=" mt-2">
//                         <div class=" px-10 flex flex-col gap-2 p-5 bg-gray-800 text-white">
//                             <h1 class="py-5 text-lg">Reviews</h1>

//                             <div class="flex flex-col gap-3">
//                                 {
//                                     AllReview?.data?.map((review) => (

//                                         <div class="flex flex-col gap-4 bg-gray-700 p-4">

//                                             <div class="flex justify justify-between">
//                                                 <div class="flex gap-2">
//                                                     <div class="w-7 h-7 text-center rounded-full bg-red-500">J</div>
//                                                     <span>Jess Hopkins</span>
//                                                 </div>
//                                                 <div class="flex p-1 gap-1 text-orange-300">
//                                                     {
//                                                         review.star === '1' &&
//                                                         <>

//                                                             <IoIosStar></IoIosStar>
//                                                             <IoIosStarOutline ></IoIosStarOutline >
//                                                             <IoIosStarOutline ></IoIosStarOutline >
//                                                             <IoIosStarOutline ></IoIosStarOutline >
//                                                             <IoIosStarOutline ></IoIosStarOutline >
//                                                         </>
//                                                     }

//                                                     {
//                                                         review.star === '2' &&
//                                                         <>
//                                                             <IoIosStar></IoIosStar>
//                                                             <IoIosStar></IoIosStar>
//                                                             <IoIosStarOutline ></IoIosStarOutline >
//                                                             <IoIosStarOutline ></IoIosStarOutline >
//                                                             <IoIosStarOutline ></IoIosStarOutline >
//                                                         </>
//                                                     }

//                                                     {
//                                                         review.star === '3' &&
//                                                         <>
//                                                             <IoIosStar></IoIosStar>
//                                                             <IoIosStar></IoIosStar>
//                                                             <IoIosStar></IoIosStar>
//                                                             <IoIosStarOutline ></IoIosStarOutline >
//                                                             <IoIosStarOutline ></IoIosStarOutline >


//                                                         </>
//                                                     }

//                                                     {
//                                                         review.star === '4' &&
//                                                         <>

//                                                             <IoIosStar></IoIosStar>
//                                                             <IoIosStar></IoIosStar>
//                                                             <IoIosStar></IoIosStar>
//                                                             <IoIosStar></IoIosStar>
//                                                             <IoIosStarOutline ></IoIosStarOutline >

//                                                         </>
//                                                     }

//                                                     {
//                                                         review.star === '5' &&
//                                                         <>
//                                                             <IoIosStar></IoIosStar>
//                                                             <IoIosStar></IoIosStar>
//                                                             <IoIosStar></IoIosStar>
//                                                             <IoIosStar></IoIosStar>
//                                                             <IoIosStar></IoIosStar>
//                                                         </>
//                                                     }

//                                                 </div>
//                                             </div>

//                                             <div>
//                                                 {review.message}
//                                             </div>

//                                             <div class="flex justify-between">
//                                                 <span>{review.created_at}</span>

//                                             </div>
//                                         </div>
//                                     ))
//                                 }


//                             </div>
//                         </div>
//                         <form action="#" class=" container mx-auto border  p-6 bg-white" onSubmit={expense_create}>
//                             <h2 class="text-2xl pb-3 font-semibold">
//                                 Send Review
//                             </h2>
//                             <div>
//                                 <div class="flex flex-col mb-3">

//                                     <div class="flex flex-row-reverse justify-end items-center">
//                                         <input id="hs-ratings-readonly-2" type="radio" class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0" name="rating" onChange={handleRatingChange} value="5" />
//                                         <label for="hs-ratings-readonly-2" class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-gray-600">
//                                             <svg class="flex-shrink-0 size-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//                                                 <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
//                                             </svg>
//                                         </label>
//                                         <input id="hs-ratings-readonly-2" type="radio" class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0" name="rating" onChange={handleRatingChange} value="4" />
//                                         <label for="hs-ratings-readonly-2" class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-gray-600">
//                                             <svg class="flex-shrink-0 size-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//                                                 <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
//                                             </svg>
//                                         </label>
//                                         <input id="hs-ratings-readonly-3" type="radio" class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0" name="rating" onChange={handleRatingChange} value="3" />
//                                         <label for="hs-ratings-readonly-3" class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-gray-600">
//                                             <svg class="flex-shrink-0 size-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//                                                 <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
//                                             </svg>
//                                         </label>
//                                         <input id="hs-ratings-readonly-4" type="radio" class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0" name="rating" onChange={handleRatingChange} value="2" />
//                                         <label for="hs-ratings-readonly-4" class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-gray-600">
//                                             <svg class="flex-shrink-0 size-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//                                                 <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
//                                             </svg>
//                                         </label>
//                                         <input id="hs-ratings-readonly-5" type="radio" class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0" name="rating" onChange={handleRatingChange} value="1" />
//                                         <label for="hs-ratings-readonly-5" class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-gray-600">
//                                             <svg class="flex-shrink-0 size-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//                                                 <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
//                                             </svg>
//                                         </label>


//                                     </div>
//                                 </div>


//                                 <div class="flex flex-col mb-3">
//                                     <label for="message">Message</label>
//                                     <textarea
//                                         rows="4" id="message"
//                                         name='message'
//                                         class="px-3 py-2 bg-gray-800 border border-gray-900 focus:border-red-500 focus:outline-none focus:bg-gray-800 focus:text-red-500"
//                                     ></textarea>
//                                 </div>
//                             </div>
//                             <div class="w-full pt-3">
//                                 <button type="submit" class="w-full bg-gray-900 border border-red-500 px-4 py-2 transition duration-50 focus:outline-none font-semibold hover:bg-red-500 hover:text-white text-xl cursor-pointer text-white">
//                                     Send
//                                 </button>
//                             </div>
//                         </form>


//                     </div>

//                 )}
//             </div>
//             <div className='px-7 mt-10'>
//                 {loading ? (
//                     <button className="btn loading m-10 ">loading</button>
//                 ) : (
//                     <div className='container mx-auto '>
//                         <div className=" grid  grid-cols-1  ">
//                             <div className='flex justify-start mb-3'>
//                                 <h1 className=' text-xl px-2 rounded-md py-1 mb-5'>Related Products</h1>
//                             </div>
//                             <Slider className=''  {...settings}>
//                                 {relatedProducts?.map(item => (
//                                     <div className="card rounded-none ">
//                                         <Link to={`/product/product_details/${item.slug}`}>

//                                             <figure className="">
//                                                 <img src={item.photo} alt="Shoes" className="w-[160px] h-[130px] " />
//                                             </figure>
//                                             <p className='text-red-500 text-center'>{item.title.slice(0, 15) + '...'}</p>
//                                         </Link>

//                                     </div>


//                                 ))}

//                             </Slider>
//                         </div>

//                     </div>
//                 )}
//             </div>
//             <div>

//             </div>
//         </div>

//     </div>
//     );
// };

// export default ProductDescription;

import React, { useEffect, useState } from 'react';
import { IoIosStar, IoIosStarOutline } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import PrivateRoutes from '../../Shared/PrivateRoutes/PrivateRoute';

const ProductDescription = ({ homes }) => {

    const [activeButton, setActiveButton] = useState('login');
    const [related, setRelated] = useState([])


    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);
        fetch(`https://backend.ebeachbd.com/api/product-list`)
            .then(response => response.json())
            .then(data => {
                setRelated(data);
                setLoading(false);
            });
    }, []);
    const [AllReview, setAllReview] = useState([])
    useEffect(() => {
        fetchReviews();
    }, []);

    const fetchReviews = async () => {
        try {
            const response = await fetch(`https://backend.ebeachbd.com/api/single-product-reviewList/${homes?.data?.id}`);
            const data = await response.json();
            setAllReview(data);
        } catch (error) {
            console.error('Error fetching reviews:', error);
        }
    };

    //   const userId = (AllReview?.data?.map(review => review.user_id))
    //   console.log(userId)

    // const [singleUser, setSingleUser] = useState([])

    // useEffect(() => {
    //     fetch(`https://backend.ebeachbd.com/api/user-name/`)
    //         .then(res => res.json())
    //         .then(data => setSingleUser(data))
    // }, [])

    // console.log(singleUser)

    console.log(AllReview.data)


    const relatedProd = homes?.data?.cat_id
    const relatedProducts = related?.data?.filter(item => item?.cat_id === relatedProd);

    console.log(homes)

    const [topSelling, setTopSelling] = useState([])
    useEffect(() => {
        setLoading(true);
        fetch(`https://backend.ebeachbd.com/api/bestSelling-list`)
            .then(response => response.json())
            .then(data => {
                setTopSelling(data);
                setLoading(false);
            });
    }, []);

    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true, // Set autoplay to true
        autoplaySpeed: 3000,

        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    const [selectedRating, setSelectedRating] = useState(null);

    const handleRatingChange = (event) => {
        setSelectedRating(event.target.value);
    };

    const user_id = localStorage.getItem('user_id')


    const expense_create = async (event) => {
        event.preventDefault();

        const form = event.target
        const star = selectedRating;
        const message = form.message.value;
        const productData = {
            star, message, user_id, product_id: homes?.data?.id

        }
        console.log(productData)

        try {
            const response = await fetch('https://backend.ebeachbd.com/api/review-store', {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${localStorage.getItem('token')}`,
                    'content-type': 'application/json',
                },
                body: JSON.stringify(productData),
            });
            // Assuming successful response implies review added
            if (response.ok) {
                // Refresh reviews after successful addition
                fetchReviews();
            }
        } catch (error) {
            console.error('Error posting review:', error);
        }

    }

    const userId = localStorage.getItem('user_id')

    return (
        <div className="lg:flex container mx-auto lg:p-0 md:px-5 px-8 mb-10">
            <div className='lg:w-[25%]  mt-16 px-4  '>
                <p className='mb-4 font-bold'>Top Selling Products</p>
                <div className="dropdown rounded-xl dropdown-hover dropdown-right   block  flex-col bg-white py-1 text-gray-800 shadow-xl group-hover:visible">
                    <div className="mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
                        <div className="rounded-lg ">
                            {
                                topSelling?.data?.slice(0, 6).map((product => (
                                    <Link to={`/product/product_details/${product.slug}`}>
                                        <div className="justify-between rounded-lg bg-white p-6 sm:flex sm:justify-start" >
                                            <img src={product.photo} alt="product-image" className="w-full rounded-lg sm:w-40 lg:h-[100px] md:h-[100px]" />


                                            <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                                                <div className="mt-5 sm:mt-0">

                                                    <p className="mt-1 text-xs text-gray-700 underline">{product.title}</p>
                                                    <p className="mt-5 text-orange-400 font-semibold">{product.price} TAKA</p>
                                                </div>
                                            </div>

                                        </div>
                                    </Link>
                                )))
                            }

                        </div>


                    </div>
                </div>
            </div>


            <div className=' container mx-auto lg:w-[75%]   mt-10 gap-10'>

                <div class="flex   mt-6 ">
                    <a className={`w-1/3 pb-4 font-medium text-center text-gray-800 capitalize border-b-2  dark:text-black ${activeButton === 'login' ? 'border-b-2 border-blue-500 dark:border-blue-400' : ''}`} onClick={() => setActiveButton('login')}>
                        Description
                    </a>
                    {/* <PrivateRoutes> */}

                    <a className={`w-1/3 pb-4 font-medium text-center text-gray-800 capitalize border-b-2 dark:text-black ${activeButton === 'registration' ? 'border-b-2 border-blue-500 dark:border-blue-400' : ''}`} onClick={() => setActiveButton('registration')}>
                        Review
                    </a>
                    {/* </PrivateRoutes> */}
                </div>






                <div>
                    {activeButton === 'login' && (


                        <div class="w-full mt-5 py-6">



                            <form action="" >


                                <p>{homes?.data?.description}</p>


                            </form>
                        </div>

                    )}
                    {activeButton === 'registration' && (

                        <div class=" mt-2">
                            <div class=" px-10 flex flex-col gap-2 p-5 bg-gray-800 text-white">
                                <h1 class="py-5 text-lg">All Reviews</h1>

                                <div class="flex flex-col gap-3">
                                    {
                                        loading ?

                                            <div>loading...</div>
                                            :
                                            AllReview?.data?.map((review) => (

                                                <div class="flex flex-col gap-4 bg-gray-700 p-4">

                                                    <div class="flex justify justify-between">
                                                        <div class="flex gap-2">

                                                            <img class="w-7 h-7 text-center rounded-full bg-red-500" src={`https://backend.ebeachbd.com/uploads/images/user_image/${review.user.photo}`} alt="" />

                                                            <span>{review.user.full_name}</span>
                                                        </div>
                                                        <div class="flex p-1 gap-1 text-orange-300">
                                                            {
                                                                review.star === '1' &&
                                                                <>

                                                                    <IoIosStar></IoIosStar>
                                                                    <IoIosStarOutline ></IoIosStarOutline >
                                                                    <IoIosStarOutline ></IoIosStarOutline >
                                                                    <IoIosStarOutline ></IoIosStarOutline >
                                                                    <IoIosStarOutline ></IoIosStarOutline >
                                                                </>
                                                            }

                                                            {
                                                                review.star === '2' &&
                                                                <>
                                                                    <IoIosStar></IoIosStar>
                                                                    <IoIosStar></IoIosStar>
                                                                    <IoIosStarOutline ></IoIosStarOutline >
                                                                    <IoIosStarOutline ></IoIosStarOutline >
                                                                    <IoIosStarOutline ></IoIosStarOutline >
                                                                </>
                                                            }

                                                            {
                                                                review.star === '3' &&
                                                                <>
                                                                    <IoIosStar></IoIosStar>
                                                                    <IoIosStar></IoIosStar>
                                                                    <IoIosStar></IoIosStar>
                                                                    <IoIosStarOutline ></IoIosStarOutline >
                                                                    <IoIosStarOutline ></IoIosStarOutline >


                                                                </>
                                                            }

                                                            {
                                                                review.star === '4' &&
                                                                <>

                                                                    <IoIosStar></IoIosStar>
                                                                    <IoIosStar></IoIosStar>
                                                                    <IoIosStar></IoIosStar>
                                                                    <IoIosStar></IoIosStar>
                                                                    <IoIosStarOutline ></IoIosStarOutline >

                                                                </>
                                                            }

                                                            {
                                                                review.star === '5' &&
                                                                <>
                                                                    <IoIosStar></IoIosStar>
                                                                    <IoIosStar></IoIosStar>
                                                                    <IoIosStar></IoIosStar>
                                                                    <IoIosStar></IoIosStar>
                                                                    <IoIosStar></IoIosStar>
                                                                </>
                                                            }

                                                        </div>
                                                    </div>

                                                    <div>
                                                        {review.message}
                                                    </div>

                                                    {/* <div class="flex justify-between">
                                                    <span>{review.created_at}</span>

                                                </div> */}
                                                </div>
                                            ))
                                    }


                                </div>
                            </div>
                            {
                                userId ?


                                    <form action="#" class=" container mx-auto border  p-6 bg-white" onSubmit={expense_create}>
                                        <h2 class="text-2xl pb-3 font-semibold">
                                            Send Review
                                        </h2>
                                        <div>
                                            <div class="flex flex-col mb-3">

                                                <div class="flex flex-row-reverse justify-end items-center">
                                                    <input id="hs-ratings-readonly-2" type="radio" class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0" name="rating" onChange={handleRatingChange} value="5" />
                                                    <label for="hs-ratings-readonly-2" class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-gray-600">
                                                        <svg class="flex-shrink-0 size-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </label>
                                                    <input id="hs-ratings-readonly-2" type="radio" class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0" name="rating" onChange={handleRatingChange} value="4" />
                                                    <label for="hs-ratings-readonly-2" class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-gray-600">
                                                        <svg class="flex-shrink-0 size-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </label>
                                                    <input id="hs-ratings-readonly-3" type="radio" class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0" name="rating" onChange={handleRatingChange} value="3" />
                                                    <label for="hs-ratings-readonly-3" class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-gray-600">
                                                        <svg class="flex-shrink-0 size-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </label>
                                                    <input id="hs-ratings-readonly-4" type="radio" class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0" name="rating" onChange={handleRatingChange} value="2" />
                                                    <label for="hs-ratings-readonly-4" class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-gray-600">
                                                        <svg class="flex-shrink-0 size-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </label>
                                                    <input id="hs-ratings-readonly-5" type="radio" class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0" name="rating" onChange={handleRatingChange} value="1" />
                                                    <label for="hs-ratings-readonly-5" class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-gray-600">
                                                        <svg class="flex-shrink-0 size-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </label>


                                                </div>
                                            </div>


                                            <div class="flex flex-col mb-3">
                                                <label for="message">Message</label>
                                                <textarea
                                                    rows="4" id="message"
                                                    name='message'
                                                    class="px-3 py-2 bg-gray-800 border border-gray-900 focus:border-red-500 focus:outline-none focus:bg-gray-800 focus:text-red-500"
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div class="w-full pt-3">
                                            <button type="submit" class="w-full bg-gray-900 border border-red-500 px-4 py-2 transition duration-50 focus:outline-none font-semibold hover:bg-red-500 hover:text-white text-xl cursor-pointer text-white">
                                                Send
                                            </button>
                                        </div>

                                    </form>
                                    :
                                    <div class=" px-10 flex flex-col gap-2 p-5 bg-gray-800 text-white">

                                        <p >Please Login To Add Review</p>
                                        <p>Click Here To <Link className='underline text-red-500' to={`/login`}>
                                            Login
                                        </Link></p>
                                    </div>

                            }

                        </div>

                    )}
                </div>
                <div className='px-7 mt-10'>
                    {loading ? (
                        <button className="btn loading m-10 ">loading</button>
                    ) : (
                        <div className='container mx-auto '>
                            <div className=" grid  grid-cols-1  ">
                                <div className='flex justify-start mb-3'>
                                    <h1 className=' text-xl px-2 rounded-md py-1 mb-5'>Related Products</h1>
                                </div>
                                <Slider className=''  {...settings}>
                                    {
                                        relatedProducts?.length > 1 ?
                                            relatedProducts?.map(item => (
                                                <div className="card rounded-none ">
                                                    <Link to={`/product/product_details/${item.slug}`}>

                                                        <figure className="">
                                                            <img src={item.photo} alt="Shoes" className="w-[160px] h-[130px] " />
                                                        </figure>
                                                        <p className='text-red-500 text-center'>{item.title.slice(0, 15) + '...'}</p>
                                                    </Link>

                                                </div>


                                            ))
                                            :
                                            <div className='overflow-hidden'>
                                                Nothing Found
                                            </div>



                                    }

                                </Slider>
                            </div>

                        </div>
                    )}
                </div>
                <div>

                </div>
            </div>

        </div>
    );
};

export default ProductDescription;
