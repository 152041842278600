import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ForgetPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        try {
            const response = await axios.post('https://www.backend.ebeachbd.com/api/forget_password', {
                email: email
            });
            // Assuming the API response contains a success message or some indication of successful email sending
            if (response.data.success) {
                alert('Code sent successfully. Please check your email.');
                navigate('/user/forget_password/code_match')
                // Redirect user to another page or perform any other action after successful email sending
                // Example: navigate('/login');
            } else {
                // Handle any error responses from the API
                setError('Failed to send code. Please try again.');
            }
        } catch (error) {
            // Handle network errors or any other unexpected errors
            setError('An error occurred. Please try again later.');
        }

        setLoading(false);
    };

    return (
        <div className="relative mx-auto w-full max-w-md bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:rounded-xl sm:px-10 mt-10 mb-10">
            <div className="w-full">
                <div className="text-center">
                    <h6 className="text-2xl font-semibold text-gray-900 mb-10">Enter Your Email To Send Code</h6>
                </div>
                <div className="mt-5">
                    <form onSubmit={handleSubmit}>
                        <div className="relative mt-6">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                name="Email"
                                placeholder="Email"
                                className="peer bg-white peer mt-1 w-full border-b-2 border-gray-300 px-0 py-1 placeholder:text-transparent focus:border-gray-500 focus:outline-none"
                            />
                            <label
                                htmlFor="password"
                                className="pointer-events-none absolute top-0 left-0 origin-left -translate-y-1/2 transform text-sm text-gray-800 opacity-75 transition-all duration-100 ease-in-out peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:top-0 peer-focus:pl-0 peer-focus:text-sm peer-focus:text-gray-800"
                            >
                                Enter Your Email
                            </label>
                        </div>
                        <div className="form-group row my-6">
                            <div className="offset-md-3 col-sm-6">
                                <button
                                    type="submit"
                                    className="w-full btn rounded-md bg-black px-3 py-4 text-white focus:bg-gray-600 focus:outline-none"
                                    disabled={loading}
                                >
                                    {loading ? 'Sending...' : 'Submit'}
                                </button>
                            </div>
                        </div>
                    </form>
                    {error && <p className="text-red-500">{error}</p>}
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
