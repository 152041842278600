import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './order.css'


const TableToPrint = ({ setAllData }) => {

    const { id } = useParams()
    const [data, setData] = useState([]);
    const user_id = localStorage.getItem('user_id')
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://backend.ebeachbd.com/api/user_order', {
                    params: {
                        user_id: user_id
                    },
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setData(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [user_id]);
    // console.log(data.data)
    // console.log(id)
    const filteredData = data?.data?.filter(item => item.order_number === id);
    // {filteredData?.map(data => data?.total_amount)}
    const orderData = (filteredData?.map(data => data?.product_details));
    // console.log(orderData)
    const [totalSum, setTotalSum] = useState(0);
    useEffect(() => {
        let sum = 0;
        orderData?.forEach(order => {
            order?.forEach(item => {
                sum += item.offer_price * item.qty;
            });
        });
        // Round up the sum to the nearest whole number
        const roundedSum = Math.ceil(sum);
        setTotalSum(roundedSum);
    }, [orderData]);
    // console.log(totalSum)

    const total = filteredData?.map(data => data?.total_amount)

    const shipptingCost = total - totalSum
    console.log(Math.ceil(shipptingCost))

    const [user, setUser] = useState([])
    useEffect(() => {
        fetch(`https://backend.ebeachbd.com/api/user-name/${user_id}`)
            .then(res => res.json())
            .then(data => setUser(data))
    }, [user_id])

    const email = localStorage.getItem('email')

    useEffect(() => {
        setAllData(orderData)
    }, [orderData])

    const delivery_charge = (filteredData?.map(data => data?.delivery_charge))
    const coupon = (filteredData?.map(data => data?.coupon))

console.log(user)
    return (
        <div>
            <div className='hidden'>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3 >Bill From: EbeachBd</h3>
                    <h1>INVOICE</h1>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-40px' }}>
                    <p>Bill To:</p>
                    <p >#{id}</p>
                </div>
                <div style={{ marginTop: '-25px', }}>
                    <p style={{ fontWeight: 700 }}>{user?.data?.full_name}</p>
                    <p style={{ marginTop: '-10px', fontWeight: 700 }}>{email}</p>
                    <p style={{ marginTop: '-10px', fontWeight: 700 }}>  {user?.data?.phone}</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>

                    </div>
                    <div >
                        <div style={{ display: 'flex', justifyContent: 'space-between', }}>

                            <p>Date:	</p>
                            <p style={{ fontWeight: 700 }}>01/04/24</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-25px' }}>

                            <p >Payment Type:	</p>
                            <p style={{ fontWeight: 700 }}>cash on delivery</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-25px' }}>

                            <p >Balance Due	</p>
                            <p style={{ fontWeight: 700 }}>{Math.ceil(total)}৳</p>
                        </div>

                    </div>

                </div>
                <div>

                    <table>
                        <thead>
                            <tr style={{ backgroundColor: 'black', color: 'white' }}>
                                <th>Product</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderData?.map((data) =>
                                    data.map(all =>
                                        <tr style={{ textAlign: 'center', backgroundColor: 'darkgray' }}>
                                            <td>{all.name}</td>
                                            <td>{all.offer_price}</td>
                                            <td>{all.qty}</td>
                                            <td>{all.offer_price * all.qty}</td>
                                        </tr>

                                    )

                                )
                            }

                        </tbody>
                    </table>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>

                    </div>
                    <div >
                        <div style={{ display: 'flex', }}>

                            <p style={{ marginRight: '150px' }}>Subtotal:	</p>
                            <p style={{ fontWeight: 700 }}>{totalSum}৳</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-25px' }}>

                            <p style={{ marginRight: '100px', }}>Shipping Cost:	</p>
                            <p style={{ fontWeight: 700 }}>{Math.ceil(delivery_charge)}৳</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-25px' }}>

                            <p style={{ marginRight: '100px', }}>Coupon:	</p>
                            <p style={{ fontWeight: 700 }}>{Math.ceil(coupon)}৳</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-25px' }}>

                            <p style={{ marginRight: '100px' }}>Total:	</p>
                            <p style={{ fontWeight: 700 }}>{Math.ceil(total)}৳</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-25px' }}>

                            <p style={{ marginRight: '100px' }}>Amount Due:</p>
                            <p style={{ fontWeight: 700 }}>{Math.ceil(total)}৳</p>
                        </div>
                        <small>(Coupon And Delivery Charge Included)</small>

                    </div>

                </div>
            </div>

        </div>
    );
};

const OrderListView = () => {
    const name = localStorage.getItem('name')
    const [allData, setAllData] = useState([])

    const handlePrint = () => {
        const printContents = document.getElementById('table-to-print').outerHTML;
        const newWindow = window.open('', '_blank');
        newWindow.document.write('<html><head><title>Print</title>');
        newWindow.document.write('<style>@media print { table { width: 100%; } }</style>');
        newWindow.document.write('</head><body>');
        newWindow.document.write(printContents);
        newWindow.document.write('</body></html>');
        newWindow.document.close();
        newWindow.print();
    };
    // console.log(allData)
    return (
        <div>
            <div className="flex flex-col mb-10">
                <div className="">
                    <div className="">
                        <div className="overflow-hidden" id="table-to-print">
                            <TableToPrint setAllData={setAllData} />
                        </div>
                    </div>
                    <div>
                        <div className='container  mx-auto mt-10 px-6'>
                            <div className='text-start mb-3'>
                                <h1 className='font-bold'>Hello, {name} </h1>
                                <h3>Recent order</h3>
                            </div>
                            <div class="flex flex-col px-6 py-3  mb-10 bg-[#F8F8FF] rounded">

                                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                        <div class="overflow-hidden">
                                            <table
                                                class="min-w-full border border-black text-center text-sm font-light text-surface dark:border-black text-black rounded">
                                                <thead
                                                    class="border-b border-black font-medium dark:border-black">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            class="border-e border-black px-6 py-4 dark:border-black">
                                                            #
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class="border-e border-black px-6 py-4 dark:border-black">
                                                            Product
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class="border-e border-black px-6 py-4 dark:border-black">
                                                            Price
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class="border-e border-black px-6 py-4 dark:border-black">
                                                            Qty
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class="border-e border-black px-6 py-4 dark:border-black">
                                                            Total
                                                        </th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        allData?.map((data, index) =>
                                                            data.map(all =>
                                                                <>

                                                                    <tr class="border-b border-black dark:border-black">
                                                                        <td
                                                                            class="whitespace-nowrap border-e border-black px-6 py-4 font-bold dark:border-black">
                                                                            {index + 1}
                                                                        </td>
                                                                        <td
                                                                            class="font-medium whitespace-nowrap border-e border-black px-6 py-4 dark:border-black">
                                                                            {all.name}
                                                                        </td>
                                                                        <td
                                                                            class="whitespace-nowrap font-medium border-e border-black px-6 py-4 dark:border-black">
                                                                            {all.offer_price}
                                                                        </td>
                                                                        <td
                                                                            class="whitespace-nowrap font-medium border-e border-black px-6 py-4 dark:border-black">

                                                                            {all.qty}

                                                                        </td>
                                                                        <td
                                                                            class="whitespace-nowrap border-e font-medium border-black px-6 py-4 dark:border-black">
                                                                            {all.offer_price * all.qty}
                                                                        </td>


                                                                    </tr>

                                                                </>


                                                            )

                                                        )
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className='btn btn-sm btn-primary' onClick={handlePrint}>Print Invoice</button>
                </div>
            </div>
        </div>
    );
};

export default OrderListView;
