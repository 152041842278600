// import React, { useEffect, useState } from 'react';
// import toast from 'react-hot-toast';
// import { IoIosStar, IoIosStarOutline } from 'react-icons/io';
// import { Link, useParams } from 'react-router-dom';
// import { FaArrowsRotate, FaLocationDot } from "react-icons/fa6";
// import { FaCcApplePay, FaTruck } from 'react-icons/fa';
// import ReactImageMagnify from 'react-image-magnify';
// import './productDetails.css'
// import Slider from 'react-slick';
// import { useQuery } from '@tanstack/react-query';
// import ProductDescription from './ProductDescription';



// const ProductDetails = () => {
//     const [homes, setHomes] = useState([]);

//     const { id } = useParams();
//     const [loading, setLoading] = useState(false);
//     const [count, setCount] = useState(1);
//     const [totalPrice, setTotalPrice] = useState(0);
//     const [selectedImage, setSelectedImage] = useState('');


//     useEffect(() => {
//         setLoading(true);
//         fetch(`https://backend.ebeachbd.com/api/single-product/${id}`)
//             .then(response => response.json())
//             .then(data => {
//                 setHomes(data);
//                 setLoading(false);
//             });
//     }, [id]);

//     console.log(homes)

//     const [home, setHome] = useState([]);
//     useEffect(() => {
//         setLoading(true);
//         fetch(`https://backend.ebeachbd.com/api/shop-list`)
//             .then(response => response.json())
//             .then(data => {
//                 setHome(data);
//                 setLoading(false);
//             });
//     }, []);

//     // Filtering homes based on home IDs
//     const filteredHomes = home?.data?.filter(home => home?.id === parseFloat(homes?.data?.shop_id));

//     console.log(filteredHomes);

//     // console.log(homes?.data?.multi_image?.map(image => image.image))

//     const multiImage = homes?.data?.multi_image

//     // useEffect(() => {
//     //     setLoading(true);
//     //     setTimeout(() => {
//     //         setLoading(false);
//     //     }, 1000);
//     // }, []);

//     useEffect(() => {
//         setTotalPrice(count * homes?.data?.price);
//     }, [count, homes]);

//     const increaseCount = () => {
//         setCount(count + 1);
//     };

//     const decreaseCount = () => {
//         if (count > 1) {
//             setCount(count - 1);
//         }
//     };

//     const handleThumbnailClick = (imageUrl) => {
//         if (imageUrl) {
//             setSelectedImage(imageUrl);
//         }
//     };



//     const [cart, setCart] = useState([])
//     // const handleAddToCart = data => {

//     //     const newCart = [...cart, data]
//     //     setCart(newCart)

//     //     localStorage.setItem('cart', JSON.stringify(newCart));
//     // }
//     //(cart)
//     const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//     useEffect(() => {
//         const handleResize = () => {
//             setWindowWidth(window.innerWidth);
//         };
//         window.addEventListener('resize', handleResize);
//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

//     const getResponsiveWidth = () => {
//         if (windowWidth < 576) { // Extra small (xs)
//             return 300; // Adjust this value as per your requirement
//         } else if (windowWidth >= 576 && windowWidth < 768) { // Small (sm)
//             return 350; // Adjust this value as per your requirement
//         } else if (windowWidth >= 768 && windowWidth < 992) { // Medium (md)
//             return 400; // Adjust this value as per your requirement
//         } else if (windowWidth >= 992 && windowWidth < 1200) { // Large (lg)
//             return 550; // Adjust this value as per your requirement
//         } else { // Extra large (xl)
//             return 550; // Adjust this value as per your requirement
//         }
//     };

//     const getResponsiveHeight = () => {
//         // Adjust this function based on your requirements
//         // You might want to maintain aspect ratio or calculate height relative to width
//         return getResponsiveWidth() * (1000 / 1200); // Maintaining aspect ratio
//     };




//     const allIdString = localStorage.getItem('cart');
//     const allId = allIdString ? JSON.parse(allIdString) : [];

//     const handleAddToCart = () => {
//         const newCartItem = {
//             id: homes?.data?.id,
//             count: count,
//             selectedColor: selectedColor,
//             selectedSize: selectedSize
//         };

//         // Retrieve existing cart items from localStorage
//         let existingCartItems = JSON.parse(localStorage.getItem('DetailsCart')) || [];

//         // Check if an item with the same ID, color, and size exists in the cart
//         const existingItemIndex = existingCartItems.findIndex(item => (
//             item.id === newCartItem.id &&
//             item.selectedColor === newCartItem.selectedColor &&
//             item.selectedSize === newCartItem.selectedSize
//         ));

//         if (existingItemIndex !== -1) {
//             // If the item exists, calculate the total count including the new count
//             const totalCount = existingCartItems[existingItemIndex].count + count;

//             // Check if the total count exceeds the available stock
//             if (totalCount > homes?.data?.stock) {
//                 // Show an alert indicating insufficient stock
//                 alert(`Insufficient stock! you cannot add more then ${homes?.data?.stock}`);

//                 // Abort further processing
//                 return;
//             }

//             // If the total count doesn't exceed the stock, update the count
//             existingCartItems[existingItemIndex].count = totalCount;
//         } else {
//             // If the item doesn't exist, check if the count exceeds the stock
//             if (count > homes?.data?.stock) {
//                 // Show an alert indicating insufficient stock
//                 alert(`Insufficient stock! you cannot add more then ${homes?.data?.stock}`);

//                 // Abort further processing
//                 return;
//             }

//             // If the count doesn't exceed the stock, add the item to the cart
//             existingCartItems.push(newCartItem);
//         }

//         // Update the cart state and localStorage
//         localStorage.setItem('DetailsCart', JSON.stringify(existingCartItems));
//         setCart(existingCartItems);

//         // Optionally, you can display a toast or perform any other actions
//         toast.success('Added to cart successfully!');
//     };


//     const [selectedColor, setSelectedColor] = useState(null);

//     const handleColorClick = (color) => {
//         setSelectedColor(color);
//     };
//     console.log(selectedColor)

//     const [selectedSize, setSelectedSize] = useState(null);

//     const handleSizeClick = (size) => {
//         setSelectedSize(size);
//     };

//     console.log(selectedSize)

//     const handleAddToFavouriteList = (itemId) => {
//         // Retrieve existing cart items from localStorage
//         const existingCartItems = JSON.parse(localStorage.getItem('favouriteList')) || [];

//         // Add the new item ID to the cart
//         const updatedCartItems = [...existingCartItems, itemId];

//         // Update the cart state and localStorage
//         setCart(updatedCartItems);
//         localStorage.setItem('favouriteList', JSON.stringify(updatedCartItems));

//         // Display a success toast
//         toast.success('Added to favourite List successfully!');
//     };


//     return (
//         <div className='text-start'>

//             {loading ? (
//                 <button className="btn loading m-10 ">loading</button>
//             ) : (
//                 <div>
//                     <div className='max-w-[1440px] mx-auto'>
//                         <div className='lg:flex md:flex  gap-10 p-8 lg:p-10 md:px-10'>
//                             <div className='lg:w-[700px]'>


//                                 <div className=' '>

//                                     <div className='ReactImageMagnify hidden lg:block md:block'  >
//                                         <ReactImageMagnify {...{
//                                             smallImage: {
//                                                 alt: 'Wristwatch by Ted Baker London',
//                                                 // isFluidWidth: true,
//                                                 src: selectedImage || homes?.data?.photo,

//                                                 width: getResponsiveWidth(),
//                                                 height: getResponsiveHeight(),

//                                             },
//                                             largeImage: {
//                                                 src: selectedImage || homes?.data?.photo,
//                                                 width: 800,
//                                                 height: 800
//                                             },
//                                             lensStyle: { backgroundColor: 'rgba(0,0,0,0.5)' },

//                                         }} />
//                                     </div>
//                                 </div>

//                                 <img src={selectedImage || homes?.data?.photo} alt="" className='w-[700px] mx-auto rounded-md h-[500px] block lg:hidden md:hidden' />
//                                 <div className=' gap-5 grid grid-cols-3 lg:grid-cols-5 md:grid-cols-4 mt-10'>
//                                     {multiImage?.map((imageUrl, index) => (
//                                         <img
//                                             key={index}
//                                             src={`https://backend.ebeachbd.com/uploads/images/product_more_image/${imageUrl.image}`}
//                                             alt={`Thumbnail ${index}`}
//                                             className='w-[60px] h-[60px] cursor-pointer'
//                                             onClick={() => handleThumbnailClick(`https://backend.ebeachbd.com/uploads/images/product_more_image/${imageUrl.image}`)}
//                                         />
//                                     ))}
//                                 </div>
//                                 {/* */}
//                             </div>
//                             <div className='lg:w-[677px] mx-auto md:w-full mt-5 lg:mt-0 md:mt-0'>
//                                 <Link to={`/shop/all_product/${homes?.data?.shop_id}`}>
//                                     {
//                                         filteredHomes?.map((shop) => (

//                                             <h2 className='text-2xl text-red-500  mb-3'>{shop?.name}  </h2>
//                                         ))
//                                     }
//                                 </Link>
//                                 <h2 className='text-2xl font-semibold mb-3'> {homes?.data?.title} </h2>
//                                 <div className='flex gap-2'>
//                                     <div>{homes?.data?.offer_price}</div>
//                                     <div> <del className='text-red-500'>{homes?.data?.price}</del> BDT</div>
//                                 </div>
//                                 <div className=' flex text-[#FACA15] mb-3 mt-2'>
//                                     <IoIosStar></IoIosStar>
//                                     <IoIosStar></IoIosStar>
//                                     <IoIosStar></IoIosStar>
//                                     <IoIosStar></IoIosStar>
//                                     <IoIosStar></IoIosStar>
//                                 </div>
//                                 <div className='mt-2'>

//                                     <p>QUANTITY</p>
//                                     <div className='flex'>
//                                         <button className='btn btn-xs btn-outline px-4 rounded-none' onClick={decreaseCount}>-</button>
//                                         <button className='btn btn-outline btn-xs rounded-none px-5'> {count}</button>
//                                         <button className='btn btn-xs rounded-none btn-outline px-4' onClick={increaseCount} >+</button>
//                                     </div>
//                                 </div>
//                                 <div className='mt-3'>
//                                     {
//                                         homes?.data?.color.length > 0 ?
//                                             <>
//                                                 <p className='font-medium mb-1'>Select Your Color</p>
//                                                 <div className='flex gap-2'>
//                                                     {homes?.data?.color?.map((colorOption) => (
//                                                         <label
//                                                             key={colorOption?.color}
//                                                             htmlFor={`colorInput_${colorOption?.id}`}
//                                                             className={`btn-outline btn btn-xs ${selectedColor === colorOption?.color ? 'bg-red-700 text-white border-none' : 'text-black'}`}
//                                                             style={{ backgroundColor: colorOption.color }}
//                                                         >
//                                                             <input
//                                                                 name='color_path'
//                                                                 type='radio'
//                                                                 id={`colorInput_${colorOption?.id}`}
//                                                                 checked={selectedColor === colorOption.color}
//                                                                 onChange={() => handleColorClick(colorOption.color)}
//                                                             // style={{ display: 'none' }}
//                                                             />
//                                                             {/* {colorOption.color} */}
//                                                         </label>
//                                                     ))}
//                                                 </div>

//                                             </>
//                                             :
//                                             ''
//                                     }
//                                 </div>

//                                 <div className='mt-3'>
//                                     {
//                                         homes?.data?.product_size.length > 0 ?
//                                             <>
//                                                 <p className='font-medium mb-1'>Select Your Size</p>
//                                                 <div className='flex gap-2'>
//                                                     {homes?.data?.product_size?.map((sizeOption) => (
//                                                         <label
//                                                             key={sizeOption?.id}
//                                                             htmlFor={`sizeInput_${sizeOption?.id}`}
//                                                             className={`btn-outline btn btn-xs ${selectedSize === sizeOption?.size ? 'bg-red-700 text-white border-none' : 'text-black'}`}
//                                                         >
//                                                             <input
//                                                                 name='size_path'
//                                                                 type='radio'
//                                                                 id={`sizeInput_${sizeOption?.id}`}
//                                                                 checked={selectedSize === sizeOption?.size}
//                                                                 onChange={() => handleSizeClick(sizeOption.size)}
//                                                                 style={{ display: 'none' }}
//                                                             />
//                                                             {sizeOption.size}
//                                                         </label>
//                                                     ))}
//                                                 </div>

//                                             </>
//                                             :
//                                             ''
//                                     }
//                                 </div>


//                                 <div className='flex gap-2 mt-3'>
//                                     <Link to={``}>
//                                         <button onClick={() => handleAddToFavouriteList(homes?.data?.id)} className='bg-[#be1e2d] px-6  rounded-md text-white font-semibold btn-sm'>Add To Wish List</button>
//                                     </Link>
//                                     <Link to={``}>
//                                         <button onClick={handleAddToCart} className='bg-slate-600 px-6 rounded-md text-white font-semibold btn-sm'>Add To Cart</button>
//                                     </Link>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>




//                     <div className='max-w-[1440px] mx-auto'>

//                         <ProductDescription
//                             homes={homes}
//                         ></ProductDescription>


//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default ProductDetails;

import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { IoIosStar } from 'react-icons/io';
import { Link, useParams } from 'react-router-dom';
import { FaArrowsRotate, FaLocationDot } from "react-icons/fa6";
import { FaCcApplePay, FaTruck } from 'react-icons/fa';
import ReactImageMagnify from 'react-image-magnify';
import './productDetails.css';
import ProductDescription from './ProductDescription';
import { MdNextPlan } from 'react-icons/md';
import { GrChapterNext, GrChapterPrevious } from 'react-icons/gr';

const ProductDetails = () => {
    const [homes, setHomes] = useState([]);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(1);
    const [totalPrice, setTotalPrice] = useState(0);
    const [cart, setCart] = useState()
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    useEffect(() => {
        setLoading(true);
        fetch(`https://backend.ebeachbd.com/api/single-product/${id}`)
            .then(response => response.json())
            .then(data => {
                setHomes(data);
                setLoading(false);
            });
    }, [id]);

    const [home, setHome] = useState([]);
    useEffect(() => {
        setLoading(true);
        fetch(`https://backend.ebeachbd.com/api/shop-list`)
            .then(response => response.json())
            .then(data => {
                setHome(data);
                setLoading(false);
            });
    }, []);

    const filteredHomes = home?.data?.filter(home => home?.id === parseFloat(homes?.data?.shop_id));
    const multiImage = homes?.data?.multi_image;

    useEffect(() => {
        setTotalPrice(count * homes?.data?.price);
    }, [count, homes]);

    const increaseCount = () => {
        setCount(count + 1);
    };

    const decreaseCount = () => {
        if (count > 1) {
            setCount(count - 1);
        }
    };

    const handleThumbnailClick = (index) => {
        setSelectedImageIndex(index);
    };

    const handlePrevClick = () => {
        setSelectedImageIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : multiImage.length - 1));
    };

    const handleNextClick = () => {
        setSelectedImageIndex(prevIndex => (prevIndex < multiImage.length - 1 ? prevIndex + 1 : 0));
    };

    const getResponsiveWidth = () => {
        if (window.innerWidth < 576) return 300;
        if (window.innerWidth >= 576 && window.innerWidth < 768) return 350;
        if (window.innerWidth >= 768 && window.innerWidth < 992) return 512;
        return 512;
    };





    const getResponsiveHeight = () => {
        return getResponsiveWidth() * (1000 / 1200);
    };

    const handleAddToCart = () => {
        const newCartItem = {
            id: homes?.data?.id,
            count: count,
            selectedColor: selectedColor,
            selectedSize: selectedSize
        };

        let existingCartItems = JSON.parse(localStorage.getItem('DetailsCart')) || [];

        const existingItemIndex = existingCartItems.findIndex(item => (
            item.id === newCartItem.id &&
            item.selectedColor === newCartItem.selectedColor &&
            item.selectedSize === newCartItem.selectedSize
        ));

        if (existingItemIndex !== -1) {
            const totalCount = existingCartItems[existingItemIndex].count + count;
            if (totalCount > homes?.data?.stock) {
                alert(`Insufficient stock! you cannot add more than ${homes?.data?.stock}`);
                return;
            }
            existingCartItems[existingItemIndex].count = totalCount;
        } else {
            if (count > homes?.data?.stock) {
                alert(`Insufficient stock! you cannot add more than ${homes?.data?.stock}`);
                return;
            }
            existingCartItems.push(newCartItem);
        }

        localStorage.setItem('DetailsCart', JSON.stringify(existingCartItems));
        setCart(existingCartItems);
        toast.success('Added to cart successfully!');
    };

    const [selectedColor, setSelectedColor] = useState(null);
    const handleColorClick = (color) => {
        setSelectedColor(color);
    };

    const [selectedSize, setSelectedSize] = useState(null);
    const handleSizeClick = (size) => {
        setSelectedSize(size);
    };

    const handleAddToFavouriteList = () => {
        const newCartItem = {
            id: homes?.data?.id,
            price: homes?.data?.offer_price,
            count: count,
            selectedColor: selectedColor,
            selectedSize: selectedSize
        };

        // Clear any existing data in DetailsCartBuyNow
        let existingCartItems = [];

        const existingItemIndex = existingCartItems.findIndex(item => (
            item.id === newCartItem.id &&
            item.selectedColor === newCartItem.selectedColor &&
            item.selectedSize === newCartItem.selectedSize
        ));

        if (existingItemIndex !== -1) {
            const totalCount = existingCartItems[existingItemIndex].count + count;
            if (totalCount > homes?.data?.stock) {
                alert(`Insufficient stock! You cannot add more than ${homes?.data?.stock}`);
                return;
            }
            existingCartItems[existingItemIndex].count = totalCount;
        } else {
            if (count > homes?.data?.stock) {
                alert(`Insufficient stock! You cannot add more than ${homes?.data?.stock}`);
                return;
            }
            existingCartItems.push(newCartItem);
        }

        localStorage.setItem('DetailsCartBuyNow', JSON.stringify(existingCartItems));
        setCart(existingCartItems);
        toast.success('Process To CheckOut');
    };

    console.log(homes?.data?.description)
    return (
        <div className='text-start'>
            {loading ? (
                <button className="btn loading m-10 ">loading</button>
            ) : (
                <div>
                    <div className='max-w-[1440px] mx-auto'>
                        <div className='lg:flex md:flex gap-10 p-8 lg:p-10 md:px-10'>
                            <div className='lg:w-[700px]'>
                                <div>
                                    <div className='ReactImageMagnify hidden lg:block md:block '>
                                        <ReactImageMagnify {...{
                                            smallImage: {
                                                alt: 'Wristwatch by Ted Baker London',
                                                src: multiImage && multiImage[selectedImageIndex] ? `https://backend.ebeachbd.com/uploads/images/product_more_image/${multiImage[selectedImageIndex].image}` : homes?.data?.photo,
                                                // width: getResponsiveWidth(),
                                                // height: getResponsiveHeight(),
                                                width: 512,
                                                height: 512
                                            },
                                            largeImage: {
                                                src: multiImage && multiImage[selectedImageIndex] ? `https://backend.ebeachbd.com/uploads/images/product_more_image/${multiImage[selectedImageIndex].image}` : homes?.data?.photo,
                                                width: 800,
                                                height: 800
                                            },
                                            lensStyle: { backgroundColor: 'rgba(0,0,0,0.5)' },
                                        }} />
                                    </div>
                                    <div className='flex justify-evenly mt-5 ml-[-140px]'>
                                        <button
                                            className='btn btn-outline text-black'
                                            onClick={handlePrevClick}
                                        >
                                            <GrChapterPrevious />
                                        </button>
                                        <button
                                            className='btn btn-outline text-black'
                                            onClick={handleNextClick}
                                        >
                                            <GrChapterNext />
                                        </button>
                                    </div>
                                </div>
                                <img src={multiImage && multiImage[selectedImageIndex] ? `https://backend.ebeachbd.com/uploads/images/product_more_image/${multiImage[selectedImageIndex].image}` : homes?.data?.photo} alt="" className='w-[700px] mx-auto rounded-md h-[500px] block lg:hidden md:hidden' />
                                <div className='gap-5 grid grid-cols-3 lg:grid-cols-5 md:grid-cols-4 mt-10'>
                                    {multiImage?.map((imageUrl, index) => (
                                        <img
                                            key={index}
                                            src={`https://backend.ebeachbd.com/uploads/images/product_more_image/${imageUrl.image}`}
                                            alt={`Thumbnail ${index}`}
                                            className='w-[80px] h-[80px] cursor-pointer'
                                            onClick={() => handleThumbnailClick(index)}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className='lg:w-[677px] mx-auto md:w-full mt-5 lg:mt-0 md:mt-0'>
                                <Link to={`/shop/all_product/${homes?.data?.shop_id}`}>
                                    {filteredHomes?.map((shop) => (
                                        <h2 key={shop.id} className='text-2xl text-red-500  mb-3'>{shop?.name}</h2>
                                    ))}
                                </Link>
                                <h2 className='text-2xl font-semibold mb-3'>{homes?.data?.title}</h2>
                                <div className='flex gap-2'>
                                    <div>{homes?.data?.offer_price}</div>
                                    <div><del className='text-red-500'>{homes?.data?.price}</del> BDT</div>
                                </div>
                                <div className='flex text-[#FACA15] mb-3 mt-2'>
                                    <IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar />
                                </div>
                                <div className='mt-2'>
                                    <p>QUANTITY</p>
                                    <div className='flex'>
                                        <button className='btn btn-xs btn-outline px-4 rounded-none' onClick={decreaseCount}>-</button>
                                        <button className='btn btn-outline btn-xs rounded-none px-5'>{count}</button>
                                        <button className='btn btn-xs rounded-none btn-outline px-4' onClick={increaseCount}>+</button>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    {homes?.data?.color.length > 0 && (
                                        <>
                                            <p className='font-medium mb-1'>Select Your Color</p>
                                            <div className='flex gap-2'>
                                                {homes?.data?.color?.map((colorOption) => (
                                                    <label
                                                        key={colorOption?.color}
                                                        htmlFor={`colorInput_${colorOption?.id}`}
                                                        className={`btn-outline btn btn-xs ${selectedColor === colorOption?.color ? 'bg-red-700 text-white border-none' : 'text-black'}`}
                                                        style={{ backgroundColor: colorOption.color }}
                                                    >
                                                        <input
                                                            name='color_path'
                                                            type='radio'
                                                            id={`colorInput_${colorOption?.id}`}
                                                            checked={selectedColor === colorOption.color}
                                                            onChange={() => handleColorClick(colorOption.color)}
                                                        // style={{ display: 'none' }}
                                                        />
                                                        {/* {colorOption.color} */}
                                                    </label>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className='mt-5'>
                                    {homes?.data?.size?.length > 0 && (
                                        <>
                                            <p className='font-medium mb-1'>Select Your Size</p>
                                            <div className='flex gap-2'>
                                                {homes?.data?.size?.map((sizeOption) => (
                                                    <label
                                                        key={sizeOption.size}
                                                        htmlFor={`sizeInput_${sizeOption?.id}`}
                                                        className={`btn-outline btn btn-xs ${selectedSize === sizeOption.size ? 'btn-primary' : ''}`}
                                                    >
                                                        <input
                                                            type="radio"
                                                            id={`sizeInput_${sizeOption?.id}`}
                                                            name="size"
                                                            value={sizeOption?.size}
                                                            onClick={() => handleSizeClick(sizeOption?.size)}
                                                            className="hidden"
                                                        />
                                                        {sizeOption.size}
                                                    </label>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </div>
                                {/*  */}
                                <div className='flex gap-4 items-center mt-5'>
                                    <FaTruck size={25} />
                                    <p className='text-base font-medium'>Free home delivery in 24 hours</p>
                                </div>
                                <div className='flex gap-4 items-center mt-2'>
                                    <FaLocationDot size={25} />
                                    <p className='text-base font-medium'>Currently delivering all over Bangladesh</p>
                                </div>
                                <div className='flex gap-4 items-center mt-2'>
                                    <FaArrowsRotate size={25} />
                                    <p className='text-base font-medium'>7 Days a week return policy</p>
                                </div>
                                <div className='flex gap-4 items-center mt-2'>
                                    <FaCcApplePay size={25} />
                                    <p className='text-base font-medium'>Pay with Bkash, Rocket, Nagad and All credit and debit cards</p>
                                </div>
                                <div className='mt-5 flex'>
                                    <button className='mr-2 hover:border-0 btn rounded-full px-10 btn-outline text-black hover:bg-[#FACA15]' onClick={handleAddToCart}>Add To Cart</button>
                                    <Link to={`/product/order/buy_now`}>
                                        <button className='btn bg-red-700 border-none text-white px-10 rounded-full' onClick={() => handleAddToFavouriteList(homes?.data?.id)}>Buy Now</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ProductDescription homes={homes} />
                </div>
            )}
        </div>
    );
};

export default ProductDetails;

