// import React, { useState } from 'react';

// const BecomeSeller = () => {
//     const [formData, setFormData] = useState({
//         fname: '',
//         lname: '',
//         email: '',
//         phone: '',
//         nid_part_one: null,
//         nid_part_two: null,
//         buisness_type: '',
//         message: ''
//     });

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: value
//         });
//     };

//     const handleFileChange = (e) => {
//         const { name, files } = e.target;
//         setFormData({
//             ...formData,
//             [name]: files[0]
//         });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         const data = new FormData();
//         for (let key in formData) {
//             data.append(key, formData[key]);
//         }

//         try {
//             const response = await fetch('https://backend.ebeachbd.com/api/seller-request-store', {
//                 method: 'POST',
//                 body: data
//             });
//             console.log(data)

//             if (response.ok) {
//                 console.log(response)
//                 // Handle success
//                 console.log('Request sent successfully');
//             } else {
//                 // Handle errors
//                 console.error('Failed to send request');
//             }
//         } catch (error) {
//             console.error('Error:', error);
//         }
//     };

//     console.log(formData)

//     return (
//         <div className='mt-10 shadow-lg container mx-auto px-10 pt-5 bg-white'>
//             <h1 className='text-3xl mt-10'>GROWTH YOUR BUSINESS WITH US</h1>
//             <form onSubmit={handleSubmit}>
//                 <div className="flex justify-center items-center">
//                     <div className="container mx-auto my-4 px-4">
//                         <div className="w-full">
//                             <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
//                                 <div>
//                                     <p className='text-start'>First Name</p>
//                                     <input
//                                         className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                                         type="text" placeholder="First Name*"
//                                         name='fname'
//                                         value={formData.fname}
//                                         onChange={handleChange}
//                                     />
//                                 </div>
//                                 <div>
//                                     <p className='text-start'>Last Name</p>
//                                     <input
//                                         className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                                         type="text" placeholder="Last Name*"
//                                         name='lname'
//                                         value={formData.lname}
//                                         onChange={handleChange}
//                                     />
//                                 </div>
//                                 <div>
//                                     <p className='text-start'>Email</p>
//                                     <input
//                                         className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                                         type="email" placeholder="Email*"
//                                         name='email'
//                                         value={formData.email}
//                                         onChange={handleChange}
//                                     />
//                                 </div>
//                                 <div>
//                                     <p className='text-start'>Phone</p>
//                                     <input
//                                         className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                                         type="number" placeholder="Phone*"
//                                         name='phone'
//                                         value={formData.phone}
//                                         onChange={handleChange}
//                                     />
//                                 </div>
//                                 <div>
//                                     <p className='text-start'>Front Page Of Nid</p>
//                                     <input
//                                         className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                                         type="file" placeholder="Front Page Of Nid*"
//                                         name='nid_part_one'
//                                         onChange={handleFileChange}
//                                     />
//                                 </div>
//                                 <div>
//                                     <p className='text-start'>Back Page Of Nid</p>
//                                     <input
//                                         className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                                         type="file" placeholder="Back Page Of Nid*"
//                                         name='nid_part_two'
//                                         onChange={handleFileChange}
//                                     />
//                                 </div>
//                             </div>
//                             <div className="my-4">
//                                 <input
//                                     className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                                     type="text" placeholder="Business Type*"
//                                     name='buisness_type'
//                                     value={formData.buisness_type}
//                                     onChange={handleChange}
//                                 />
//                             </div>
//                             <div className="my-4">
//                                 <textarea
//                                     placeholder="Message*"
//                                     className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                                     name="message"
//                                     value={formData.message}
//                                     onChange={handleChange}
//                                 ></textarea>
//                             </div>
//                             <div className="my-2 w-1/2 lg:w-1/4">
//                                 <button
//                                     className="uppercase text-sm font-bold tracking-wide bg-blue-900 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
//                                     type="submit"
//                                 >
//                                     Send Message
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </form>
//         </div>
//     );
// };

// export default BecomeSeller;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import image from '../../../img/logo.png'
import image2 from '../../../img/become.png'

const BecomeSeller = () => {
    const backgroundImageStyle = {
        backgroundImage: `url(${image2})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100vw',  // Set width to 100% of viewport width
        // height: '100vh',
    };


    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        buisness_email: '',
        phone: '',
        buisness_type: '',
        location: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({
            ...formData,
            [name]: files[0]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        for (let key in formData) {
            data.append(key, formData[key]);
        }

        try {
            const response = await fetch('https://backend.ebeachbd.com/api/seller-request-store', {
                method: 'POST',
                body: data
            });
            console.log(data)

            if (response.ok) {
                console.log(response)
                // Handle success
                console.log('Request sent successfully');
            } else {
                // Handle errors
                console.error('Failed to send request');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    console.log(formData)


    return (
        <div className="w-full" style={backgroundImageStyle}>
            <div className='lg:flex p-10 md:flex justify-evenly container mx-auto' >

                <div className='lg:w-[40%] w-full'>
                    <Link className='flex' to={`/`}>
                        <div className=''>
                            <img src={image} alt="" style={{ width: '120px', height: '120px' }} />
                            {/* <img src={image} alt="" style={{ width: '50px' }} /> */}
                        </div>
                        <div className='mt-12 ml-[-15px]'>
                            <h1 className='text-2xl'><strong>E Beach BD</strong></h1>
                            <p className='mt-[-10px]'><small >Get Ready To Shop</small></p>
                        </div>
                    </Link>
                </div>
                <div className="card shrink-0  max-w-lg shadow-2xl bg-[#FAE2E0] lg:w-[60%]">
                    <form action="" onSubmit={handleSubmit}>

                    <div className="card-body">
                        <h1 className='text-2xl text-[#be1e2d]'>Create E Beach BD Seller Account</h1>

                        <div>
                            <p className='text-start'>Your Name</p>
                            <input
                                className="w-full bg-[#E7A6AB] text-gray-900 mt-2 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                type="text" placeholder="Your Name*"
                                name='fullname'
                                value={formData.fullname}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <p className='text-start'>Your Phone Number</p>
                            <input
                                className="w-full bg-[#E7A6AB] text-gray-900 mt-2 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                type="text" placeholder="Your Phone*"
                                name='phone'
                                value={formData.phone}
                                onChange={handleChange}

                            />
                        </div>
                        <div>
                            <p className='text-start'>Your Email</p>
                            <input
                                className="w-full bg-[#E7A6AB] text-gray-900 mt-2 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                type="text" placeholder="Your Email*"
                                name='email'
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <p className='text-start'>Business Name / Brand Name</p>
                            <input
                                className="w-full bg-[#E7A6AB] text-gray-900 mt-2 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                type="text" placeholder="Your Business Name / Brand Name*"
                                name='buisness_type'
                                value={formData.buisness_type}
                                onChange={handleChange}

                            />
                        </div>
                        <div>
                            <p className='text-start'>Your Business Email (IF)</p>
                            <input
                                className="w-full bg-[#E7A6AB] text-gray-900 mt-2 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                type="text" placeholder="Your Business Email*"
                                name='buisness_email'
                                value={formData.buisness_email}
                                onChange={handleChange}

                            />
                        </div>
                        <div>
                            <p className='text-start'>Location</p>
                            <input
                                className="w-full bg-[#E7A6AB] text-gray-900 mt-2 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                type="text" placeholder="Your Location*"
                                name='location'
                                value={formData.location}
                                onChange={handleChange}

                            />
                        </div>


                        <div className="form-control mt-5">
                            <button className='btn bg-[#be1e2d] text-white font-semibold text-xl border-none' >
                                Create
                            </button>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default BecomeSeller;
