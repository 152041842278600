import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const OrderCheck = () => {
    const [details_cart_data, setDetailsCartData] = useState(JSON.parse(localStorage.getItem('DetailsCart')) || []); // Parse JSON string and initialize as empty array if null
    const [matchedProducts, setMatchedProducts] = useState([]); // State variable to store matched products

    useEffect(() => {
        fetch(`https://backend.ebeachbd.com/api/product-list`)
            .then(res => res.json())
            .then(data => {
                const matchedProductsArray = [];

                // Iterate through each object in details_cart_data
                details_cart_data.forEach(cartItem => {
                    // Find the product that matches the ID from details_cart_data
                    const matchedProduct = data?.data?.find(product => product.id === cartItem.id);

                    // If a match is found, add it to the matchedProductsArray
                    if (matchedProduct) {
                        matchedProductsArray.push({
                            ...matchedProduct,
                            count: cartItem.count,
                            selectedColor: cartItem.selectedColor,
                            selectedSize: cartItem.selectedSize
                        });
                    }
                });

                setMatchedProducts(matchedProductsArray); // Set matched products to state
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [details_cart_data]); // Include details_cart_data in the dependency array to fetch the data whenever it changes
    console.log(matchedProducts)


    const removeFromCart = (index) => {
        const updatedDetailsCartData = [...details_cart_data];
        updatedDetailsCartData.splice(index, 1);
        setDetailsCartData(updatedDetailsCartData);
        localStorage.setItem('DetailsCart', JSON.stringify(updatedDetailsCartData));
        toast.success('Item removed from cart');
    };
    const calculateTotalPrice = () => {
        let totalPrice = 0;
        matchedProducts.forEach(product => {
            totalPrice += product.price * product.count;
        });
        return totalPrice;
    };



    const totalAmount = localStorage.getItem('total')



    const [coupon, setCoupon] = useState([])

    useEffect(() => {
        fetch(`https://backend.ebeachbd.com/api/coupon-list`)
            .then(res => res.json())
            .then(data => setCoupon(data))
    }, [])

    console.log(coupon.data)
    const [enteredCoupon, setEnteredCoupon] = useState('');
    const handleCouponChange = (e) => {
        setEnteredCoupon(e.target.value);
    };

    console.log(enteredCoupon)
    const couponValue = coupon?.data?.find(item => item.code === enteredCoupon);
    console.log(couponValue?.value)
    console.log(parseInt(couponValue?.value))


    const saveWithCoupon = () => {
        localStorage.setItem('couponValue', couponValue?.value ? couponValue?.value : 0 )
        localStorage.setItem('After_coupon_total', totalAmount - couponValue?.value ? totalAmount - couponValue?.value : totalAmount)
    }

    return (
        <div className="flex  flex-col max-w-9xl mx-auto p-6 space-y-4 sm:p-10 dark:bg-gray-50 dark:text-gray-800">
            <h2 className="text-xl font-semibold">Confirm Your Order</h2>

            <div class="flex container mx-auto flex-col justify-start items-start dark:bg-gray-800 bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
                <p class="text-lg md:text-xl dark:text-white font-semibold leading-6 xl:leading-5 text-gray-800">Customer’s Cart</p>
                {
                    matchedProducts?.map((matched, index) => (
                        <div class="mt-4 md:mt-6 flex flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full">
                            <div class="pb-4 md:pb-8 w-full md:w-40">
                                <img class="w-full hidden md:block" src={matched.photo} alt="dress" />
                                <img class="w-full md:hidden" src={matched.photo} alt="dress" />
                            </div>
                            <div class="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full pb-8 space-y-4 md:space-y-0">
                                <div class="w-full flex flex-col justify-start items-start space-y-8">
                                    <h3 class="text-xl dark:text-white xl:text-2xl font-semibold leading-6 text-gray-800">{matched.title}</h3>
                                    <div class="flex justify-start items-start flex-col space-y-2">

                                        <p class="text-sm dark:text-white leading-none text-gray-800"><span class="dark:text-gray-500 text-gray-300">Size: </span> {matched.selectedSize ? matched.selectedSize : 'Not Found'}</p>
                                        <p class="text-sm dark:text-white leading-none text-gray-800"><span class="dark:text-gray-500 text-gray-300">Color: </span> {matched.selectedColor ? matched.selectedColor : 'Not Found'}</p>
                                    </div>
                                </div>
                                <div class="flex justify-between space-x-8 items-start w-full">
                                    <p class="text-base dark:text-white xl:text-lg leading-6">{matched.offer_price} <span class="text-red-300 line-through"> {matched.price}</span>BDT</p>
                                    <p class="text-base dark:text-white xl:text-lg leading-6 text-gray-800">{matched.count}</p>
                                    <p class="text-base dark:text-white xl:text-lg font-semibold leading-6 text-gray-800">{matched.offer_price * matched.count} BDT</p>
                                </div>
                            </div>
                        </div>
                    ))}
                <div className="text-start dark:text-violet-600 mt-5 mb-5">
                    <p className='mb-4'>Enter Your Coupon
                      If Any:
                    </p>
                    <input type="text" value={enteredCoupon} onChange={handleCouponChange} class="border-1  border  rounded-r px-4 py-2 w-full"/>
                </div>
                <div className="text-start dark:text-violet-600 mb-5">
                    <p>Total amount:
                        <span className="font-semibold ml-3">{totalAmount - couponValue?.value ? totalAmount - couponValue?.value : totalAmount } BDT</span>
                    </p>
                    
                    <p className="text-sm ">Including taxes and shipping costs</p>
                </div>

                <div className="flex justify-end space-x-4 mt-2">
                    <Link to={`/shop/all_shop`}>

                        <button type="button" className="px-6 py-2 border dark:text-violet-600  rounded-md dark:border-violet-600">Back
                            <span className="sr-only sm:not-sr-only">to shop</span>
                        </button>
                    </Link>
                    <Link to={`/order/check_out`}>
                        <button
                        onClick={saveWithCoupon}
                        
                        type="button" className="px-6 py-2 border rounded-md dark:bg-violet-600 dark:text-gray-50 dark:border-violet-600">
                            <span className="sr-only sm:not-sr-only">Continue To</span> Checkout
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default OrderCheck;