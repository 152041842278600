import React, { useEffect, useState } from 'react';
import Navbars from '../Pages/Shared/Navbars/Navbars';
import Footer from '../Pages/Shared/Footer/Footer';
import { Outlet } from 'react-router-dom';
import Home from '../Pages/Home/Home/Home';
import SearchData from '../Pages/Home/SearchData/SearchData';

const Main = () => {
  const allId = localStorage.getItem('cart');

  const [categories, setCategories] = useState([]);
  const [home, setHome] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  const [sortByPrice, setSortByPrice] = useState("");

  useEffect(() => {
    setLoading(true); // Set loading to true when fetching data

    fetch('https://backend.ebeachbd.com/api/category-list')
      .then(res => res.json())
      .then(data => setCategories(data));

    fetch('https://backend.ebeachbd.com/api/product-list')
      .then(response => response.json())
      .then(data => {
        setHome(data);

        setLoading(false); // Set loading to false when data is fetched
      });
  }, []);

  const handleCategoryChange = (event) => {
    const selectedCategoryId = event.target.value;
    if (selectedCategoryId === 'Select Category') {
      setFilterCategory([]);
    } else {
      const filteredHomes = home.data.filter(product => product.cat_id === selectedCategoryId);
      setFilterCategory(filteredHomes);
    }
  };

  const handleSearch = () => {
    setLoading(true); // Set loading to true when performing search
    const query = searchQuery.toLowerCase();
    let searchData = [];

    if (filterCategory.length > 0) {
      searchData = filterCategory.filter(product =>
        product.title.toLowerCase().includes(query)
      );
    } else {
      searchData = home.data.filter(product =>
        product.title.toLowerCase().includes(query)
      );
    }

    setFilteredData(searchData);
    setLoading(false); // Set loading to false after search is done
  };


  const handleSortByPrice = (order) => {
    const sorted = [...home.data]; // Create a copy of the original data
    sorted.sort((a, b) => {
      if (order === "asc") {
        return a.offer_price - b.offer_price; // Sort in ascending order
      } else if (order === "desc") {
        return b.offer_price - a.offer_price; // Sort in descending order
      }
      return 0;
    });
    setFilteredData(sorted); // Set the sorted array to filteredData state
    setSortByPrice(order);
  };
  
  // const handleFilterByPriceRange = (minPrice, maxPrice) => {
  //   const filteredProducts = home.data.filter(product => product.price >= minPrice && product.price <= maxPrice);
  //   setFilteredData(filteredProducts);
  // };
  const handleFilterByPriceRange = (minPrice, maxPrice) => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(checkbox => {
      checkbox.checked = false; // Uncheck all checkboxes
    });

    const filteredCheckbox = document.getElementById(`${minPrice}-${maxPrice}`);
    filteredCheckbox.checked = true; // Check the clicked checkbox

    const filteredProducts = home.data.filter(product => product.offer_price >= minPrice && product.offer_price <= maxPrice);
    setFilteredData(filteredProducts);
  };

  return (
    <div>

      {/* {loading ? (
        <div class="flex items-center justify-center h-screen">
          <div class="relative">
            <div class="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
            <div class="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin">
            </div>
          </div>
        </div>
      ) : (
        <>
          <Navbars
          handleFilterByPriceRange={handleFilterByPriceRange}
            handleCategoryChange={handleCategoryChange}
            categories={categories}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleSearch={handleSearch}
            filteredData={filteredData}
          /> */}
      <Navbars

        handleCategoryChange={handleCategoryChange}
        categories={categories}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearch={handleSearch}
        filteredData={filteredData}
      />
      {filteredData
        && filteredData.length > 0 ? (
        <SearchData
          handleSortByPrice={handleSortByPrice}
          sortByPrice={sortByPrice}
          handleFilterByPriceRange={handleFilterByPriceRange}
          filteredCategorys={filteredData} />
      ) : (
        <Outlet />
      )}
      <Footer />
      {/* </>
      )} */}
    </div>
  );
};

export default Main;
