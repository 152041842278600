import logo from './logo.svg';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './Components/Routes/Routes';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div className="App bg-[#E5E1E2] text-black" >

      <RouterProvider router={router}></RouterProvider>
      <Toaster></Toaster>
      <div class="flex items-end justify-end fixed bottom-0 right-0 mb-4 mr-4 z-10">
        <div>
          <a title="Messanger" href="https://www.messenger.com/t/109910955352587/?messaging_source=source%3Apages%3Amessage_shortlink&source_id=1441792&recurring_notification=0" target="_blank" class="block w-16 h-16 rounded-full transition-all shadow hover:shadow-lg transform hover:scale-110 hover:rotate-12">
            <img class="object-cover object-center w-full h-full rounded-full" src="https://i.pinimg.com/736x/fe/9d/71/fe9d715e9a48568fafafdbb3194c586b.jpg" />
          </a>
        </div>
      </div>

    </div>
  );
}

export default App;
