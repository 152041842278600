import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaWhatsapp, FaInstagram, } from 'react-icons/fa';
import { HiPhone } from "react-icons/hi2";
import { IoIosAlarm, IoIosGlobe, IoIosArrowDown } from "react-icons/io";
import { FaCartArrowDown, FaShoppingCart } from "react-icons/fa";
import { Link } from 'react-router-dom';
const Nav1 = ({ filteredData }) => {
    return (
        <div>
            {
                filteredData.length > 0 ?
                    <div className='container mx-auto  pt-4 mb-4 text-black font-bold'>
                        <div className='hidden lg:block'>

                            <div className='flex justify-between'>

                                <div className='flex justify-between gap-2'>
                                    <a href='/'>

                                        <p className='mt-[-5px]'>Welcome To E Beach BD</p>
                                    </a >
                                </div>
                                <div className='flex justify-between gap-2'>

                                    <a href={`/seller/seller_type`}>
                                        <p className='mt-[-5px] mr-9'>Become a Seller</p>
                                    </a>
                                    <p className='mt-[-5px]'>Help & Support</p>

                                </div>

                            </div>
                        </div>
                        <div className='block lg:hidden '>
                            <div className='flex  gap-2 mb-4 justify-center'>

                                <a href='/'>
                                    <p className='mt-[-5px]'>Welcome To E Beach BD</p>
                                </a>
                            </div>
                            <div className='flex  gap-2 justify-center mb-4'>
                                {/* <IoIosAlarm></IoIosAlarm> */}
                                <a href={`/seller/seller_type`}>
                                    <p className='mt-[-5px] mr-9'>Become a Seller</p>
                                </a>
                            </div>
                            <div className='flex  gap-2 justify-center'>
                                {/* <IoIosAlarm></IoIosAlarm> */}
                                <p className='mt-[-5px]'>Help & Support</p>
                            </div>
                        </div>

                    </div>
                    :
                    <div className='container mx-auto  pt-4 mb-4 text-black font-bold'>
                        <div className='hidden lg:block'>

                            <div className='flex justify-between'>

                                <div className='flex justify-between gap-2'>

                                    <Link to={'/'}>
                                        <p className='mt-[-5px]'>Welcome To E Beach BD</p>
                                    </Link>
                                </div>
                                <div className='flex justify-between gap-2'>

                                    <Link to={`/seller/seller_type`}>
                                        <p className='mt-[-5px] mr-9'>Become a Seller</p>
                                    </Link>
                                    <p className='mt-[-5px]'>Help & Support</p>

                                </div>

                            </div>
                        </div>
                        <div className='block lg:hidden '>
                            <div className='flex  gap-2 mb-4 justify-center'>

                                <Link to={'/'}>
                                    <p className='mt-[-5px]'>Welcome To E Beach BD</p>
                                </Link>
                            </div>
                            <div className='flex  gap-2 justify-center mb-4'>
                                {/* <IoIosAlarm></IoIosAlarm> */}
                                <Link to={`/seller/seller_type`}>
                                    <p className='mt-[-5px]'>Become a Seller</p>
                                </Link>
                            </div>
                            <div className='flex  gap-2 justify-center'>
                                {/* <IoIosAlarm></IoIosAlarm> */}
                                <p className='mt-[-5px]'>Help & Support</p>
                            </div>
                        </div>

                    </div>
            }
        </div>
    );
};

export default Nav1;